import { useEffect, useState } from 'react'

export const ROLES_FOR_ACCESS_DATE = ["Admin", "SalesRop"]
export const ROLES_FOR_VIEW_ALL_WORKPOINTS = ["Admin", "SalesRop"]
const useCheckRights = (listRolesForAccess: string[], role: string) => {
  const [isRights, setIsRights] = useState(false)

  useEffect(() => {
    if (role) {
      const hasRole = listRolesForAccess.includes(role)
      setIsRights(hasRole)
    }
  }, [listRolesForAccess, role])

  return isRights
}

export default useCheckRights