import React, { useEffect } from 'react'

export const FormTextArea = props => {
  const {className = '', input, meta: {touched, error}, child, ...restProps} = props

  const hasError = touched && error
  const classError = hasError ? 'field--error' : ''

  return (
    <div className={`form-input ${className} ${classError}`}>
      <textarea {...input} {...restProps}/>
      {hasError && <span className="textAreaErr">{error}</span>}
    </div>
  )
}

export const FormInput = props => {
  const {
    input, type = 'text', label, placeholder,
    disabled = false, id, className = '', meta: {touched, error}
  } = props

  const hasError = touched && error
  const classError = hasError ? 'field--error' : ''

  return (
    <div className={`form-input ${className} ${classError}`}>
      <input  {...input} id={id} type={type} disabled={disabled} placeholder={placeholder}/>
      {hasError && <span className="textAreaErr">{error}</span>}
      <label htmlFor={id}>{label}</label>
    </div>
  )
}
export const FormInputRadio = props => {
  const {input, type = 'text', label, id, className = '', meta: {touched, error}} = props

  const hasError = touched && error
  const classError = hasError ? 'field--error' : ''

  return (
    <div className={`form-input ${className} ${classError}`}>
      <input  {...input} id={id} type={type} className={hasError ? 'radioError' : ''}/>
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export const FormInputQuantity = props => {
  const {input, className = '', quantity = null, meta: {touched, error}, ...rest} = props
  const {value, onChange} = input
  useEffect(() => {
    if (!quantity) onChange(1)
    // eslint-disable-next-line
  }, [quantity])

  const handleClick = e => {
    e.preventDefault()
    if (e.target.textContent === '+') onChange(+value + 1)
    if (e.target.textContent === '-') onChange(+value - 1)
  }

  const hasError = touched && error
  const classError = hasError ? 'field--error' : ''

  return (
    <div className={`form-input ${className} ${classError}`}>
      <input {...input} {...rest} type="number"/>
      {hasError && <span>{error}</span>}
      {
        !rest.disabled &&
        <>
          <button type="button" onClick={handleClick} disabled={value > 98 ?? true}>+</button>
          <button type="button" onClick={handleClick} disabled={value < 2 ?? true}>-</button>
        </>
      }
    </div>
  )
}