import React from 'react';


export const FormTable = ({ headlines, children }) => {

	// функция изменение ширины поля
	const currentWidth = item => {
		let thWidth = 'auto';

		if (item === '№') thWidth = '37px';
		if (item === 'Кол-во') thWidth = '70px';
		if (item === 'Цена' || item === 'Cкидка') thWidth = '110px';
		if (item === 'Стоимость') thWidth = '120px';

		return thWidth;
	};

	return (
		<table className="form-table">
			<tbody>
				<tr>
					{headlines.map((item, i) => (
						<th style={{width: currentWidth(item.name)}} key={i} className={item.className}>
							<p>{item.name}</p>
						</th>
					))}
				</tr>
				{children}
			</tbody>
		</table>
	);
};