import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getPayments } from '../../../../../redux/paymentsReducer'
import { setUserDataOnServer } from '../../../../../redux/usersReducer'
import FormStatus from '../FormStatus/FormStatus'
import ModalFormRedux, {IUserFormData} from '../UserData/FormUserData'
import {appendFile} from "fs";
import {IUser} from "../../../../../types/types";

interface IModalFormUserInfo {
  userData: IUser
}

export type IUserDataKeysTypes = Extract<keyof IUserFormData, string>

const ModalFormUserInfo: React.FC<IModalFormUserInfo> = ({ userData }) => {
  const [isChangeUserData, setIsChangeUserData] = useState(false)
  const [document, setDocument] = useState<{file: typeof appendFile | null}>({ file: null })
  const dispatch = useDispatch()

  const changeData = () => setIsChangeUserData(true)

  const sendData = (formData: any) => {
    dispatch(setUserDataOnServer(userData?.id, formData, document, () => {
      dispatch(getPayments('role__type=worker'))
      setIsChangeUserData(false)
    }))
  }

  return (
    <div>
      <FormStatus id={userData.id}
                  status={userData.status}/>

      <ModalFormRedux userData={userData}
                      onSubmit={sendData}
                      changeData={changeData}
                      isChangeUserData={isChangeUserData}
                      setDocument={setDocument}/>
    </div>
  )
}

export default ModalFormUserInfo