import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form'
import { appendFile } from 'fs'

import ButtonFile from '../../../common/Buttons/ButtonsFile/ButtonFile'
import { createField, Input } from '../../../common/FormsControl'

import { AppStateType } from '../../../../../redux/rootReducer'
import { getRoles } from '../../../../../redux/usersReducer'

import { requiredBorder } from '../../../../../utils/validators/validators'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'

import s from './createUser.module.scss'

interface ICreateUser {
  closeModal: () => void
  setRoleId: (id: any) => void
  setDocument: any
}

const CreateUser: React.FC<InjectedFormProps<ICreateUserReduxForm, ICreateUser> & ICreateUser> = ({ handleSubmit, closeModal, setRoleId, setDocument}) => {
  const roles = useSelector((state: AppStateType) => state.users?.roles)
  const [isShowRoles, setIsShowRoles] = useState(false)
  const modalCreateUser = useRef(null)
  const rolesRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRoles('type=worker'))
  }, [dispatch])

  useOnClickOutside(modalCreateUser, closeModal)
  useOnClickOutside(rolesRef, () => setIsShowRoles(false))

  // установить роль пользователя
  const setRole = (id: number, name: string) => {
    setRoleId(id)
    dispatch(change('createUser', 'role', name))
    setIsShowRoles(false)
  }

  return (
    <div className={s.createUserModal} ref={modalCreateUser}>
      <button className={s.btnClose} onClick={closeModal}>x</button>

      <h2 className={s.title}>Добавить исполнителя</h2>

      <form onSubmit={(e) => handleSubmit(e)}
            autoComplete={'off'}>
        <div onSubmit={handleSubmit} className={s.userData}>
          <div>

            <div className={s.item}>
              <span className={s.itemTitle}>Фамилия</span>
              {createField('', 'middle_name',
                [requiredBorder], Input,
                {
                  className: s.itemValue
                })}
            </div>
            <div className={s.item}>
              <span className={s.itemTitle}>Имя</span>
              {createField('', 'first_name',
                [requiredBorder], Input,
                {
                  className: s.itemValue
                })}
            </div>
            <div className={s.item}>
              <span className={s.itemTitle}>Отчество</span>
              {createField('', 'last_name',
                [], Input,
                {
                  className: s.itemValue
                })}
            </div>
            <div className={s.item}>
              <span className={s.itemTitle}>Должность</span>
              <span className={s.roles} ref={rolesRef}>
                {createField('', 'role',
                  [requiredBorder], Input,
                  {
                    classWrap: s.wrapField,
                    className: s.itemValue,
                    onFocus: () => setIsShowRoles(true),
                    // onBlur: () => setIsShowRoles(false),
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()
                  }
                )}

                {
                  isShowRoles &&
                  <div className={s.rolesList}>
                    {
                      roles.map(({id, name}: {id: number, name: string}) => (
                        <span key={id}
                              className={s.role}
                              onClick={() => setRole(id, name)}>
                          {name}
                        </span>
                      ))
                    }
                  </div>
                }

              </span>
            </div>
            <div className={s.item}>
              <span className={s.itemTitle}>Адрес</span>
              {createField('', 'address',
                [], Input,
                {
                  className: s.itemValue
                })}
            </div>
            <div className={s.item}>
              <span className={s.itemTitle}>Телефон</span>
              {createField('', 'phone_number',
                [requiredBorder], Input,
                {
                  className: s.itemValue
                })}
            </div>
          </div>

          <div>
            <h3 className={s.documentTitle}>Копия паспорта</h3>

            <div>
              <Field name={'passport'}
                     component={ButtonFile}
                     formName={'createUser'}
                     inputName={'passport'}
                     setDocument={setDocument}/>
            </div>
          </div>
        </div>

        <div>
          <button className={s.btnSave}>Сохранить</button>
        </div>
      </form>
    </div>
  )
}

interface ICreateUserReduxForm {
  middle_name: string
  first_name: string
  last_name: string
  role: string
  address: string
  phone_number: string
  passport: typeof appendFile
}

export const CreateUserReduxForm = reduxForm<ICreateUserReduxForm, ICreateUser>({ form: 'createUser' })(CreateUser)