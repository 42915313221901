import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import { OrderCard } from '../OrderCard';

import s from '../orderingMaterials.module.scss';
import {getMaterialsFromServer} from '../../../../redux/materialsReducer'

export const OrderArchive = () => {
	const materials = useSelector(state => state?.materials?.materialsArchive?.results);
	const dispatch = useDispatch()

	// Получение материалов с сервера для архива
	useEffect(() => dispatch(getMaterialsFromServer(3)), [dispatch]);

	return (
		<div className={s.orders_archive}>
			
			{materials?.map(item => (
				(item.status === 3) &&
				<OrderCard key={item.id} content={item}>
					<div className={s.card_item}>
						<p className={s.card_item_title}>Количество</p>
						<div className={s.card_item_amountBlock}>
							<div className={s.card_item_value}>{item?.quantity}</div>
							{/*<div className={s.card_item_value}>{item?.measurement}</div>*/}
						</div>
					</div>

					<div className={s.card_item}>
						<div className={s.card_item_notes}>{item?.note}</div>
					</div>

					<p className={s.card_item_date}>Дата заказа <span>{item.created_datetime}</span></p>
					<p className={s.card_item_date}>Дата доставки <span>{item.end_datetime}</span></p>
					<div className={`${s.card_item}  ${s.item_control}`}>
						<span>Дата создания:<span>{item.created_datetime}</span></span>
					</div>
				</OrderCard>
			))}
		</div>
	);
};