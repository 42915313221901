import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { change, Field, formValueSelector} from 'redux-form'

import { FormInput, FormInputRadio } from '../../FormOrderBlock/FormOrderControl'

import { maxLength, normalizePrice, requiredBorder } from '../../../../../utils/validators/validators'

import s from './formOfPayment.module.scss'

const maxLength9 = maxLength(9)

export const FormOfPayment = (props) => {
	const selector = formValueSelector('fieldArrays')
	const total = useSelector(state => selector(state, 'total'))
	const percent = useSelector(state => selector(state, 'order.percent'))
	const prepayment = useSelector(state => selector(state, 'order.prepayment'))
	const paymentMethod = useSelector(state => selector(state, 'order.payment_method'))
	const dispatch = useDispatch()

	useEffect(() => {
		if (!paymentMethod) {
			dispatch(change('fieldArrays', 'order.payment_method', "1"))
		}
		if (!percent) {
			dispatch(change('fieldArrays', 'order.percent', "50"))
		}
		// eslint-disable-next-line
	}, [paymentMethod, percent])

	useEffect(() => {
		if (total) {
			const totalSum = total ? +total.toString().split(' ').join('') : ''
			const countPrepayment = Math.round(totalSum * (+percent / 100))

			dispatch(change('fieldArrays', 'order.prepayment', normalizePrice(countPrepayment + '')))
		}
		// eslint-disable-next-line
	}, [percent, total])
	useEffect(() => {
		if (total && prepayment) {
			const totalNormalize = +(total.toString().split(' ').join(''))
			const prepaymentNormalize = +(prepayment.toString().split(' ').join(''))
			const countPayment = totalNormalize - prepaymentNormalize
			const payment = Number.isInteger(countPayment) ? countPayment : countPayment.toFixed(2)

			if (prepaymentNormalize > totalNormalize) {
				dispatch(change('fieldArrays', 'order.prepayment', normalizePrice(totalNormalize + '')))
			}

			dispatch(change('fieldArrays', 'order.payment', normalizePrice(payment + '')))
		}
	}, [dispatch, total, prepayment])

	const onChange = e => e.target.value = e.target.value.replace(/[^0-9]/gi, '')

	return (
		<div className={s.formItemBlock}>
			<p className="form-item-title">Оплата</p>

			<div className={s.flexBlock}>
				<span className={s.infoPaymentTitle}>Форма оплаты:</span>
				<Field
					name={`payment_type`}
					component={FormInputRadio}
					type="radio"
					id='1'
					value={'1'}
					className={s.inputRadio}
					label={"Наличный расчет"}
					// validate={[requiredBorder]}
				/>
				<Field
					name={`payment_type`}
					component={FormInputRadio}
					type="radio"
					id='2'
					value={'2'}
					className={s.inputRadio}
					label={"Терминал"}
					required={true}
					// validate={[requiredBorder]}
				/>
				<Field
					name={`payment_type`}
					component={FormInputRadio}
					type="radio"
					id='3'
					value={'3'}
					className={s.inputRadio}
					label={"Перевод по счету"}
					required={true}
					// validate={[requiredBorder]}
				/>
				<Field
					name={`payment_type`}
					component={FormInputRadio}
					type="radio"
					id='4'
					value={'4'}
					className={s.inputRadio}
					label={"Перевод по реквизитам"}
					required={true}
					validate={[requiredBorder]}
				/>
			</div>

			<div className={s.flexBlock}>
				<span className={s.infoPaymentTitle}>Процент предоплаты: </span>
				<Field
					name={`percent`}
					component={FormInput}
					type="radio"
					id='percent_50'
					value={'50'}
					className={s.inputRadio}
					label={"50%"}
				/>
				<Field
					name={`percent`}
					component={FormInput}
					type="radio"
					id='percent_70'
					value={'70'}
					className={s.inputRadio}
					label={"70%"}
				/>
				<Field
					name={`percent`}
					component={FormInput}
					type="radio"
					id='percent_100'
					value={'100'}
					className={s.inputRadio}
					label={"100%"}
				/>
			</div>

			<div className={s.flexBlock}>
				<span className={s.infoPaymentTitle}>Получено при оформлении: </span>
				<Field
					name={`prepayment`}
					component={FormInput}
					validate={[maxLength9]}
					normalize={normalizePrice}
					className={s.atRegistrationInput}
					onChange={onChange}
				/>
				<span>руб.</span>
			</div>

			<div className={s.flexBlock}>
				{
					<span className={s.infoPaymentTitle}>Окончательный платеж (дома наличные): </span>
				}
				<Field
					name={`payment`}
					component={FormInput}
					className={s.atRegistrationInput}
					disabled={true}
				/>
				<span>руб.</span>
			</div>
		</div>
	)
}