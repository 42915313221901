import React from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import { Loader } from '../components/content/common/loader/Loader'

const tokenStorage = () => localStorage.getItem('token') || sessionStorage.getItem('token')

const mapStateToPropsForRedirect = (state) => ({
  isAuth: state.auth.isAuth,
  menu: state.app.menu
})

const withAuthRedirect = (Container) => {

  const RedirectComponent = (props) => {
    if ((!props.isAuth || !props.menu) && tokenStorage()) {
      return <Loader/>
    } else if ((!props.isAuth && !tokenStorage())) {
      return <Redirect to={'/sign-in'}/>
    }

    return <Container {...props}/>
  }

  const ConnectedAuthRedirectComponent = connect(mapStateToPropsForRedirect)(RedirectComponent)

  return ConnectedAuthRedirectComponent
}

export default withAuthRedirect