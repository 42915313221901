import React from  'react';
import { Field } from 'redux-form';
// import { FormItemTitle } from '../FormItemTitle';
import { FormTable } from '../FormTable';
import { FormInput } from './FormOrderControl';
import { maxLength, minLength, required, normalizePhone } from '../../../../utils/validators/validators';
import s from './clientInfo.module.scss'

const headlines = [
	{
		name: 'Данные',
		className: ''
	},
	{
		name: 'Поля для заполнения',
		className: ''
	}
];

const minLength5 = minLength(5);
const maxLength40 = maxLength(40);

const maxLength60 = maxLength(60);
const maxLength140 = maxLength(140);


export const ClientInfo = () => {
	return (
		<div className={s.formItemBlock}>
			<p className="form-item-title">Контактные данные клиента</p>

			<FormTable headlines={headlines}>
				<tr>
					<td><p>ФИО</p></td>
					<td>
						<Field
							name={`customer_full_name`}
							component={FormInput}
							validate={[minLength5, maxLength40, required]}
						/>
					</td>
				</tr>
				<tr>
					<td><p>Номер телефона</p></td>
					<td>
						<Field
							name={`customer_phone_number`}
							validate={[required]}
							component={FormInput}
							normalize={normalizePhone}
						/>
					</td>
				</tr>
				<tr>
					<td><p>Адрес доставки</p></td>
					<td>
						<Field
							name={`customer_address`}
							component={FormInput}
							validate={[minLength5, maxLength140, required]}
						/>
					</td>
				</tr>
				<tr>
					<td><p>Район доставки</p></td>
					<td>
						<Field
							name={`customer_district`}
							component={FormInput}
							validate={[maxLength60]}
						/>
					</td>
				</tr>
			</FormTable>
		</div>
	);
};