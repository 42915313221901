import { SET_PAYMENTS } from './types'
import { paymentsAPI } from '../api/api'
import { appActions } from './appReducer'

const initialState = {
  payments: [],
}

export type InitialStateType = typeof initialState

export const paymentsReducer = (state = initialState, action: any): InitialStateType => {
  switch (action.type) {
    case 'SET_PAYMENTS':
      return {
        ...state,
        payments: action.payments
      }

    default:
      return state
  }
}
type SetPayments = {
  type: typeof SET_PAYMENTS,
  payments: any
}

export const actionsPayment = {
  setPayments: (payments: SetPayments) => ({type: 'SET_PAYMENTS', payments} as const),
}

export const getPayments = (filter: string) => (dispatch: Function) => {
  return paymentsAPI.getPayments(filter)
    .then((response: any) => {
      dispatch(actionsPayment.setPayments(response))
    })
    .catch((error: any) => {
      if (error.response) {
        dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      }
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
