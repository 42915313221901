import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {toggleClass} from '../function/toggleClass';
import useOnClickOutside from '../hooks/useOnClickOutside';

import s from './header.module.scss';

export const HeaderProfile = props => {
	const {shortName, logout} = props;

	const [openDrop, setOpenDrop] = useState(false);
	const ref = useRef(null);
	const avatar = useSelector(state => state.auth.avatar)

	const openDropDown = () => setOpenDrop(openDrop => !openDrop);

	const handleClickOutside = () => setOpenDrop(false);
	useOnClickOutside(ref, handleClickOutside);

	const dropDownClass = toggleClass(s.profileDropdown, s.profileDropdownOpen, openDrop);
	
	return (
		<div className={s.header__profile} onClick={openDropDown}>
			<p className={s.header__profileName}>{shortName}</p>
			<div className={s.header__profileImg}>
				<img src={avatar || '../../image/header/account.png'} alt="account"/>
			</div>

			<div className={s.header__profileShowDropdown}></div>
			<ul className={dropDownClass.join(' ')} ref={ref}>
				<Link to='/private-office/personal-data'>
					<li>
						<img src="../../image/header/profile.png" alt="profile"/>
						<p>Личный кабинет</p>
					</li>
				</Link>

				<li onClick={logout}>
					<img src="../../image/header/exit.png" alt="exit"/>
					<p>Выход</p>
				</li>
			</ul>
		</div>
	);
};