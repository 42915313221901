import { materialsAPI } from '../api/api'

import { appActions } from './appReducer'
import { InferActionsTypes } from './rootReducer'

const initialState = {
  materialsToOrder: [] as any[],
  materialsAwaiting: [] as any[],
  materialsArchive: [] as any[]
}
export type InitialStateType = typeof initialState
export const materialsReducer = (state = initialState, action: InferActionsTypes<typeof materialsActions>): InitialStateType => {
  switch (action.type) {
    case 'GET_MATERIALS_TO_ORDER':
      return {...state, materialsToOrder: [...action.payload?.results.filter((item: any) => !!item?.materials.length)]}

    case 'GET_MATERIALS_AWAITING':
      return {...state, materialsAwaiting: [...action.payload?.results.filter((item: any) => !!item?.materials.length)]}

    case 'GET_MATERIALS_ARCHIVE':
      return {...state, materialsArchive: action.payload}

    default:
      return state
  }
}

export const materialsActions = {
  getMaterialsToOrder: (materials: any) => ({type: 'GET_MATERIALS_TO_ORDER', payload: materials}),
  getMaterialsAwaiting: (materials: any) => ({type: 'GET_MATERIALS_AWAITING', payload: materials}),
  getMaterialsArchive: (materials: any) => ({type: 'GET_MATERIALS_ARCHIVE', payload: materials})
}

// получение материалов с нужным статусом с сервера
export const getMaterialsFromServer = (status: number) => (dispatch: any) => {
  dispatch(appActions.showLoader(true))
  return materialsAPI.getMaterials(status)
    .then((response: any) => {
      if (status === 1) dispatch(materialsActions.getMaterialsToOrder(response.data))
      if (status === 2) dispatch(materialsActions.getMaterialsAwaiting(response.data))
      if (status === 3) dispatch(materialsActions.getMaterialsArchive(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
// Заказ материалов
export const orderMaterials = (id: number, data: any) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))
  return materialsAPI.orderMaterials(id, data)
    .then((response: any) => {
      dispatch(getMaterialsFromServer(1))
      dispatch(getMaterialsFromServer(2))
      dispatch(appActions.showModal(false))
      dispatch(appActions.showPrompt('SUCCESS', 'Данные успешно сохранены'))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}

//создать заказ
export const orderMaterialCreate = (data: any, cb: Function) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))
  return materialsAPI.orderMaterialCreate(data)
    .then((response: any) => {
      dispatch(getMaterialsFromServer(1))
      dispatch(getMaterialsFromServer(2))
      cb()
      dispatch(appActions.showModal(false))
      dispatch(appActions.showPrompt('SUCCESS', 'Данные успешно сохранены'))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}

export const getMaterialsTypeFromServer = (type: number, cb: any) => (dispatch: Function) => {
  return materialsAPI.orderMaterialsType(type)
    .then((response: any) => {
      cb(response.data, type)
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}