import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Select from '../../Administration/GoodsInStock/SelectPoint/Select'

import { AppStateType } from '../../../../redux/rootReducer'
import {
  changeOrder,
  getOrdersPaymentsFromServer,
  startOrdersListeningArchive,
  startOrdersListeningPayment
} from '../../../../redux/ordersReducer'
import { OrderType } from '../../../../types/types'
import { normalizePrice } from '../../../../utils/validators/validators'
import { paymentTypeChoice } from '../../../../configs/orderConfig'
import { paymentType } from '../../../../configs/enums'
import { ComponentLoader } from '../../common/LoaderFull/ComponentLoader'
import { getRoles } from '../../../../redux/usersReducer'

import s from './payment.module.scss'

const Payment = () => {
  const idUser = useSelector((state: AppStateType) => state.auth.id)
  const orders = useSelector((state: AppStateType) => state.orders.ordersPayment.results)
  const [filterTypeOrders, setFilterTypeOrders] = useState<OrderType[]>([])
  const [loadingP, setLoadingP] = useState(true)
  const {role, workpoint} = useSelector((state: AppStateType) => state.auth)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(startOrdersListeningPayment())
  }, [dispatch])

  useEffect(() => {
    let addWorkpoint = ''
    let isAdmin = ''
    if (role !== 'Admin_prod' && role !== 'Admin' && role !== 'SalesRop') {
      addWorkpoint = `&workpoint_id=${workpoint}`
    }
    if (role === 'Admin_prod' || role === 'Admin' || role === 'SalesRop') {
      isAdmin = `&workpoint__not=6`
    }

    dispatch(getOrdersPaymentsFromServer(
      '2',
      `${addWorkpoint}${isAdmin}&payment__gte=0&limit=500&finalpayment_type__isnull=true&payment_gt=0&order=-created_datetime`,
      () => setLoading(false))
    )
    dispatch(startOrdersListeningArchive())
    dispatch(getRoles(''))

    // eslint-disable-next-line
  }, [role, workpoint])

  useEffect(() => {
    if (orders.length && !loading) {
      setFilterTypeOrders(orders.filter(order => !order.finalpayment_type && order.payment > 0).reverse())
    }
  }, [orders, loading])
  useEffect(() => {
    if ((!loading && orders.length && filterTypeOrders.length)
      || (!loading && !orders.length)) {
      setLoadingP(false)
    }
  }, [loading, filterTypeOrders, orders])

  const setFormat = (id: number, choice: any) => {
    dispatch(changeOrder(id, {finalpayment_type: paymentType[choice.prefix], responsible_for_payment_user: idUser}))
  }

  if (loadingP) {
    return <ComponentLoader/>
  }


  return (
    <div className={s.table}>
      <div className={s.headerTable}>
        <div>№</div>
        <div>Дата создания</div>
        <div>Окончательный платеж</div>
        <div>Форма оплаты</div>
      </div>

      <div className={s.contentTable}>
        {
          filterTypeOrders.length
            ? filterTypeOrders.map(({
                                      id,
                                      code,
                                      created_datetime,
                                      payment,
                                      finalpayment_type,
                                      document_link,
                                      price,
                                      status
                                    }) => (
              <div key={id} className={s.contentRow}>
                <div className={s.orderField}>
                  <a href={document_link ? document_link : undefined} target={'_blank'} rel="noreferrer">
                    {code}
                  </a>
                </div>
                <div className={s.orderField}>{created_datetime}</div>
                <div className={s.orderField}
                     onClick={() => console.log(code, ', id заказа: ' + id, ', price: ' + price, ', status: ' + status)}
                >
                  {normalizePrice((payment).toString())} руб
                </div>

                <Select
                  activeChoice={paymentType[finalpayment_type]}
                  choices={paymentTypeChoice}
                  setNewChoice={(choice) => setFormat(id, choice)}
                  isConfirm={true}
                />
              </div>
            ))
            : <p className={s.emptyGoods}>Договоры отстутствуют</p>
        }
      </div>
    </div>
  )
}

export default Payment
