// получить id или имя ножек/молдинга

import { LegMolding } from '../../types/types'

export const getLegsMoldings = (arr: LegMolding[], value: string | number) => {
  return arr.find(item => {
    return item.id === value
  })
}

