import React, { useEffect, useState } from 'react'

import s from '../productionOrders.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  actionsOrder,
  getOrdersFromServerForProductionArchive,
  startOrdersListeningArchive
} from '../../../../redux/ordersReducer'
import { appActions } from '../../../../redux/appReducer'
import { getStatusName } from '../../../function/getStatusName'

const Archive = ({showOrder}) => {
  const orders = useSelector(state => state?.orders?.ordersProductionArchive?.results)
  const [orderSortDate, setOrderSortDate] = useState([{}])

  // подгрузка заказов
  const [fetching, setFetching] = useState(true)
  const pageStore = useSelector((state) => state?.orders.pageOrdersProductionArchive)
  const nextPage = useSelector((state) => state?.orders.ordersProductionArchive.next)
  const dispatch = useDispatch()

  useEffect(() => {
    if (orders?.length) {
      setOrderSortDate(orders?.sort((a, b) => {
          const dateFirst = a?.load_date?.split('.').reverse().join(', ')
          const dateSecond = b?.load_date?.split('.').reverse().join(', ')

          a = new Date(dateFirst)
          b = new Date(dateSecond)
          return a > b ? -1 : a < b ? 1 : 0
        })
          .sort((a, b) => a.status - b.status)
          .filter(item => item.status >= 6)
      )
    }
  }, [orders])
  useEffect(() => {
    if (orderSortDate.length < 50) {
      setFetching(true)
    }
  }, [orderSortDate])
  useEffect(() => {
    dispatch(startOrdersListeningArchive())
  }, [dispatch])
  useEffect(() => {
    if (!fetching) return

    if (fetching && nextPage !== null && nextPage.indexOf(`&page=${pageStore + 1}`)) {
      dispatch(getOrdersFromServerForProductionArchive('5',
        `&page=${pageStore}&substatus=SHIPPED&order=status,-created_datetime`, () => {
          setFetching(false)
          dispatch(actionsOrder.setPageProductionArchive(pageStore + 1))
          dispatch(appActions.showLoader(false))
        }))
    }

    // eslint-disable-next-line
  }, [dispatch, fetching])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
      dispatch(actionsOrder.resetStateOrders())
      setOrderSortDate([])
    }
    // eslint-disable-next-line
  }, [])

  const scrollHandler = (e) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }

  const onLogOrder = (order) => console.log(order)

  return (
    <div className={s.tableOrder}>
      <div className={s.header}>
        <div className={s.numbers}>№</div>
        <div className={s.compiler}>Составитель</div>
        <div className={s.info}>Информация</div>
        <div className={s.status}>Статус</div>
        <div className={s.dateCreate}>Дата отгрузки</div>
      </div>
      <div className={s.content}>
        {
          orderSortDate?.map(item => (
            <div key={item.code + item.id} className={s.contentRow}>
              <div className={s.numbers}>{item.code}</div>
              <div className={s.compiler}>
                {
                  item?.responsible_user
                    ? `${item?.responsible_user?.last_name} ${item?.responsible_user?.first_name} ${item?.responsible_user?.middle_name}`
                    : 'Пользователь удалён'
                }
              </div>
              <div className={s.info}>
                <button className={s.orderView} onClick={() => showOrder(item)}>
                  Смотреть
                </button>
              </div>
              <div
                className={s.status}>{(item.status === 6 && item.substatus) ? 'Отгружено' : getStatusName(item.status)}</div>
              <div className={s.dateCreate} onClick={() => onLogOrder(item)}>{item.load_date}</div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Archive