import React, {useCallback, useEffect, useState} from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {compose} from 'redux'
import {useDispatch, useSelector} from 'react-redux'
import {CustomPrompt} from './components/content/common/customPrompt/CustomPrompt'
import {Loader} from './components/content/common/loader/Loader'

import {startWSChannel, stopWSChannel} from './redux/appReducer'

import {ContentComponent} from './components/content/ContentComponent'
import {FooterComponent} from './components/footer/FooterComponent'
import HeaderComponent from './components/header/HeaderComponent'
import {SiderComponent} from './components/sider/SiderComponent'
import withAuthRedirect from './hoc/withAuthRedirect'
import {getAuthUserData} from './redux/authReducer'
import Login from './components/login/Login'

import './App.scss'

const MainApp = ({isAuth}) => {
  const userId = useSelector(state => state.auth.id)
  const menu = useSelector(state => state.app?.menu)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userId) {
      dispatch(startWSChannel(userId))
    }

    return () => dispatch(stopWSChannel())
    // eslint-disable-next-line
  }, [userId])

  const [collapsed, setCollapsed] = useState(false)

  const toggleCollapsed = useCallback(() => {
    setCollapsed(collapsed => !collapsed)
    // eslint-disable-next-line
  }, [dispatch])

  if (!userId && !menu && !isAuth) return <Loader/>

  return (
    <>
      <Route path={'/'}>
        <div className="block-row">
          <HeaderComponent toggleCollapsed={toggleCollapsed} collapsed={collapsed}/>
          <SiderComponent collapsed={collapsed} setCollapsed={setCollapsed}/>
          <ContentComponent/>
          <FooterComponent/>
        </div>
      </Route>
    </>
  )
}

const AppContainer = compose(withAuthRedirect)(MainApp)

const App = () => {
  const userId = useSelector(state => state.auth?.id)
  const isAuth = useSelector(state => state.auth?.isAuth)
  const dispatch = useDispatch()

  // получаем данные пользователя и меню, если прежде не получен id юзера
  useEffect(() => {
    if (!userId) {
      dispatch(getAuthUserData())
    }
  }, [dispatch, userId])

  return (
    <>
      <Loader/>
      <CustomPrompt/>

      <Router>
        <Switch>
          <Route path="/sign-in" component={Login}>
          </Route>

          <AppContainer isAuth={isAuth}/>
        </Switch>
      </Router>
    </>
  )
}

export default App
