import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { OrderType } from '../../../../../types/types'
import { getInitials } from '../../../../function/getInitials'
import { normalizePrice } from '../../../../../utils/validators/validators'
import RenderDayPicker from '../RenderDayPicker/RenderDayPicker'
import ButtonsModal from '../ButtonsModal/ButtonsModal'

import useCheckRights, { ROLES_FOR_ACCESS_DATE } from '../../../../hooks/useCheckRights'
import { changeOrder } from '../../../../../redux/ordersReducer'
import { AppStateType } from '../../../../../redux/rootReducer'
import {
  orderStatusesForClient,
  orderStatusesForServer,
  orderSubStatusesForClient,
  paymentType
} from '../../../../../configs/enums'

import s from '../ordersList.module.scss'
import { getStatus } from '../../../../function/getStatus'
import Prepayment from './Prepayment/Prepayment'

interface IProps {
  filterOrders: Array<OrderType>
}

const RenderOrders: FC<IProps> = ({filterOrders}) => {
  const role = useSelector((state: AppStateType) => state.auth.role)
  const dispatch = useDispatch()

  const isHasRights = useCheckRights(ROLES_FOR_ACCESS_DATE, role)

  const sendToWork = (id: number) => {
    dispatch(changeOrder(id, {
      status: 2,
      prepayment_date: new Date().toLocaleDateString()
    }))
  }
  const setDate = (id: number, day: Date) => {
    dispatch(changeOrder(id, {
      load_date: day.toLocaleDateString(),
      substatus: 'SHIPPED'
    }))
  }

  return (
    <div className={s.tableContent}>
      {
        filterOrders.length
          ? filterOrders.map((item) => (
            <div key={item.id} className={s.tableRow}>
              <div className={s.numbers}
                   title={item.in_hall ? `${item.code} (зал)` : item.code}
                   onClick={() => console.log(item)}
              >
                <span>{item.in_hall ? `${item.code} (зал)` : item.code}</span>
              </div>
              <div className={s.dateCreate} title={item.created_datetime}>
                <span>{item.created_datetime}</span>
              </div>
              <div className={s.status}
                   title={
                     item?.substatus && item.status === orderStatusesForServer.finish
                       ? orderSubStatusesForClient[item.substatus]
                       : orderStatusesForClient[getStatus(item.status)]
                   }
              >
              <span>
                {
                  item?.substatus && item.status === orderStatusesForServer.finish
                    ? orderSubStatusesForClient[item.substatus]
                    : orderStatusesForClient[getStatus(item.status)]
                }
              </span>
              </div>
              <div className={s.sum}>
                <span>{normalizePrice(`${item.price}`)}</span>
              </div>
              <div className={s.prepayment}>
                <Prepayment idOrder={item.id}
                            status={item.status}
                            prepayment_fact={item.prepayment_fact}
                            prepayment={item.prepayment}
                            payment_type={item.payment_type}/>
              </div>
              <div className={s.payment}>
              <span>
                {
                  item.finalpayment_type &&
                  normalizePrice(`${item.payment}`) + ' - ' + paymentType[item.finalpayment_type]
                }
                {
                  item.finalpayment_type === 0 && '0'
                }
              </span>
              </div>
              <div className={s.compiler}
                   title={`${item.responsible_user.last_name} ${item.responsible_user.first_name} ${item.responsible_user.middle_name}`}>
                <span>{getInitials(item.responsible_user.first_name, item.responsible_user.middle_name, item.responsible_user.last_name)}</span>
              </div>
              <div className={s.dateDelivery}>
                {
                  isHasRights
                  && item.status === orderStatusesForServer.finish
                  && !item.substatus
                    ? <RenderDayPicker
                      id={item.id}
                      setDate={setDate}
                      // load_date={item.load_date}
                    />
                    : <span>{item.load_date || '__.__.____'}</span>
                }
              </div>
              <div className={s.buttonsOrder}>
                <ButtonsModal id={item.id}
                              document_link={item.document_link}
                              status={item.status}
                              sendToWork={sendToWork}
                />
              </div>
            </div>
          ))
          : <p className={s.searchFall}>Ничего не найдено</p>
      }
    </div>
  )
}

export default RenderOrders