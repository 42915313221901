import React, {FC, useCallback, useRef, useState} from 'react'
import s from './selectPoint.module.scss'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import ButtonPoint from "./ButtonPoint/ButtonPoint"
import {Confirmation} from '../../../common/confirmation/Confirmation'

interface IChoices {
    id: number
    prefix: string
}

interface ISelectPoint {
    activeChoice: string
    choices: IChoices[]
    setNewChoice: (choice: any) => void
    isConfirm?: boolean
}

const Select: FC<ISelectPoint> = ({activeChoice, choices, setNewChoice, isConfirm = false}) => {
    const [isListPoints, setIsListPoints] = useState(false)
    const [newPoint, setNewPoint] = useState<string>('')
    const [openConfirm, setOpenConfirm] = useState(false)

    const showPoint = useCallback(() => {
        setIsListPoints(value => !value)
    }, [])

    const setPoint = (choice: any) => {
        setNewChoice(choice)
        setIsListPoints(false)
        setOpenConfirm(false)
    }

    const showConfirmModal = (choice: any) => {
        if (isConfirm) {
            setNewPoint(choice)
            setOpenConfirm(true)
        } else {
            setPoint(choice)
        }
    }

    const refPoint = useRef(null)
    useOnClickOutside(refPoint, () => setIsListPoints(false))

    return (
        <div className={s.filter} ref={refPoint}>
            {openConfirm &&
                <Confirmation
                    text={`Вы действительно хотите подтвердить получение окончательного платежа по этому договору?`}
                    submitData={() => setPoint(newPoint)}
                    handleCancel={() => setOpenConfirm(false)}
                    textConfirm={"Подтвердить"}
                    textCanel={"Отмена"}
                />
            }
            <ButtonPoint
              activeChoice={activeChoice}
              isList={isListPoints}
              showList={showPoint}
              text={'Выберите  формат'}
            />

            {
                isListPoints &&
                <ul className={s.listPoints}>
                    {
                        choices.map(choice => <li key={choice.id} onClick={() => showConfirmModal(choice)}>{choice.prefix}</li>)
                    }
                </ul>
            }
        </div>
    )
}

export default Select