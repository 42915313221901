import React from 'react'

import { Link } from 'react-router-dom'

import s from './footer.module.scss'

export const FooterComponent = () => {
  return (
    <div className={s.footer}>
      <div className="container">
        <div className={s.footer__down}>
          <span>{(new Date()).getFullYear()} © Agenda</span>

          <Link to='/confidentiality'>
            <span className={s.tabsLink}>Политика конфиденциальности</span>
          </Link>
        </div>
      </div>
    </div>
  )
}