import React from 'react';

import './_style-button.scss';

export const Button = props => {
	const { className, title, onClick, disabled, icon = false } = props;

	return (
		<button
			className={`button-custom ${className}`}
			onClick={onClick}
			disabled={disabled}
		>
			{icon && <span>+</span>}
			{title}
		</button>
	);
};