import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Redirect, Route } from 'react-router-dom'

import { appActions } from '../../../redux/appReducer'
import ModalInfo from './ModalInfo/ModalInfo'
import { actionsOrder, getOrderFromServer, } from '../../../redux/ordersReducer'
import { tabsOrderProductionPanel } from '../../../configs/menuСonfiguration'

import { Modal } from '../common/modal/Modal'
import Archive from './Archive/Archive'
import CurrentOrders from './CurrentOrders/CurrentOrders'
import { CustomPrompt } from '../common/customPrompt/CustomPrompt'
import Tabs from '../common/Tabs/Tabs'

import s from './productionOrders.module.scss'

export const ProductionOrders = () => {
  const orderInfo = useSelector((state) => state?.orders?.orderInfo)
  const [modalClass, setModalClass] = useState(false)
  const [content, setContent] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const {url} = useRouteMatch()
  const dispatch = useDispatch()

  // подробная информация о заказе
  useEffect(() => {
    if (orderInfo.length > 0) {
      setContent(orderInfo[0])
      dispatch(appActions.showModal(true))
      setModalClass(true)
    }
  }, [dispatch, orderInfo])

  const showOrder = (order) => {
    dispatch(getOrderFromServer(order.id))
  }
  const removeDataOrder = () => dispatch(actionsOrder.removeDataOrderFromStore())

  return (
    <>
      <CustomPrompt/>

      <section className={s.orderArchive}>
        <div className="container">
          <h1 className={s.orderArchive_title}>Заказы производство</h1>
          <Tabs type="tabs" tabs={tabsOrderProductionPanel}/>

          <div>
            <Redirect to={`${url}/orders`}/>

            <Route path={`${url}/orders`}>
              <CurrentOrders showOrder={showOrder}/>
            </Route>
            <Route path={`${url}/archive`}>
              <Archive showOrder={showOrder}/>
            </Route>
          </div>
        </div>
      </section>

      <Modal modalClass={modalClass}
             setModalClass={setModalClass}
             className={s.orderArchive_modal}
             styleProps={{width: '648px'}}
             isEdit={isEdit}
             callFn={[removeDataOrder]}>
        <ModalInfo content={content}
                   isEdit={isEdit}
                   setIsEdit={setIsEdit}
                   isAvailableForEditing={true}/>
      </Modal>
    </>
  )
}