import React, { useRef, useState, useEffect, useMemo } from 'react'
import {useDispatch} from 'react-redux'
import { change, Field } from 'redux-form'
import DayPicker, { DateUtils } from 'react-day-picker'

import { FormDayPickerInput } from '../../common/FormsControl'

import useOnClickOutside from '../../../hooks/useOnClickOutside'

import { required } from '../../../../utils/validators/validators'

import 'react-day-picker/lib/style.css'

const WEEKDAYS_SHORT = {ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']}

const MONTHS = {
	ru: [
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Май',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь'
	]
}

export const DatePickerInput = props => {
	const { name, pathName, formName, valueInput='',
		className = '', disabledDate = '', require=false, agreement = false
	} = props

	const [open, setOpen] = useState(false)
	const [view, setView] = useState(false)
	const [date, setDate] = useState({
		from: undefined,
		to: undefined
	})
	const [disadledDays, setDisadledDays] = useState({})
	const [hasError, setHasError] = useState({show: false, error: ''})
	const [dash, setDash] = useState('')
	const ref = useRef(null)
	const dispatch = useDispatch()

	let { from, to } = date
	const modifiers = { start: from, end: to }

	// формирование класса ошибки при срабатывании валидатора
	const classError = hasError.show ? 'date-picker-error' : ''

	// формирование name, чтобы правильно ложить в стор
	const inputName = useMemo(() => {
		return pathName
			? {from: `${pathName}.${name}_from`, to: `${pathName}.${name}_to`}
			: {from: `${name}_from`, to: `${name}_to`}
	}, [pathName, name])

	// разбиваем дату для выключения ненужных дней в календаре
	useEffect(() => {
		if (disabledDate === '') return
		setDisadledDays(
			{
				year: +disabledDate.split('.')[2],
				month: +disabledDate.split('.')[1],
				day: +disabledDate.split('.')[0]
			}
		)
	}, [disabledDate])

	// открытие календаря
	const openCalendar = () => setOpen(!open)

	// закрытие календаря по нажатию вне области
	const handleClickOutside = () => setOpen(false)
	useOnClickOutside(ref, handleClickOutside)
	
	// меняем формат для проверки даты
	const changeFormat = (val) => new Date(val.split('.').reverse().join('-'))

	// проверка и запись в стор данных, полученных с календаря
	useEffect(() => {
		if (from) {
			dispatch(change(formName, inputName.from, from.toLocaleDateString()))
		}
		if(from && to) {
			const disabledFormatDate = changeFormat(disabledDate)
			let fromDate = from
			let toDate = to

			if (agreement) {
				const datePickFrom = new Date(from)
				const datePickTo = new Date(toDate)
				const dateFrom = datePickFrom.getDate()
				const dateTo = datePickTo.getDate()
				const isDayWeekFrom = datePickFrom.getDay()
				const isDayWeekTo = datePickTo.getDay()
				let addCountDays = 0

				if (isDayWeekFrom === 6 && isDayWeekTo === 6) {
					if (dateFrom !== dateTo) {
						addCountDays += 4
					} else {
						addCountDays += 3
					}
				} else if ((isDayWeekFrom === 6 && isDayWeekTo === 4)
					|| (isDayWeekFrom === 6 && isDayWeekTo === 5)) {
					addCountDays += 4
				} else if (isDayWeekFrom === 6 && isDayWeekTo === 0) {
					if ((dateFrom + 1) !== dateTo) {
						addCountDays += 3
					} else {
						addCountDays += 2
					}
				} else if (isDayWeekFrom === 0 && isDayWeekTo === 6) {
					addCountDays += 3
				} else if (isDayWeekFrom === 0 && isDayWeekTo === 5) {
					addCountDays += 3
				} else if (isDayWeekFrom === 0 && isDayWeekTo === 0) {
					if (dateFrom === dateTo) {
						addCountDays += 1
					} else {
						addCountDays += 2
					}
				} else if (isDayWeekFrom === 0) {
					addCountDays += 1
				} else if (isDayWeekTo === 0) {
					addCountDays += 2
				} else if (isDayWeekFrom === 6) {
					addCountDays += 2
				} else if (isDayWeekTo === 6) {
					addCountDays += 3
				}

				toDate = new Date(toDate.getFullYear(), toDate.getMonth(),toDate.getDate() + addCountDays)
			}

			if (disabledFormatDate &&
				(from < disabledFormatDate || to < disabledFormatDate)) return

			if (fromDate.toLocaleDateString().toString() !== toDate.toLocaleDateString().toString()) {
				dispatch(change(formName, inputName.to, toDate.toLocaleDateString()))
			} else {
				dispatch(change(formName, inputName.to, ''))
			}

			setView(true)
		} else {
			setView(false)
		}
		if (!to) {
			dispatch(change(formName, inputName.to, ''))
		}

		if (to && from && (from.toLocaleDateString() !== to.toLocaleDateString())) {
			setDash('-')
		} else {
			setDash('')
		}
		// eslint-disable-next-line
	}, [date])

	// проверяем дату, которая уже может лежать в форме
	useEffect(() => {
		if (!date.to && !date.from && valueInput.to && valueInput.from) {
			setDate({from: changeFormat(valueInput.from), to: changeFormat(valueInput.to)})
		}
		// eslint-disable-next-line
	}, [valueInput])

	// получение данных с календаря
	const handleDayClick = day => {
		const range = DateUtils.addDayToRange(day, date)
		if (range.from !== range.to) {
			setDate(range)
		} else {
			setDate({from: range.from, to: undefined})
		}
	}

	// очистка input и календаря по нажатию крестика
	const clearValue = () => {
		setDate({from: undefined, to: undefined})
		dispatch(change(formName, inputName.from, ''))
		dispatch(change(formName, inputName.to, ''))
	}

	return (
		<div className={`order-data-picker ${className}`} ref={ref}>
			<div
				className={`date-picker-block ${className} ${classError}`}
				onClick={openCalendar}
			>
				<Field
					component={FormDayPickerInput}
					name={`${name}_from`}
					disabled
					validate={[required]}
					setHasError={setHasError}
				/>

				{
					(dash && to && to.toLocaleDateString()) &&
						<>
							{dash}

							<Field
								component={FormDayPickerInput}
								name={`${name}_to`}
								disabled
								validate={require ? [required] : []}
								setHasError={setHasError}
							/>
						</>
				}

				{hasError.show && <span className="date-picker-error__text">{hasError.error}</span>} 
			</div>
			
			{(view || valueInput.to) && <button type="button" onClick={clearValue}>x</button>}

			{open &&
				<DayPicker
					className="Selectable"
					locale="ru"
					numberOfMonths={1}
					selectedDays={date}
					modifiers={modifiers}
					onDayClick={handleDayClick}
					months={MONTHS['ru']}
					weekdaysShort={WEEKDAYS_SHORT['ru']}
					disabledDays={[
						{
							before: new Date(disadledDays.year, disadledDays.month - 1, disadledDays.day),
						},
					]}
				/>
			}
		</div>
	);
};