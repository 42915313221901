import { notiesAPI } from '../api/api'

import { GET_NOTIES } from './types'

import { appActions } from './appReducer'
import { Dispatch } from 'redux'
import { wsAPI } from '../api/ws-api'

const initialState = {
  noties: [],
}

export const notiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_NOTIES:
      return {...state, noties: action.payload}

    default:
      return state
  }
}

type GetNotiesActionType = {
  type: typeof GET_NOTIES,
  payload: any
}

export const getNoties = (noties: any): GetNotiesActionType => {
  return {
    type: GET_NOTIES,
    payload: noties
  }
}

export const getNotiesFromServer = () => (dispatch: Function) => {
  return notiesAPI.getNoties()
    .then((response: any) => dispatch(getNoties(response.data)))
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}

export const readNoties = (ids: number) => (dispatch: Function) => {
  return notiesAPI.readNoties(ids)
    .then(() => {
      console.log('read noties without new request')
      // dispatch(getNotiesFromServer())
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}

export const deleteNotice = (id: number) => (dispatch: any) => {
  return notiesAPI.deleteNonice(id)
    .then(() => dispatch(getNotiesFromServer()))
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}

const notiesHandler = (dispatch: Function) => () => {
  return dispatch(getNotiesFromServer())
}
export const startNotiesListening = () => (dispatch: Dispatch) => {
  wsAPI.subscribeNoties(notiesHandler(dispatch))
}