import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import { AwaitingReceiptCard } from './AwaitingReceiptCard'

import {getMaterialsFromServer} from '../../../../../redux/materialsReducer'

import s from '../../orderingMaterials.module.scss'

export const AwaitingReceipt = ({ showInfo }) => {
	const materials = useSelector(state => state?.materials?.materialsAwaiting)
	const userId = useSelector(state => state.auth.id)
	const dispatch = useDispatch()

	// Получение материалов с сервера со статусом 2, если id юзера получен
	useEffect(() => {
		if (userId) {
			dispatch(getMaterialsFromServer(2))
		}
	}, [dispatch, userId])

	if (!materials || materials.length <= 0) return null

	return (
		<div className={s.orders_awaitingReceipt}>
			<p className={s.ordersItem_title}>Ожидает поступление</p>

			<div className={s.ordersItem_content}>
				{materials?.map(item => (
					(item.status === 2) && <AwaitingReceiptCard key={item.id} item={item} showInfo={showInfo}/>
				))}
			</div>
		</div>
	)
}

