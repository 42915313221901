import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {change, reduxForm} from 'redux-form'

import {createField, Input, Textarea} from '../../common/FormsControl'
import {
  isNumber, isPositive,
  isSelectedFromList,
  requiredBorder
} from '../../../../utils/validators/validators'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import {orderMaterialCreate} from '../../../../redux/materialsReducer'
import {DatePickerInput} from '../../orders/FormOrderBlock/DatePickerInput'
import {getOrdersFromServer} from '../../../../redux/ordersReducer'

import {isChangeName, isOptionsToChange, namesProducts, optionsToChange} from '../../../function/changeNameProduct'

import s from './orderMaterial.module.scss'

const OrderMaterialRender = ({handleSubmit, closeModal}) => {
  const orders = useSelector(state => state?.orders?.orders?.results)
  const orderName = useSelector(state => state?.form?.materials?.values?.order)
  const orderId = useSelector(state => state?.form?.materials?.values?.orderId)
  const orderProductName = useSelector(state => state?.form?.materials?.values?.product_name)
  const [showList, setShowList] = useState(false)
  const [filterOrders, setFilterOrders] = useState([])
  const [order, setOrder] = useState({})
  const [orderProducts, setOrderProducts] = useState([])
  const [showListProduct, setShowListProduct] = useState(false)
  const [productActive, setProductActive] = useState(true)
  const modal = useRef(null)
  const ordersList = useRef(null)
  const productsList = useRef(null)
  const dispatch = useDispatch()

  // логика работы с именем заказа
  useEffect(() => {
    (orderName && showList)
      ? setFilterOrders(orders.filter(item => {
          const isCoincidences = item.code.toLowerCase().indexOf(orderName.toLowerCase()) >= 0
          return isCoincidences ? ({id: item.id, code: item.code, items: item.items}) : false
        }))
      : setFilterOrders(orders.map(item => ({id: item.id, code: item.code, items: item.items})))
    // eslint-disable-next-line
  }, [orderName, showList])
  useEffect(() => {
    const isFromList = orders.find(item => item.code === orderName)
    if (isFromList) {
      dispatch(changeStore('orderId', isFromList.id))
      setOrder(isFromList)
    } else {
      dispatch(changeStore('orderId', null))
      setOrder({})
    }
    dispatch(changeStore('product_name', null))
    // eslint-disable-next-line
  }, [orderName])
  useEffect(() => {
    if (!orderId) {
      setProductActive(true)
    } else {
      setProductActive(false)
    }
    // eslint-disable-next-line
  }, [orderId])
  // логика работы с продуктами из заказа
  useEffect(() => {
    if (order?.items?.length > 0) {
      setOrderProducts(order?.items?.map(item => ({
        product: !isChangeName(namesProducts, item.product)
          ? `${item.product}`
          : isOptionsToChange(optionsToChange, item.options)
            ? item.product.replace('Д/О', 'Д.к. ') : item.product
        }))
      )
    }
    // eslint-disable-next-line
  }, [orderProductName, order])

  useOnClickOutside(modal, () => closeModal(false))
  useOnClickOutside(ordersList, () => setShowList(false))
  useOnClickOutside(productsList, () => setShowListProduct(false))


  // функция для изменения стора
  const changeStore = (to, value) => change('materials', to, value)

  const onClickOrder = () => {
    setShowList(true)
  }
  const onClickProduct = () => {
    setShowListProduct(true)
  }
  const setOrderName = (id, code) => {
    dispatch(changeStore('orderId', id))
    dispatch(changeStore('order', code))
    setShowList(false)
  }
  const setProductName = (name) => {
    dispatch(changeStore('product_name', name))
    setShowListProduct(false)
  }

  let isSelected = isSelectedFromList(orders)
  // let isSelectedProduct = isSelectedFromListProduct(orderProducts)

  return (
    <div>
      <div className={s.modalMat}>
        <div className={s.modal} ref={modal}>
          <button onClick={closeModal} className={s.closeForm}>x</button>

          <h3 className={s.modalTitle}>Форма заказа</h3>

          <div className={s.orderName}>
            <span className={s.order}>Договор</span>
            <div className={s.orderNames}>
              {
                createField('', 'order', [requiredBorder, isSelected], Input,
                  {
                    classWrap: s.orderNameWrap,
                    className: s.treatyFieldInput,
                    onClick: onClickOrder,
                    autoComplete: 'off'
                  })
              }
              {
                createField('', 'orderId', [], Input,
                  {
                    className: s.treatyFieldInput,
                    hidden: true
                  })
              }
              {
                showList &&
                <div className={s.orderList} ref={ordersList}>
                  {
                    (filterOrders.length > 0)
                      ? filterOrders.map(item => <span key={item.id}
                                                       onClick={() => setOrderName(item.id, item.code)}
                                                       className={s.orderItem}>{item.code}</span>)
                      : <span>Совпадения не найдены</span>
                  }
                </div>
              }
            </div>
          </div>
          <div className={s.data}>
            <div className={s.item}>
              <span className={s.itemName}>Товар</span>
              <div className={s.productsNames}>
                {
                  createField('', 'product_name', [requiredBorder], Input,
                    {
                      className: s.dataFieldInput,
                      classWrap: s.dataFieldInputWrap,
                      disabled: productActive,
                      onClick: onClickProduct,
                      autoComplete: 'off'
                    })
                }
                {
                  showListProduct &&
                  <div className={s.productsList} ref={productsList}>
                    {
                      (orderProducts.length > 0)
                        ? orderProducts.map((item, index) => (
                          <span key={index}
                                onClick={() => setProductName(item.product)}
                                className={s.orderItem}>{item.product}
                          </span>))
                        : <span>Совпадения не найдены</span>
                    }
                  </div>
                }
              </div>
            </div>
            <div className={s.item}>
              <span className={s.itemName}>Материал</span>
              {createField('', 'material', [requiredBorder], Input,
                {
                  className: s.dataFieldInput,
                  classWrap: s.dataFieldInputWrap
                })}
            </div>
            <div className={s.item}>
              <span className={s.itemName}>Наименование</span>
              {createField('', 'name', [requiredBorder], Input,
                {
                  className: s.dataFieldInput,
                  classWrap: s.dataFieldInputWrap
                })}
            </div>
            <div className={s.item}>
              <span className={s.itemName}>Поставщик</span>
              {createField('', 'provider', [requiredBorder], Input,
                {
                  className: s.dataFieldInput,
                  classWrap: s.dataFieldInputWrap
                })}
            </div>
            <div className={s.item}>
              <span className={s.itemName}>Количество</span>
              {createField('', 'quantity', [requiredBorder, isNumber, isPositive], Input,
                {
                  className: s.dataFieldInput,
                  classWrap: s.dataFieldInputWrap,
                  type: 'number'
                })}
            </div>
            <div className={s.item}>
              <span className={s.itemName}>Ожидаемая дата доставки</span>
              <DatePickerInput
                name="delivery_date"
                formName="materials"
                require={true}
                agreement={true}
                className={s.dataPicker}
              />
            </div>
            <div>
              {createField('Комментарий', 'note', [], Textarea,
                {
                  className: s.dataFieldTextarea
                })}
            </div>
          </div>

          <div className={s.buttons}>
            <button onClick={handleSubmit} className={s.buttonSend}>Готово</button>
          </div>
        </div>
      </div>
    </div>
  )
}

const OrderMaterialForm = reduxForm({form: 'materials'})(OrderMaterialRender)

const OrderMaterial = () => {
  const [modal, setModal] = useState(false)
  const dispatch = useDispatch()

  // eslint-disable-next-line
  const handleCancel = useCallback(() => setModal(false), [])

  const showModal = () => {
    setModal(true)
    dispatch(getOrdersFromServer())
  }
  const onSubmit = (formData) => {
    const data = {
      delivery_date_from: formData.delivery_date_from,
      delivery_date_to: formData.delivery_date_to || formData.delivery_date_from,
      material: formData.material,
      name: formData.name,
      note: formData.note || '',
      order: +formData.orderId,
      product_name: formData.product_name,
      provider: formData.provider,
      quantity: +formData.quantity,
      status: 2
    }
    dispatch(orderMaterialCreate(data, () => setModal(false)))
  }

  return (
    <div>
      <div>
        <button onClick={showModal} className={s.buttonOrder}>Сделать заказ</button>
      </div>

      {modal && <OrderMaterialForm onSubmit={onSubmit} closeModal={handleCancel}/>}
    </div>
  )
}

export default OrderMaterial