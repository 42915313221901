import React from 'react'

import { InfoUser } from '../../AdminPanel/Performers/Statistic/Statistics'

import s from './cell.module.scss'

interface ICell {
  price: string
  showModalInfo: ((data: InfoUser) => void)
  setIsModalActive: ((isActive: boolean) => void)
}

const Cell: React.FC<ICell & InfoUser> = ({ month, year, codeId, idUser, price , showModalInfo}) => {

  const onClick = () => {
    showModalInfo({
      codeId: codeId,
      idUser: idUser,
      month: month,
      year: year
    })
  }

  return (
    <span className={ s.contentPrice } onClick={onClick}>
      { price || '0' } руб.
    </span>
  )
}

export default Cell