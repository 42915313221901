import React, { useEffect } from 'react'
import s from './DataChangeRequest.module.scss'
import { createField, Textarea } from '../../common/FormsControl'
import { maxLength, minLength, required } from '../../../../utils/validators/validators'
import { reduxForm, reset } from 'redux-form'
import { changeData } from '../../../../redux/authReducer'
import { useDispatch } from 'react-redux'
import { CustomPrompt } from '../../common/customPrompt/CustomPrompt'
import { appActions } from '../../../../redux/appReducer'

const minLength4 = minLength(4)
const maxLength256 = maxLength(256)

const FormChangeData = ({handleSubmit, submitting, pristine}) => {
  return (
    <form onSubmit={handleSubmit} className={s.form}>
      <div className={s.formTextarea}>
        {createField('Опишите Вашу проблему и причину изменения данных',
          'text', [required, minLength4, maxLength256], Textarea,
          {className: `${s.formText}`})}
      </div>
      <button type="submit" className={`${s.btnGreen}`}
              disabled={submitting || pristine}>Отправить
      </button>
    </form>
  )
}

const TextReduxForm = reduxForm({form: 'changeData'})(FormChangeData)

export const DataChangeRequest = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(appActions.showPrompt(null))
  }, [dispatch])

  const onSubmit = (formData) => {
    dispatch(changeData({content: formData.text}))
    dispatch(reset('changeData'))
  }

  return (
    <>
      <CustomPrompt/>
      <div className={s.personalData}>
        <div className={s.personalContent}>
          <h2 className={s.formTitle}>Запрос на изменения данных</h2>

          <TextReduxForm onSubmit={onSubmit}/>
        </div>
      </div>
    </>
  )
}
