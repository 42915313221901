import React, { useEffect } from 'react'
import { change, FieldArray, FormSection, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '../../common/Buttons/button/Button'
import Goods from '../Goods/Goods'
import { FormOfPayment } from '../Goods/FormOfPayment/FormOfPayment'
import { ClientInfo } from '../FormOrderBlock/ClientInfo'
import { DateOfImplementation } from '../FormOrderBlock/DateOfImplementation'

import { createField, Input } from '../../common/FormsControl'
import { required } from '../../../../utils/validators/validators'
import { SvgImages } from '../../../generic'
import { appActions } from '../../../../redux/appReducer'

import s from './createOrderForm.module.scss'

let CreateOrderForm = (props) => {
  const {
    handleSubmit, invalid, itemForEdit = [],
    setIsSave, closeForm
  } = props
  const orderCode = useSelector(state => state?.orders?.orderCode)
  const workpoint = useSelector(state => state?.auth?.workpoint)
  const isFromHall = useSelector(state => state.orders.isFormFromHall)
  const dispatch = useDispatch()

  useEffect(() => {
    if (itemForEdit) {
      changeStore('fieldArrays', 'orderCode', itemForEdit.code)
    }
    // eslint-disable-next-line
  }, [dispatch, itemForEdit])

  // функция для изменения store
  const changeStore = (formName, to, value) => dispatch(change(formName, to, value))

  const sendForm = (e) => {
    e.preventDefault()
    if (invalid) {
      dispatch(appActions.showPrompt('WARN', 'Заполните всю форму'))
    }
    setIsSave(false)
    handleSubmit()
  }
  const saveForm = (e) => {
    e.preventDefault()
    if (invalid) {
      dispatch(appActions.showPrompt('WARN', 'Заполните всю форму'))
    }
    setIsSave(true)
    handleSubmit()
  }

  return (
    <>
      <form autoComplete="off">
        <button onClick={closeForm} className={s.closeCreateOrder}>x</button>

        <div className="create-order-title">
          {
            (workpoint === 6)
              ? <div className={s.agreementName}>
                <span>Договор {'В зал-'}</span>
                {
                  createField('', 'code', [required], Input, {
                    className: s.agreementInput,
                    classWrap: s.agreementWrap
                  })
                }
              </div>
              : `Договор ${orderCode}`
          }
        </div>

        <FieldArray name="goods" component={Goods}/>

        <FormSection name="order">
          <FormOfPayment/>
          <ClientInfo/>
          <DateOfImplementation/>
        </FormSection>

        <div className={s.btnsBlock}>
          <Button className={s.sendOrderBtn}
                  title={isFromHall ? 'Создать договор' : 'Отправить'}
                  onClick={(e) => sendForm(e)}
          />
          {
            !isFromHall &&
            <Button className={s.saveOrderBtn}
                    title={SvgImages({type: 'save'})}
                    onClick={(e) => saveForm(e)}
            />
          }

        </div>
      </form>
    </>
  )
}

export default reduxForm({form: 'fieldArrays'})(CreateOrderForm)