import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import ChangeAssortment from './ChangeAssortment/ChangeAssortment'

import { tabsAdministration } from '../../../configs/menuСonfiguration'

import Sellers from './Sellers/Sellers'
import GoodsInStock from './GoodsInStock/GoodsInStock'
import Tabs from '../common/Tabs/Tabs'

import s from './administration.module.scss'

const Administration = () => {
  const {url} = useRouteMatch()

  return (
    <div>
      <div className='container'>
        <h1 className={s.storageTitle}>Управление продажами</h1>

        <div className={s.storageContent}>
          <Tabs type="tabs" tabs={tabsAdministration}/>

          <div>
            <Redirect to={`${url}/goods-in-stock`} />

            <Switch>
              <Route path={`${url}/goods-in-stock`}>
                <GoodsInStock/>
              </Route>
              <Route path={`${url}/sellers`}>
                <Sellers/>
              </Route>
              <Route path={`${url}/changeAssortment`}>
                <ChangeAssortment/>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Administration