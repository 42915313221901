import React from 'react'
import { useSelector } from 'react-redux'

import s from './loader.module.scss'

export const Loader = () => {
	const isLoading = useSelector(state => state.app.isLoading)

	if (!isLoading) return null;
	
	return (
		<div id={s.preloader}>
			<div id={s.loader}></div>
		</div>
	)
}