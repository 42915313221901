import React, {useEffect, useRef, useState} from 'react'

import {IUser} from "../../../../../types/types"
import {Modal} from "../../../common/modal/Modal"
import Cell from '../../../common/Cell/Cell'

import ModalUserStatistic from './ModalUserStatistic/ModalUserStatistic'

import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import {useDispatch} from 'react-redux'
import {appActions} from '../../../../../redux/appReducer'

import s from './statistics.module.scss'

interface IContentCell {
    twoMonthsPrev: number
    prevMonth: number
    thisMonth: number
    prevPrevMonthYear: number | null
    prevMonthYear: number | null
    thisMonthYear: number | null
    idUser: number | null
    codeId: string
    prev2MonthSumm: string
    prevMonthSumm: string
    thisMonthSumm: string
    status: string
    showModalInfo: ((data: InfoUser) => void)
    setIsModalActive: ((isActive: boolean) => void)
}

const ContentCell: React.FC<IContentCell> = (props) => {
    const {
        twoMonthsPrev, prevMonth, thisMonth, prevPrevMonthYear, prevMonthYear, thisMonthYear, idUser,
        codeId, prev2MonthSumm, prevMonthSumm, thisMonthSumm, status,
        showModalInfo, setIsModalActive
    } = props
    const [statusUser, setStatusUser] = useState<string>('')

    useEffect(() => {
        if (status === 'active') setStatusUser(s.active)
        if (status === 'inactive') setStatusUser(s.inactive)
        if (status === 'deleted') setStatusUser(s.deleted)
    }, [status])

    return (
        <div className={s.contentItem}>
      <span className={s.contentId}>
        <span className={statusUser}> </span>
          {codeId}
      </span>
            <Cell month={twoMonthsPrev}
                  year={prevPrevMonthYear}
                  idUser={idUser}
                  codeId={codeId}
                  price={prev2MonthSumm}
                  showModalInfo={showModalInfo}
                  setIsModalActive={setIsModalActive}/>
            <Cell month={prevMonth}
                  year={prevMonthYear}
                  idUser={idUser}
                  codeId={codeId}
                  price={prevMonthSumm}
                  showModalInfo={showModalInfo}
                  setIsModalActive={setIsModalActive}/>
            <Cell month={thisMonth}
                  year={thisMonthYear}
                  idUser={idUser}
                  codeId={codeId}
                  price={thisMonthSumm}
                  showModalInfo={showModalInfo}
                  setIsModalActive={setIsModalActive}/>
        </div>
    )
}

interface IUsersSum {
    prevprev_month_summ: {
        total: string
    }
    prev_month_summ: {
        total: string
    }
    this_month_summ: {
        total: string
    }
}

interface IStatistics {
    users: Array<IUser & IUsersSum>
}

export interface InfoUser {
    codeId: string | null
    idUser: number | null,
    month: number,
    year: number | null
}

const monthsNames = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
]

const Statistics: React.FC<IStatistics> = ({users}) => {
    // получение 3x последних месяцев для таблицы
    const date = new Date()
    const thisMonth = date.getMonth()
    const monthPrev = date.getMonth() - 1
    const twoMonthsPrev = date.getMonth() - 2

    const getYear = (month: number, year: number) => month < 0 ? year - 1 : year

    // модальное окно
    const [isModalActive, setIsModalActive] = useState<boolean>(false)
    const [infoUser, setInfoUser] = useState<InfoUser>({
        codeId: null,
        idUser: null,
        month: 0,
        year: null
    })
    // ---------
    const dispatch = useDispatch()

    const modalRef = useRef(null)

    useOnClickOutside(modalRef, () => {
        setIsModalActive(false)

        showModalInfo({
            codeId: null,
            idUser: null,
            month: 0,
            year: null
        })
    })
    const showModalInfo = (data: InfoUser) => {
        setInfoUser(data)
        setIsModalActive(true)
        dispatch(appActions.showModal(true))
    }

    return (
        <div className={s.table}>
            <div className={s.head}>
                <div className={s.headCell}>ID</div>
                <div
                    className={s.headCell}>{twoMonthsPrev >= 0 ? monthsNames[twoMonthsPrev] : monthsNames[twoMonthsPrev + 12]}</div>
                <div
                    className={s.headCell}>{monthPrev >= 0 ? monthsNames[monthPrev] : monthsNames[monthPrev + 12]}</div>
                <div className={s.headCell}>{monthsNames[thisMonth]}</div>
            </div>

            <div className={s.content}>
                {
                    users.map(user => (
                        <ContentCell key={user.id}
                                     twoMonthsPrev={twoMonthsPrev}
                                     prevMonth={monthPrev}
                                     thisMonth={thisMonth}
                                     idUser={user.id}
                                     prevPrevMonthYear={getYear(twoMonthsPrev, date.getFullYear())}
                                     prevMonthYear={getYear(monthPrev, date.getFullYear())}
                                     thisMonthYear={date.getFullYear()}
                                     codeId={user.code_id}
                                     status={user.status}
                                     prev2MonthSumm={user?.prevprev_month_summ?.total}
                                     prevMonthSumm={user?.prev_month_summ?.total}
                                     thisMonthSumm={user?.this_month_summ?.total}
                                     showModalInfo={showModalInfo}
                                     setIsModalActive={setIsModalActive}
                        />))
                }
            </div>

            <div className={s.footer}>
                <div className={s.footerCell}>Итого</div>
                <div className={s.footerCell}>
                    {
                        users.reduce((acc, user) => acc + +user?.prevprev_month_summ?.total || 0, 0).toFixed(0)
                    } руб
                </div>
                <div className={s.footerCell}>
                    {
                        users.reduce((acc, user) => acc + +user?.prev_month_summ?.total || 0, 0).toFixed(0)
                    } руб
                </div>
                <div className={s.footerCell}>
                    {
                        users.reduce((acc, user) => acc + +user?.this_month_summ?.total || 0, 0).toFixed(0)
                    } руб
                </div>
            </div>

            <Modal modalClass={isModalActive}
                   setModalClass={setIsModalActive}
                   callFn={[]}
                   isEdit={false}
            >
                <ModalUserStatistic idUser={infoUser.idUser}
                                    codeId={infoUser.codeId}
                                    month={infoUser.month}
                                    year={infoUser.year}
                                    setModalClass={setIsModalActive}/>
            </Modal>
        </div>
    )
}

export default Statistics