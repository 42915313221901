import {SET_POINT, SET_POINTS} from './types'
import {pointsAPI} from '../api/api'

const initialState = {
  points: [] as Array<any>,
  isEnterPoint: false
}

export type InitialStateType = typeof initialState

export const workpointsReducer = (state = initialState, action: ActionsTypes):InitialStateType => {
  switch (action.type) {
    case SET_POINTS:
      return {...state, points: action.points}

    case SET_POINT:
      return {...state, isEnterPoint: action.flag}

    default:
      return state
  }
}

type GetPoints = {
  type: typeof SET_POINTS
  points: Array<any>
}
type SetPoint = {
  type: typeof SET_POINT
  flag: boolean
}
type ActionsTypes = GetPoints | SetPoint

export const setPoint = (flag: boolean):SetPoint => ({type: SET_POINT, flag: flag})
const setPoints = (points: any):GetPoints => ({type: SET_POINTS, points: points})

export const getWorkpointsFromServer = () => (dispatch: any) => {
  return pointsAPI.getWorkpoints()
    .then((response: any) => {
      dispatch(setPoint(false))
      dispatch(setPoints(response.data))
    })
}