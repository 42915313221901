import { ThunkAction } from 'redux-thunk'
import { stopSubmit } from 'redux-form'

import { createInstance, getAuth } from '../api/api'
import { CompanyType } from '../types/types'
import { AppStateType, InferActionsTypes } from './rootReducer'

import { appActions, getMenuFromServer } from './appReducer'
import { actionsOrder } from './ordersReducer'

const initialState = {
  id: null as number | null,
  email: null as number | null,
  first_name: '' as string | undefined,
  last_name: '' as string | undefined,
  middle_name: '' as string | undefined,
  phone_number: null as string | null,
  avatar: null as string | null,
  company: undefined as CompanyType | undefined,
  isAuth: false as boolean,
  token: null as string | null,
  refresh: null as string | null,
  recaptcha: false as boolean,
  isRestore: false as boolean,
  restoreMessage: '',
  workpoint: null as number | null,
  role: ''
}

export type InitialStateType = typeof initialState

export const authReducer = (state = initialState, action: InferActionsTypes<typeof authActions>): InitialStateType => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.token,
        refresh: action.refresh
      }
    case 'SET_RECAPTCHA':
      return {
        ...state,
        recaptcha: action.flag
      }
    case 'AUTH_PROFILE':
      return {
        ...state,
        ...action.data,
        isAuth: action.isAuth,
        workpoint: action.workpoint
      }
    case 'SET_RESTORE_PASSWORD':
      return {
        ...state,
        isRestore: action.flag,
        restoreMessage: action.text
      }
    default:
      return state
  }
}

export const authActions = {
  setToken: (token: string, refresh: string) => ({type: 'SET_TOKEN', token, refresh} as const),
  setRecaptcha: (flag: boolean) => ({type: 'SET_RECAPTCHA', flag} as const),
  setRestorePassword: (flag: boolean, text: string) => ({type: 'SET_RESTORE_PASSWORD', flag, text} as const),
  setAuthUserData: (data: any = {}, isAuth: boolean, workpoint: number | null) => ({type: 'AUTH_PROFILE', data, isAuth, workpoint} as const)
}

export const getAuthUserData = (): ThunkAction<Promise<void>, AppStateType, unknown, InferActionsTypes<typeof authActions & typeof appActions>> => {
  return (dispatch) => {
    dispatch(appActions.showLoader(true))
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')

    if (token) {
      createInstance(token)
    } else {
      dispatch(authActions.setAuthUserData({}, false, null))
      dispatch(appActions.showLoader(false))
      return
    }

    return getAuth.me()
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(authActions.setAuthUserData(response.data, true, response.data.workpoint))
          dispatch(authActions.setToken(response.data.token, response.data.refresh))

          dispatch(getMenuFromServer())
        }
      })
      .catch((error: any) => {
        console.log(error)
        if (error.response?.status === 401) {
          dispatch(refreshToken(localStorage.getItem('refresh')))
          return
        }

        if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
        else dispatch(appActions.showPrompt('ALARM', `Ошибка сети!`))
      })
      .finally(() => dispatch(appActions.showLoader(false)))
  }
}

export const login = (username = null, password = null, isRemember: boolean) => (dispatch: Function) => {
  return getAuth.login(username, password)
    .then((response: any) => {
        if (isRemember) {
          localStorage.setItem('token', response.data.access)
          localStorage.setItem('refresh', response.data.refresh)
        }
        sessionStorage.setItem('token', response.data.access)
        sessionStorage.setItem('refresh', response.data.refresh)
        localStorage.removeItem('count')

        dispatch(authActions.setToken(response.data.access, response.data.refresh))
        dispatch(getAuthUserData())
      }
    )
    .catch((error: any) => {
      if (error.response?.status === 401) {
        dispatch(stopSubmit('login', {_error: 'Неправильный логин или пароль'}))

        if (localStorage.getItem('count')) {
          // @ts-ignore
          let numberOfTimes = +localStorage.getItem('count')
          localStorage.setItem('count', String(++numberOfTimes))
        } else {
          localStorage.setItem('count', '1')
        }
      } else {
        if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
        else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
      }
    })
}

export const refreshToken = (refresh: any) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return getAuth.refresh(refresh)
    .then((response: any) => {
      localStorage.setItem('token', response.data.access)
      localStorage.setItem('refresh', response.data.refresh)

      sessionStorage.setItem('token', response.data.access)
      sessionStorage.setItem('refresh', response.data.refresh)

      dispatch(authActions.setToken(response.data.access, response.data.refresh))
      dispatch(getAuthUserData())

      // @ts-ignore
      createInstance(response.data.access)
    })
    .catch((error: any) => {
      dispatch(logout())

      if (error.response) dispatch(appActions.showPrompt('ALARM', `Авторизация устарела! Войдите снова!`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}

export const logout = () => (dispatch: Function) => {
  localStorage.removeItem('token')
  localStorage.removeItem('refresh')
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('refresh')

  dispatch(authActions.setAuthUserData({}, false, null))
  dispatch(actionsOrder.resetStateOrders())
  dispatch(appActions.getMenu([]))
  dispatch(authActions.setRecaptcha(false))
}

export const changeData = (content: any) => (dispatch: Function) => {
  return getAuth.changeData(content)
    .then((response: any) => {
      if (response.status === 201) {
        dispatch(appActions.showModal(false))
        dispatch(appActions.showPrompt('SUCCESS', 'Запрос успешно отправлен'))
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}

export const setPointToServer = (id: number, data: any) => (dispatch: Function) => {
  return getAuth.changePoint(id, data)
    .then((response: any) => {
      dispatch(authActions.setAuthUserData(response.data, true, response.data.workpoint))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}

export const restorePassword = (data: any) => (dispatch: any) => {
  return getAuth.passwordReset(data)
    .then((response: any) => {
      dispatch(authActions.setRestorePassword(true, 'Ссылка для восстановления пароля отправлена на почту'))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}

export const passwordChange = (data: any) => (dispatch: any) => {
  return getAuth.changePassword(data)
    .then((response: any) => {
      dispatch(authActions.setRestorePassword(true, 'Пароль успешно изменен'))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}