import React, { FC, useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { Confirmation } from '../../../common/confirmation/Confirmation'

const WEEKDAYS_SHORT = {ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']}
const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ]
}

interface IProps {
  id: number
  setDate: (id: number, day: Date) => void
}

const RenderDayPicker: FC<IProps> = ({id, setDate}) => {
  const [selectedDay, setSelectedDay] = useState<undefined | Date>(undefined)
  const [localDay, setLocalDay] = useState<Date>()
  const [locale, setLocale] = useState('')
  const [openConfirm, setOpenConfirm] = useState(false)

  const closeConfirmModal = () => {
    setOpenConfirm(false)
    setLocale('')
    setSelectedDay(undefined)
  }
  const handleDayChange = (day: Date) => {
    setOpenConfirm(true)
    // setSelectedDay(undefined)
    setLocalDay(day)
    setLocale(day.toLocaleDateString())
  }
  const setDayToServer = () => {
    if (localDay) {
      setSelectedDay(localDay)
      setDate(id, localDay)
    }
    setOpenConfirm(false)
  }

  return (
    <>
      {openConfirm &&
        <Confirmation
          text="Подтвердите дату отгрузки"
          submitData={setDayToServer}
          subTitle="Отменить действие будет невозможно"
          handleCancel={closeConfirmModal}
        />
      }
      <DayPickerInput
        dayPickerProps={{
          months: MONTHS['ru'],
          weekdaysShort: WEEKDAYS_SHORT['ru'],
          selectedDays: selectedDay
        }}
        inputProps={{readOnly: true}}
        onDayChange={handleDayChange}
        placeholder={'__.__.____'}
        value={locale}
      />
    </>
  )
}

export default RenderDayPicker