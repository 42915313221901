import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appActions } from '../../../../redux/appReducer'

import { actionsOrder, getOrderFromServer, getOrdersFromServerDashboard } from '../../../../redux/ordersReducer'
import PerformersTasks from './PerformersTasks/PerformersTasks'
import { AppStateType } from '../../../../redux/rootReducer'
import { OrderType } from '../../../../types/types'

import OrderView from './OrderView/OrderView'
import s from './production.module.scss'

const Production = () => {
  const orders = useSelector((state: AppStateType) => state?.orders?.ordersDashboard?.results)
  const nextPage = useSelector((state: AppStateType) => state?.orders.ordersDashboard.next)
  const pageStore = useSelector((state: AppStateType) => state?.orders.pageDashboard)
  const [ordersSortStatus, setOrdersSortStatus] = useState<Array<OrderType>>([])
  const [fetching, setFetching] = useState(true)
  const dispatch = useDispatch()

  // фильтр и сортировка заказов
  useEffect(() => {
    if (orders.length) {
      setOrdersSortStatus(orders
        .filter(order => order.status > 2 && order.status < 6)
        .sort((a, b) => (!a.is_asap && b.is_asap) ? 1 : (b.is_asap && a.is_asap) ? 0 : -1)
        .sort((a, b) => {
          const dateFirst = a.production_date_from?.split('.').reverse().join(', ')
          const dateSecond = b.production_date_from?.split('.').reverse().join(', ')

          const newDateA = new Date(dateFirst)
          const newDateB = new Date(dateSecond)
          return ((newDateA < newDateB) && (a.is_asap === b.is_asap)) ? -1 : ((newDateA < newDateB) && (a.is_asap === b.is_asap)) ? 1 : 0
        })
      )
    }
  }, [orders])

  useEffect(() => {
    if (ordersSortStatus.length < 50) {
      setFetching(true)
    }
  }, [ordersSortStatus])


  useEffect(() => {
    if (!fetching) return

    if (fetching && nextPage !== null && nextPage.indexOf(`&page=${pageStore + 1}`)) {
      dispatch(getOrdersFromServerDashboard({from: '2', to: '6'},
        `have_tasks=true&dashboard=admin&page=${pageStore}&order=-is_asap,production_date_from`,
        () => {
          setFetching(false)
          dispatch(actionsOrder.setPage(pageStore + 1))
          dispatch(appActions.showLoader(false))
        })
      )
    }
    // eslint-disable-next-line
  }, [fetching])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
    // eslint-disable-next-line
  }, [])

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }

  const showOrder = (order: any) => {
    dispatch(getOrderFromServer(order.id))
  }

  return (
    <div className={s.production}>
      <PerformersTasks ordersSortStatus={ordersSortStatus}
                       showOrder={showOrder}/>
      <OrderView/>
    </div>
  )
}

export default Production