import React from 'react';

import s from './confirmation.module.scss'

export const Confirmation = props => {
	const {
		text, submitData, subTitle = '',
		handleCancel, textConfirm = 'Сохранить', textCancel = 'Отмена',
		style
	} = props

	return (
		<div className={`${s.modalOrders} ${s.modalShow}`} style={style}>
			<div className={`${s.modalContent} `}>
				<p>{text}</p>

				<p className={s.modalContent_subTitle}>{subTitle}</p>
				
				<div className={s.modalControl}>
					<button type='button' onClick={handleCancel}>{ textCancel }</button>
					<button type='button' onClick={submitData}>{ textConfirm }</button>
				</div>
			</div>
		</div>
	)
}