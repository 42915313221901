import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux'

import { materialsReducer } from './materialsReducer'
import { productsReducer } from './productsReducer'
import { paymentsReducer } from './paymentsReducer'
import { workpointsReducer } from './workpointsReducer'
import { ordersReducer } from './ordersReducer'
import { notiesReducer } from './notiesReducer'
import { systemReducer } from './systemReducer'
import { usersReducer } from './usersReducer'
import { tasksReducer } from './tasksReducer'
import { goodsReducer } from './goodsReducer'
import { newsReducer } from './newsReducer'
import { authReducer } from './authReducer'
import { appReducer } from './appReducer'

export const rootReducer = combineReducers({
	goods: goodsReducer,
	products: productsReducer,
	tasks: tasksReducer,
	news: newsReducer,
	materials: materialsReducer,
	orders: ordersReducer,
	noties: notiesReducer,
	auth: authReducer,
	users: usersReducer,
	app: appReducer,
	form: formReducer,
	points: workpointsReducer,
	system: systemReducer,
	payments: paymentsReducer,
})

export type AppStateType = ReturnType<typeof rootReducer>

export type InferActionsTypes<T> = T extends { [keys: string]: (...args: any[]) => infer U } ? U : never