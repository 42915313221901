import React, {FC} from 'react'
import s from "./buttonPoint.module.scss"
import arrowBottom from '../../../../../../assets/icons/arrow-bottom-black.svg'

interface IButtonPoint {
    showList: () => void
    text: string
    isList: boolean
    activeChoice: string
}

const ButtonPoint: FC<IButtonPoint> = ({showList, isList, activeChoice, text}) => {
    return (
        <button className={s.button} onClick={showList} style={{background: isList ? '#DCEDE9' : ''}}>
                {activeChoice || text}
            <img src={arrowBottom} className={s.arrow} alt=""/>
        </button>
    )
}

export default ButtonPoint