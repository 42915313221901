import React, {useRef} from 'react'
import {useSelector} from 'react-redux'

import {toggleClass} from '../../../function/toggleClass'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

import s from './modal.module.scss'
import {AppStateType} from "../../../../redux/rootReducer";

interface IModal {
  setModalClass: (flag: boolean) => void
  callFn: Array<() => void>
  modalClass: boolean
  isEdit: boolean
  styleProps?: any
  openConfirm?: boolean
}

export const Modal: React.FC<IModal> = ({ setModalClass,
                                          modalClass,
                                          callFn,
                                          styleProps,
                                          isEdit = false,
                                          openConfirm = false,
                                          children
                                        }) => {
  const openModal = useSelector((state: AppStateType) => state.app.openModal)
  const ref = useRef(null)

  // при выключенном режиме редактирования isEdit, при клике вне модального окна,
  // модалка закрывается и вызываются все функции в переданном массиве callFn
  const handleClickOutside = () => {
    if (!isEdit && !openConfirm) {
      setModalClass(false)
      if (modalClass && callFn?.length > 0) {
        callFn.forEach((item) => item())
      }
    }
  }
  useOnClickOutside(ref, handleClickOutside)

  const modalClassNames = toggleClass(s.modal, s.modal_show, modalClass)
  if (!openModal) return null

  return (
    <div className={modalClassNames.join(' ')}>
      <div className={`${s.modal_content} ${modalClassNames}`} style={styleProps} ref={ref}>
        {children}
      </div>
    </div>
  );
};