import { useDispatch } from 'react-redux'
import React, { useEffect } from 'react'
import { getProductsFromServer } from '../../../../redux/productsReducer'
import { FieldArray } from 'redux-form'
import { ProductInformation } from './ProductInformation/ProductInformation'
import { DropDownList } from '../DropDownList'
import s from './good.module.scss'
import { ProductMaterialInfo } from './ProductMaterialInfo/ProductMaterialInfo'
import LegsMolding from './LegsMolding/LegsMolding'
import Commentary from './Сommentary/Сommentary'
import Scheme from './Scheme/Scheme'

const RenderOrder = ({fields, indexGood, onEnterGood}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (fields.length <= 0) fields.push({})
    dispatch(getProductsFromServer(''))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {
        fields.map((item, index) => (
          <span key={index}>
            <FieldArray
              name={`${item}.items`}
              onEnterGood={onEnterGood}
              component={ProductInformation}
              indexGood={indexGood}
            />
            <DropDownList
              title="Комплектация"
              className={s.createOrderDropDown}
            >
              <ProductMaterialInfo item={item}/>
              <LegsMolding item={item}/>
              <Commentary item={item}/>
            </DropDownList>

            <DropDownList title="Схема товара">
              <Scheme item={item}/>
            </DropDownList>
            </span>
        ))
      }
    </>
  )
}

export default RenderOrder