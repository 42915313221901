import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOrderDatesFromServer,
  startOrdersDatesUpdateListening
} from '../../../redux/ordersReducer'

import { AppStateType } from '../../../redux/rootReducer'
import { OrderType } from '../../../types/types'

import s from './orderDates.module.scss'

const OrderDates = () => {
  const orders = useSelector((state: AppStateType) => state?.orders?.orderDates?.results)
  const [ordersSort, setOrdersSort] = useState<Array<OrderType>>([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOrderDatesFromServer(
    `status__lt=6&production_date_from__gte=${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}&limit=1000&order=-production_date_to,-status`
    ))
    dispatch(startOrdersDatesUpdateListening())

    // запросы заказов каждые 12 часов
    const timerId = setInterval(() => {
      dispatch(getOrderDatesFromServer(
        `status__lt=6&production_date_from__gte=${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}&limit=1000&order=-production_date_to,-status`
      ))
    } , 43200000)

    return () => clearInterval(timerId)
  }, [dispatch])

  // сортировка заказов по дате производства
  useEffect(() => {
    if (orders) {
      setOrdersSort(orders
        .sort((a, b) => {
          const dateFirst = a.production_date_from?.split('.').reverse().join(', ')
          const dateSecond = b.production_date_from?.split('.').reverse().join(', ')

          return (+(new Date(dateFirst)) - +(new Date(dateSecond)))
        })
      )
    }
  }, [dispatch, orders])

  return (
    <section className={s.orderDates}>
      <h1 className={s.orderDatesTitle}>Даты изготовления заказов</h1>

      <div className={s.table}>
        <div className={s.tableHeader}>
          <div className={s.headerItem}>№ договора</div>
          <div className={s.headerItem}>Дата изготовления</div>
        </div>
        <div className={s.contentTable}>

          {
            ordersSort?.map(item => (
              <div key={item.id} className={s.contentRow}>
                <div className={s.contentItem}>{ item.code }</div>
                <div className={s.contentItem}>{ item.production_date_from }</div>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default OrderDates