import React, { useEffect, useRef } from 'react'
import { change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

import s from './buttonFile.module.scss'

import iconPdf from '../../../../../assets/icons/pdfIcon.png'
import deleteBtn from '../../../../../assets/icons/delete.png'

const ButtonFile = (props) => {
  const {
    input: { onChange }, formName,
    inputName, text = 'Выберите файл',
    setDocument, passport = '', isChangeUserData = true
  } = props
  const fileName = useSelector((state) => state.form[formName]?.values?.passport?.name)
  const dispatch = useDispatch()
  let fileInput = useRef()
  const existFileName = passport?.split('/')

  useEffect(() => {
    if (isChangeUserData && fileInput && formName) {
      deleteFile()
    }
    // eslint-disable-next-line
  }, [isChangeUserData])

  const onChangeInput = (e) => {
    if (e?.target?.files?.length) {
      onChange(e.target.files[0])
      if (setDocument) {
        setDocument({ file: e.target.files[0] })
      }
    }
  }

  const deleteFile = () => {
    dispatch(change(formName, inputName, ''))
    if (setDocument) {
      setDocument({ file: null })
    }
    fileInput.value = ''
  }

  return (
    <div className={s.file}>
      {
        isChangeUserData &&
        <div className={s.btnFile}>
          <input type="file"
                 accept=".pdf"
                 name={inputName}
                 className={s.btnFileInput}
                 onChange={onChangeInput}
                 ref={ref => fileInput = ref}/>
          {text}
        </div>
      }

      <span className={s.fileName}>
          <span className={s.iconPdf}>
            {
              ((fileName || existFileName)
                && (fileName?.slice(-4) === '.pdf' || existFileName[existFileName.length - 1]?.slice(-4) === '.pdf'))
              && <img src={iconPdf} alt=""/>
            }
          </span>

        {
          fileName && isChangeUserData ? fileName
            : existFileName ?
              <div className={s.fileName}>
                <a href={passport} target={'_blank'} rel="noreferrer">{existFileName[existFileName.length - 1]}</a>
              </div>
              : 'Файл не загружен'
        }

        {
          fileName && isChangeUserData &&
          <img src={deleteBtn} alt="Удалить" className={s.deleteBtn} onClick={deleteFile}/>
        }
        </span>
    </div>
  )
}

export default ButtonFile