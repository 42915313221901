import React from 'react'
import { useDispatch } from 'react-redux'

import { orderMaterials } from '../../../../../redux/materialsReducer'

import { OrderCard } from '../../OrderCard'
import OrderForm from './OrderForm'

import s from '../../orderingMaterials.module.scss'
import {appActions} from '../../../../../redux/appReducer'

export const ModalContent = ({ content }) => {
	const dispatch = useDispatch()

	// отправка данных на сервер при нажатии на кнопку ГОТОВО
	const onSubmit = (formData) => {
		let orderData = JSON.parse(JSON.stringify(formData))
		orderData.status = 2
		orderData.quantity = Number(orderData.quantity)
		dispatch(orderMaterials(content.id, orderData))
	}

	// закрытие модального окна создания заказа при клике на крестик
	const closeForm = (e) => {
		e.preventDefault()
		dispatch(appActions.showModal(false))
	}

	return (
		<div className={s.ModalContent}>
			<button onClick={closeForm} className={s.closeForm}>x</button>

			<p className={s.ModalContent_title}>Форма заказа</p>
			<OrderCard content={content}>
				<OrderForm onSubmit={onSubmit} quantity={content?.quantity}/>
			</OrderCard>
		</div>
	)
}