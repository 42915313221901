import React, {useEffect} from 'react'
import {change, Field, reduxForm} from 'redux-form'

import { DatePickerInput } from '../../../orders/FormOrderBlock/DatePickerInput'
import {FormInput, FormTextArea} from '../../../orders/FormOrderBlock/FormOrderControl'

import {isPositive, maxLength} from '../../../../../utils/validators/validators'
import { toggleClass } from '../../../../function/toggleClass'

import s from '../../orderingMaterials.module.scss'
import {useDispatch} from 'react-redux'

const maxLength9 = maxLength(9)
const maxLength200 = maxLength(200)

const OrderForm = ({ handleSubmit, quantity } ) => {
	const accessClass = toggleClass(s.card_item_datePicker, s.card_item_access, true)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(change('FormOrderingMaterials', 'quantity', quantity))
	}, [dispatch, quantity])

	return (
		<form onSubmit={handleSubmit}>
			<div className={s.card_item}>
				<p className={s.card_item_title}>Количество</p>
				<div className={s.card_item_amountBlock}>
					<Field
						name="quantity"
						component={FormInput}
						className={s.materialInput}
						type="number"
						validate={[maxLength9, isPositive]}
					/>
				</div>
			</div>

			<div className={s.card_item}>
				<p className={s.card_item_title}>Ожидаемая дата <br/> доставки</p>
				<div className={accessClass.join(' ')}>
					<DatePickerInput
						name="delivery_date"
						formName="FormOrderingMaterials"
					/>
					<img src='../../image/ordering-materials/bell.png' alt="bells" className={s.ModalContent_bells} />
				</div>
			</div>

			<div className={s.card_item}>
				<Field
					name="note"
					component={FormTextArea}
					placeholder="Комментарий"
					validate={[maxLength200]}
				/>
			</div>
			
			<button className={s.modal_submit}>Готово</button>
		</form>
	)
}

export default reduxForm({form: 'FormOrderingMaterials'})(OrderForm)