import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'

import { CustomPrompt } from '../../common/customPrompt/CustomPrompt'
import { SvgImages } from '../../../generic'

import { classNames } from '../../../function/toggleClasses'
import { getClientsBase, getProductsDocument, sendProductsDocument } from '../../../../redux/systemReducer'

import s from './changeAssortment.module.scss'

const ChangeAssortment = () => {
  const dispatch = useDispatch()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      dispatch(sendProductsDocument(e.target.files[0]))
    }
  }
  const onClickProductsLocked = () => dispatch(getProductsDocument('locked'))
  const onClickProducts = () => dispatch(getProductsDocument(''))
  const onClickClientBase = () => dispatch(getClientsBase())

  return (
    <div>
      <CustomPrompt/>

      <div>
        <div className={s.buttons}>
          <div className={classNames([s.buttonsDownload])}>

            <button
              className={classNames([s.buttonDownload, s.buttonDownloadOutlay])}
              onClick={onClickProductsLocked}>
            <span className={s.imageWrap}>
            <SvgImages type={'download'}/>
            </span>
              Выгрузить данные для изменения цен ассортимента
            </button>

            <button className={classNames([s.buttonDownload, s.buttonDownloadMaterial])}
                    onClick={onClickProducts}>
              <span className={s.imageWrap}>
                <SvgImages type={'download'}/>
              </span>
              Выгрузить данные для операций по изменению ассортимета

              <span className={classNames([s.imageWrap, s.imageWrapWarning])}>
               <SvgImages type={'warning'}/>
              </span>
            </button>

            <button className={classNames([s.buttonDownload, s.buttonDownloadMaterial])}
                    onClick={onClickClientBase}>
              <span className={s.imageWrap}>
                <SvgImages type={'download'}/>
              </span>
              Выгрузить клиентскую базу
            </button>
          </div>


          <label className={classNames([s.buttonUpload, s.buttonUploadEdited])}>
            <input type="file"
                   accept=".xls"
                   className={s.input}
                   onChange={onChange}/>
            <span className={s.imageWrap}>
              <SvgImages type={'upload'}/>
            </span>
            Загрузить отредактированный документ
          </label>
        </div>
      </div>
    </div>
  )
}

export default ChangeAssortment