import { useEffect, useState } from 'react';

export const useReverseArray = (array) => {
	const [reverseOrders, setReverseOrders] = useState([]);

	useEffect(() => {
		if (!array) return
		setReverseOrders(array.reverse())
	},[array]);

	return reverseOrders;
};