import React, { FC, useRef, useState } from 'react'
import s from '../ordersList.module.scss'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'

interface IProps {
  id: number
  document_link: string
  status: number
  sendToWork: (id: number) => void
}

const ButtonsModal: FC<IProps> = (props) => {
  const {
    id,
    document_link,
    status,
    sendToWork
  } = props
  const [isShowButtons, setIsShowButtons] = useState(false)
  const ref = useRef(null)

  useOnClickOutside(ref, () => setIsShowButtons(false))

  const confirmPrepayment = () => {
    setIsShowButtons(false)
    sendToWork(id)
  }

  return (
    <div className={s.buttonsBlock}
         ref={ref}
    >
      <button className={s.buttonsShow}
              onClick={() => setIsShowButtons(true)}
      >
        <span></span>
      </button>

      {
        isShowButtons &&
        <div className={s.buttonsModal}>
          <div className={s.showOrder}>
            <a href={document_link}
               type={'application/pdf; download=\'some_pdf_name\''}
               target="_blank" rel="noreferrer"
               className={s.orderLink}
            >
              Открыть договор
            </a>
          </div>
          <div className={s.sendOrder}>
            {
              (status === 1) &&
              <button className={s.orderLink}
                      onClick={confirmPrepayment}
              >
                Подтвердить предоплату
              </button>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default ButtonsModal