import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import { NavLink, Redirect } from 'react-router-dom'

import { Orders } from './orders/Orders'
import { OrderArchive } from './order-archive/OrderArchive'

import { tabsMenuOrderingMaterials } from '../../../configs/menuСonfiguration'

import OrderMaterial from './OrderMaterial/OrderMaterial'
import EditData from './EditData/EditData'

import s from './orderingMaterials.module.scss'

export const OrderingMaterials = () => {
  const {url} = useRouteMatch()

  return (
    <section className={s.orderingMaterials}>
      <div className="container">
        <h1 className={s.orderingMaterials_mainTitle}>Заказ ткани</h1>

        <div className={s.orderingMaterials_content}>
          <div className={s.menu}>
            <div className={s.tabs}>
              {tabsMenuOrderingMaterials.map(tab => (
                <NavLink
                  key={tab.id}
                  to={`${url}${tab.to}`}
                  className={s.tabsItem}
                  activeClassName={s.tabsItemActive}
                >
                  {tab.title}
                </NavLink>
              ))}
            </div>
          </div>

          <div className={s.orderingMaterials_item}>
            <Redirect to={`${url}/orders`}/>

            <Switch>
              <Route path={`${url}/orders`}>
                <Orders/>
              </Route>
              <Route path={`${url}/order-material`}>
                <OrderMaterial/>
              </Route>
              <Route path={`${url}/order-archive`}>
                <OrderArchive/>
              </Route>
              <Route path={`${url}/data-edit`}>
                <EditData/>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </section>
  )
}