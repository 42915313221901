import { addressAPI } from "./APISettings"

let ws: WebSocket | null

let noticesSubscriber = [] as SubscribeType[]
let ordersSubscriber = [] as SubscribeType[]
let tasksSubscriber = [] as SubscribeType[]

function closeHandler(userId: number) {
  setTimeout(() => createChanel(userId), 3000)
}

const ordersHandler = (action: string, id: number) => {
  switch (action) {
    case 'notice':
      return noticesSubscriber.map(s => s())

    case 'order':
      return ordersSubscriber.map(s => s(id))

    case 'tasks':
      return tasksSubscriber.map(s => s(id))

    case 'task':
      return ordersSubscriber.map(s => s())

    default:
      return null
  }
}

function createChanel(userId: number) {
  ws = new WebSocket(`wss://${addressAPI}/ws/?${userId}`)

  ws.onmessage = function (e) {
    const data = JSON.parse(e.data)


    if (data.action === "update_instance" && data.data.instance_id > 0) {
      ordersHandler(data.data.instance, data.data.instance_id)
    }
  }
  ws.onclose = function (event) {
    if (!event.wasClean) {
      closeHandler(userId)
    }
  }
}

export const wsAPI = {
  start(userId: number) {
    createChanel(userId)
  },
  stop() {
    ws?.close()
  },
  subscribeOrders(callback: any) {
    ordersSubscriber.push(callback)
  },
  subscribeOrdersTasks(callback: any) {
    tasksSubscriber.push(callback)
  },
  subscribeNoties(callback: any) {
    noticesSubscriber.push(callback)
  }
}

type SubscribeType = (id?: number) => void
