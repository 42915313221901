import React from 'react';
import s from './Account.module.scss';

export const Account = ({email, username}) => {

	return (
		<div className={s.personalData}>

			<div className={s.personalContent}>
				<div className={s.accountData}>
					<div className={s.dataItem}>
						<span className={s.accountTitle}>Логин</span>
						<span className={s.accountText}>{username}</span>
					</div>

					<div className={s.dataItem}>
						<span className={s.accountTitle}>Почта</span>
						<span className={s.accountText}>{email}</span>
					</div>
				</div>
			</div>

		</div>
	);
};