import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Field, change, reduxForm, InjectedFormProps} from 'redux-form'

import { createField, Input } from '../../../common/FormsControl'
import ButtonFile from '../../../common/Buttons/ButtonsFile/ButtonFile'
import { requiredBorder } from '../../../../../utils/validators/validators'
import { getRoles } from '../../../../../redux/usersReducer'

import {AppStateType} from '../../../../../redux/rootReducer'
import {IUser} from '../../../../../types/types'

import {appendFile} from 'fs'

import s from './formUserData.module.scss'

export interface IUserFormData {
  address: string
  first_name: string
  last_name: string
  middle_name: string
  phone_number: string
  role: string
}

export interface IUserData {
  userData: IUser
  onSubmit: (formData: IUserFormData) => void
  changeData: () => void
  isChangeUserData: boolean
  setDocument: any
}

const UserData: React.FC<InjectedFormProps<IModalFormRedux, IUserData> & IUserData> = ({ handleSubmit, userData, changeData, isChangeUserData, setDocument }) => {
  const roles = useSelector((state: AppStateType) => state.users?.roles)
  const [isShowRoles, setIsShowRoles] = useState(false)
  const rolesRef = useRef(null)
  const dispatch = useDispatch()
  const {
    first_name = '', last_name = '', middle_name = '',
    role = '', phone_number = '', address = '', passport = ''
  } = userData

  useEffect(() => {
    dispatch(getRoles('type=worker'))
  }, [dispatch])

  useEffect(() => {
    if (isChangeUserData) {
      dispatch(change('userData', `first_name`, first_name))
      dispatch(change('userData', `middle_name`, middle_name))
      dispatch(change('userData', `last_name`, last_name))
      dispatch(change('userData', `role`, role))
      dispatch(change('userData', `phone_number`, phone_number))
      dispatch(change('userData', `address`, address))
    }
    // eslint-disable-next-line
  }, [isChangeUserData])

  const setRole = (id: number, name: string) => {
    dispatch(change('userData', 'role', name))
    setIsShowRoles(false)
  }

  return (
    <form className={s.userData} autoComplete={'off'}>
      <div className={s.data}>
        <div className={s.buttons}>
          {
            !isChangeUserData
              ? <div className={s.btnChange} onClick={changeData}>Изменить</div>
              : <div className={s.btnChange} onClick={handleSubmit}>Сохранить</div>
          }
        </div>

        <div className={s.user}>
          <div className={s.userItem}>
            <span className={s.userTitle}>Фамилия</span>
            {
              isChangeUserData
                ? createField('', 'middle_name',
                  [requiredBorder], Input,
                  {
                    className: s.userValue
                  })
                : <span className={s.userValue}>{middle_name}</span>
            }

          </div>
          <div className={s.userItem}>
            <span className={s.userTitle}>Имя</span>
            {
              isChangeUserData
                ? createField('', 'first_name',
                  [requiredBorder], Input,
                  {
                    className: s.userValue
                  })
                : <span className={s.userValue}>{first_name}</span>
            }
          </div>
          <div className={s.userItem}>
            <span className={s.userTitle}>Отчество</span>
            {
              isChangeUserData
                ? createField('', 'last_name',
                  [], Input,
                  {
                    className: s.userValue
                  })
                : <span className={s.userValue}>{last_name}</span>
            }
          </div>
          <div className={s.userItem}>
            <span className={s.userTitle}>Должность</span>
            <span className={s.roles} ref={rolesRef}>
              {
                isChangeUserData
                  ? createField('', 'role',
                    [requiredBorder], Input,
                    {
                      className: s.userValue,
                      onFocus: () => setIsShowRoles(true),
                      onBlur: () => setIsShowRoles(false),
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()
                    })
                  : <span className={s.userValue}>{role}</span>
              }

              {
                isShowRoles &&
                <div className={s.rolesList}>
                  {
                    roles.map(({id, name}: {id: number, name: string}) => (
                      <span key={id}
                            className={s.role}
                            onClick={() => setRole(id, name)}>
                          {name}
                        </span>
                    ))
                  }
                </div>
              }
            </span>

          </div>
          <div className={s.userItem}>
            <span className={s.userTitle}>Адрес</span>
            {
              isChangeUserData
                ? createField('', 'address',
                  [], Input,
                  {
                    className: s.userValue
                  })
                : <span className={s.userValue}> {address}</span>
            }
          </div>
          <div className={s.userItem}>
            <span className={s.userTitle}>Телефон</span>
            {
              isChangeUserData
                ? createField('', 'phone_number',
                  [requiredBorder], Input,
                  {
                    className: s.userValue
                  })
                : <span className={s.userValue}> {phone_number}</span>
            }
          </div>
        </div>
      </div>

      <div className={s.userDocument}>
        <div>
          <h2 className={s.documentTitle}>Копия паспорта</h2>

          <Field name={'passport'}
                 component={ButtonFile}
                 formName={'userData'}
                 inputName={'passport'}
                 setDocument={setDocument}
                 passport={passport}
                 isChangeUserData={isChangeUserData}/>
        </div>
      </div>
    </form>
  )
}

interface IModalFormRedux {
  passport: typeof appendFile
}

const ModalFormRedux = reduxForm<IModalFormRedux, IUserData>({ form: 'userData' })(UserData)

export default ModalFormRedux