import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { change, Field, reduxForm } from 'redux-form'

import { DatePickerInput } from '../../../orders/FormOrderBlock/DatePickerInput'
import { FormTextArea } from '../../../orders/FormOrderBlock/FormOrderControl'

import { maxLength } from '../../../../../utils/validators/validators'
import { toggleClass } from '../../../../function/toggleClass'

import s from '../../orderingMaterials.module.scss'

const maxLength200 = maxLength(200)

const AwaitingReceiptForm = ({ access, value }) => {
  const dispatch = useDispatch()

  // Инициализация store при нажатии кнопки ИЗМЕНИТЬ
  useEffect(() => {
    if (access) {
      dispatch(change('FormChangeOrder', 'quantity', value.quantity))
      dispatch(change('FormChangeOrder', 'measurement', value.measurement))
      if (value.delivery_date_from) {
        dispatch(change('FormChangeOrder', 'delivery_date_from', value.delivery_date_from))
      }
      if (value.delivery_date_to) {
        dispatch(change('FormChangeOrder', 'delivery_date_to', value.delivery_date_to))
      }
      dispatch(change('FormChangeOrder', 'note', value.note))
    }
    // eslint-disable-next-line
  }, [access])

  // Добавление/удаление класса по условию
  const accessClass = toggleClass(s.card_item_datePicker, s.card_item_access, access)

  return (
    <form>
      <div className={s.card_item}>
        <p className={s.card_item_title}>Количество</p>
        <div className={s.card_item_amountBlock}>
          <div className={s.card_item_value}>{value.quantity}</div>
        </div>
      </div>

      <div className={s.card_item}>
        <p className={s.card_item_title}>Ожидаемая дата <br/> доставки</p>
        {!access
          ?
          <div className={`${s.card_item_value} ${s.card_item_divDate}`}>
            {`${value.delivery_date_from ?? ''} - ${value.delivery_date_to ?? ''}`}
          </div>
          :
          <div className={accessClass.join(' ')}>
            <DatePickerInput
              name="delivery_date"
              formName="FormChangeOrder"
              valueInput={value.delivery_date_from ? {
                from: value.delivery_date_from,
                to: value.delivery_date_to
              } : ''}
            />
          </div>
        }
      </div>

      <div className={s.card_item}>
        {!access
          ? <div className={s.card_item_notes}>{value.note}</div>
          : <Field
            name="note"
            component={FormTextArea}
            placeholder="Комментарий"
            validate={[maxLength200]}
          />
        }
      </div>
    </form>
  )
}

export default reduxForm({ form: 'FormChangeOrder' })(AwaitingReceiptForm)