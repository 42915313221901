import React from 'react';
import { DatePickerInput } from './DatePickerInput';
import { useSelector } from 'react-redux';

import 'react-day-picker/lib/style.css';

export const DateOfImplementation = () => {
	const production_date = useSelector(state => state.form?.fieldArrays?.values?.order?.production_date_to);
	// console.log(production_date)

	const date = new Date()
	const today = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate()
	const month = (date.getMonth() < 10) ? `0${date.getMonth() + 1}` : date.getMonth() + 1
	const year = date.getFullYear()
	const disabledDateProduction = `${today}.${month}.${year}`

	return (
		<div className="date-of-implementation">
			<div className="date-of-implementation__manufacture">
				<span>Дата изготовления:</span>
				<DatePickerInput
					name="production_date"
					formName="fieldArrays"
					pathName="order"
					disabledDate={disabledDateProduction}
					require={true}
					agreement={true}
				/>
			</div>
			<div className="date-of-implementation__delivery">
				<span>Дата доставки:</span>
				<DatePickerInput
					name="delivery_date"
					formName="fieldArrays"
					pathName="order"
					disabledDate={production_date}
					require={true}
					agreement={true}
				/>
			</div>
		</div>
	);
};