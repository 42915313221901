import {
  SET_CONSUMABLE_FROM_SERVER,
  SET_LEGS_FROM_SERVER,
  SET_MOLDING_FROM_SERVER,
  SET_PRODUCT_FROM_SERVER
} from './types'
import { materialAPI, productAPI } from '../api/api'
import { appActions } from './appReducer'

const initialState = {
  products: [],
  consumable: [],
  legs: [],
  molding: []
}

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_FROM_SERVER:
      return {
        ...state,
        products: action.products
      }

    case SET_CONSUMABLE_FROM_SERVER:
      return {
        ...state,
        consumable: action.consumable
      }

    case SET_LEGS_FROM_SERVER:
      return {
        ...state,
        legs: action.legs
      }

    case SET_MOLDING_FROM_SERVER:
      return {
        ...state,
        molding: action.molding
      }

    default:
      return state
  }
}

const setProducts = (products) => ({type: SET_PRODUCT_FROM_SERVER, products: products})
const setConsumable = (consumable) => ({type: SET_CONSUMABLE_FROM_SERVER, consumable: consumable})
const setLegs = (legs) => ({type: SET_LEGS_FROM_SERVER, legs: legs})
const setMolding = (molding) => ({type: SET_MOLDING_FROM_SERVER, molding: molding})

export const getProductsFromServer = (type) => (dispatch) => {
  return productAPI.getProducts(type)
    .then((response) => {
      dispatch(setProducts(response.data))

      if (!type) {
        sessionStorage.setItem('products', response.data.map(item => item.name))
      }
    })
    .catch((error) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getConsumableFromServer = () => (dispatch) => {
  dispatch(appActions.showLoader(true))

  return productAPI.getConsumable()
    .then((response) => {
      dispatch(setConsumable(response.data))
    })
    .catch((error) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const changeQuantityConsumable = (id, data) => (dispatch) => {
  dispatch(appActions.showLoader(true))
  return productAPI.setConsumable(id, data)
    .then((response) => {
      console.log(response)
      if (response.data.success) {
        dispatch(appActions.showPrompt('WARN', 'Отправлено'))
        dispatch(getConsumableFromServer(false))
      }
    })
    .catch((error) => {
      console.log(error)
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getPriceProduct = (data, cb) => (dispatch) => {
  return productAPI.getProductPrice(data)
    .then((response) => {
      cb(response.data.price)
    })
    .catch((error) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getLegsFromServer = () => (dispatch) => {
  return materialAPI.getLegs()
    .then((response) => {
      dispatch(setLegs(response.data))
    })
}
export const getMoldingFromServer = () => (dispatch) => {
  return materialAPI.getMolding()
    .then((response) => {
      dispatch(setMolding(response.data))
    })
}
