import React, {useRef, useState} from "react"
import {change, Field} from "redux-form"
import {useDispatch} from "react-redux"

import {FormInput} from "../../../orders/FormOrderBlock/FormOrderControl"
import {createField, Input} from "../../../common/FormsControl"
import useOnClickOutside from "../../../../hooks/useOnClickOutside"

import s from "../modalInfo.module.scss"

const MaterialLegsMoldings = ({isEdit, item, index, legs, moldings}) => {
  // окно с ножками
  const [searchLegs, setSearchLegs] = useState(false)
  const refLegs = useRef(null)
  // окно с молдингами
  const [searchMolding, setSearchMolding] = useState(false)
  const refMoldings = useRef(null)

  const dispatch = useDispatch()

  const handleClickOutside = (val) => {
    if (val === 'legs') setSearchLegs(false)
    if (val === 'moldings') setSearchMolding(false)
  }
  useOnClickOutside(refLegs, () => handleClickOutside('legs'))
  useOnClickOutside(refMoldings, () => handleClickOutside('moldings'))

  const onFocusLegs = () => {
    setSearchLegs(true)
  }
  const onClickLegs = (index, value, id) => {
    setSearchLegs(false)
    dispatch(change('editOrder', `items[${index}].legsName`, value))
    dispatch(change('editOrder', `items[${index}].legs`, id))
  }

  const onFocusMolding = () => {
    setSearchMolding(true)
  }
  const onClickMolding = (index, value, id) => {
    setSearchMolding(false)
    dispatch(change('editOrder', `items[${index}].moldingName`, value))
    dispatch(change('editOrder', `items[${index}].molding`, id))
  }

  return (
    <>
      <div className={s.equipmentContent}>
        <span className={s.equipmentMaterial}>Ножки</span>
        {
          isEdit
            ? <div className={s.wrapInput} ref={refLegs}>
              <Field
                className={s.goodInput}
                name={`items[${index}].legsName`}
                component={FormInput}
                onFocus={(e) => onFocusLegs(e)}
              />
              {createField('', `items[${index}].legs`, [],
                Input, {type: 'hidden'})}
              <div className={`${s.productSearchBar} ${searchLegs ? s.productSearchBarActive : ''}`}>
                {
                  (legs.length > 0)
                    ? legs.map((el) => {
                      return <span key={el.id}
                                   className={s.materialName}
                                   onClick={() => onClickLegs(index, el.name, el.id)}>{el.name}</span>
                    })
                    : 'Ничего не найдено'
                }
              </div>
            </div>

            : <span className={s.equipmentName}>{item.leg}</span>
        }
      </div>

      <div className={s.equipmentContent}>
        <span className={s.equipmentMaterial}>Молдинг</span>
        {
          isEdit
            ?
            <div className={s.wrapInput} ref={refMoldings}>
              <Field
                className={s.goodInput}
                name={`items[${index}].moldingName`}
                component={FormInput}
                onFocus={(e) => onFocusMolding(e)}
              />
              {createField('', `${item}.molding`, [],
                Input, {type: 'hidden'})}
              <div
                className={`${s.productSearchBar} ${searchMolding ? s.productSearchBarActive : ''}`}>
                {
                  (moldings.length > 0)
                    ? moldings.map((el) => {
                      return <span key={el.id}
                                   className={s.materialName}
                                   onClick={() => onClickMolding(index, el.name, el.id)}>{el.name}</span>
                    })
                    : 'Ничего не найдено'
                }
              </div>
            </div>
            : <span className={s.equipmentName}>{item.molding}</span>
        }
      </div>
    </>
  )
}

export default MaterialLegsMoldings