import React from 'react'

import {createField, Input} from '../../content/common/FormsControl'

import {emailSymbols, required} from '../../../utils/validators/validators'

import s from '../Login.module.scss'

const RestoreEmail = () => {
  return (
    <>
      <div className={s.emailTitle}>Email:</div>
      <div className={s.inputWrap}>
        {createField('Введите ваш email', 'email', [required, emailSymbols], Input, {className: s.fieldEmail})}
      </div>
    </>
  )
}

export default RestoreEmail