import React, { Suspense } from 'react'

import s from './performersTasks.module.scss'
import {ComponentLoader} from "../../../common/LoaderFull/ComponentLoader";

interface IPerformersTasks {
  ordersSortStatus: any
  showOrder: (item: any) => void
}

const OrderInfo = React.lazy(() => import('../OrderInfo/OrderInfo'))

const PerformersTasks: React.FC<IPerformersTasks> = ({ ordersSortStatus, showOrder }) => {
  return (
    <div className={ s.table }>
      <div className={ s.header }>
        <span>Статус</span>
        <span>Закройщик</span>
        <span>Швея</span>
        <span>Распиловщик</span>
        <span>Сборщик</span>
        <span>Резчик</span>
        <span>Окрасчик</span>
        <span>Поролонщик</span>
        <span>Обивщик</span>
        <span>ОТК</span>
      </div>
      <Suspense fallback={<ComponentLoader />}>
        {
          ordersSortStatus.map((item: any, index: number) => <OrderInfo key={index}
                                                                       order={item}
                                                                       type={'circle'}
                                                                       showOrder={showOrder}/>)
        }
      </Suspense>


    </div>
  )
}

export default PerformersTasks