import React, { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CustomPrompt } from '../../common/customPrompt/CustomPrompt'
import RenderOrders from './RenderOrders/RenderOrders'

import {
  actionsOrder,
  getOrdersCreateFromServer,
  getOrdersCreateFromServerJoin,
  startOrdersCreateListening,
} from '../../../../redux/ordersReducer'
import { classNames } from '../../../function/toggleClasses'
import { AppStateType } from '../../../../redux/rootReducer'

import { appActions } from '../../../../redux/appReducer'

import s from './ordersList.module.scss'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { OrderType } from '../../../../types/types'
import InputWithCalendar from '../../common/InputWithCalendar/InputWithCalendar'
import SvgImages from '../../../generic/SvgImages/SvgImages'
import { orderStatusesForClient, orderStatusesForServer, orderSubStatusesForClient } from '../../../../configs/enums'
import useCheckRights, { ROLES_FOR_VIEW_ALL_WORKPOINTS } from '../../../hooks/useCheckRights'

const headlines = [
  {
    name: '№',
    className: [`${s.numbers}`, `${s.numbersHeader}`]
  },
  {
    name: 'Дата создания',
    className: [`${s.dateCreate}`, `${s.dateCreateHeader}`]
  },
  {
    name: 'Статус',
    className: [`${s.status}`, `${s.statusHeader}`]
  },
  {
    name: 'Сумма',
    className: [`${s.sum}`, `${s.sumHeader}`]
  },
  {
    name: 'Предоплата',
    className: [`${s.prepayment}`, `${s.prepaymentHeader}`]
  },
  {
    name: 'Доплата',
    className: [`${s.payment}`, `${s.paymentHeader}`]
  },
  {
    name: 'Продавец',
    className: [`${s.compiler}`, `${s.compilerHeader}`]
  },
  {
    name: 'Дата отгрузки',
    className: [`${s.dateDelivery}`, `${s.dateDeliveryHeader}`]
  },
  {
    name: '',
    className: [`${s.buttonsOrder}`, `${s.buttonsOrderHeader}`]
  }
]

const statuses = [
  {id: 1, value: 'Без предоплаты'},
  {id: 2, value: 'Ожидает'},
  {id: 3, value: 'В работе'},
  {id: 6, value: 'Готово'},
  {id: 5, value: 'Отгружено'},
]

const RenderOrdersMemo = memo(RenderOrders)

interface IProps {
  filterOrders: OrderType[]
  workpointName: string
  modalClass: string
}

export const OrdersList: FC<IProps> = (props) => {
  const role = useSelector((state: AppStateType) => state.auth.role)
  const [fetching, setFetching] = useState(true)
  const [searchName, setSearchName] = useState('')
  const [searchStatus, setSearchStatus] = useState<string | null>('')
  const [search, setSearch] = useState({
    name: '' as string,
    date: {
      to: undefined as Date | null | undefined,
      from: undefined as Date | null | undefined
    },
    status: null as number | null
  })
  const [searchWithQuery, setSearchWithQuery] = useState<string>('')
  const [isListStatues, setIsListStatuses] = useState(false)
  const {filterOrders, workpointName, modalClass} = props
  const refStatuses = useRef(null)

  const isAdmin = useCheckRights(ROLES_FOR_VIEW_ALL_WORKPOINTS, role)

  // подгрузка заказов
  const pageStore = useSelector((state: AppStateType) => state?.orders.pageOrdersCreate)
  const nextPage = useSelector((state: AppStateType) => state?.orders.ordersCreate.next)

  const nameRef = useRef(null)
  // const dateRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (filterOrders.length < 20) {
      setFetching(true)
    }
  }, [filterOrders])
  useEffect(() => {
    dispatch(startOrdersCreateListening())
  }, [dispatch])
  useEffect(() => {
    if (!fetching) return
    const status = search.status ? '' : '0'

    if (
      fetching
      && workpointName
      && nextPage !== null
      && nextPage.indexOf(`&page=${pageStore + 1}`)
      && searchWithQuery
    ) {
      dispatch(getOrdersCreateFromServerJoin(status,
        searchWithQuery + `&page=${pageStore}`, () => {
          setFetching(false)
          dispatch(actionsOrder.setPageOrdersCreate(pageStore + 1))
          dispatch(appActions.showLoader(false))
        }))
    }

    // return () => stopOrdersListening()
    // eslint-disable-next-line
  }, [fetching, workpointName])
  useEffect(() => {
    if (!modalClass) {
      document.addEventListener('scroll', scrollHandler)
    } else {
      document.removeEventListener('scroll', scrollHandler)
    }

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
    // eslint-disable-next-line
  }, [modalClass])
  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }
  //  подгрузка заказов ^^^^

  useEffect(() => {
    if (searchWithQuery) {
      dispatch(getOrdersCreateFromServer(searchWithQuery + `&page=${pageStore}`, () => {
        dispatch(actionsOrder.setPageOrdersCreate(pageStore + 1))
        dispatch(appActions.showLoader(false))
      }))
    }
    // eslint-disable-next-line
  }, [searchWithQuery])
  useEffect(() => {
    const name = search.name ? `&code__or=${search.name}` : ''
    const {from, to} = search.date
    let changeFrom
    let changeTo
    if (from) {
      changeFrom = `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`
    }
    if (to) {
      changeTo = `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`
    }

    const date = from
      ? `&created_datetime__date__gte=${changeFrom}&created_datetime__date__lte=${to ? changeTo : changeFrom}`
      : ''
    let status = ''

    if (search.status === 6) {
      status = `&status=${orderStatusesForServer.finish}&substatus__isnull=true`
    } else if (search.status === 5) {
      status = `&status=${orderStatusesForServer.finish}&substatus=SHIPPED`
    } else if (search.status === 3) {
      status = `&status__gte=3&status__lte=5`
    } else {
      status = search.status ? `&status=${search.status}` : ''
    }

    if (workpointName && isAdmin) {
      setSearchWithQuery(`${name + date + status}&order=-created_datetime,-status`)
    } else if (workpointName) {
      setSearchWithQuery(`code__startswith=${workpointName}${name + date + status}&order=-created_datetime,-status`)
    }
  }, [workpointName, search, isAdmin])

  useEffect(() => {
    if (searchName) {
      document.addEventListener('keyup', onClickKey)
    } else {
      document.removeEventListener('keyup', onClickKey)
    }

    return () => document.removeEventListener('keyup', onClickKey)
    // eslint-disable-next-line
  }, [searchName])
  const onClickKey = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && nameRef) {
      startSearch()
    }
  }
  const startSearch = () => {
    if (searchName !== search.name) {
      setSearch(value => ({...value, name: searchName}))
      dispatch(actionsOrder.setPageOrdersCreate(1))
    }
  }
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value)
    if (!e.target.value) {
      dispatch(actionsOrder.setPageOrdersCreate(pageStore + 1))
    }
  }
  const setListStatuses = () => {
    setIsListStatuses(value => !value)
  }
  const setStatus = (id: number) => {
    setSearch(value => ({...value, status: id}))
    let status = ''
    if (id === 5) {
      status = orderSubStatusesForClient['SHIPPED']
    } else {
      status = orderStatusesForClient[id]
    }
    setSearchStatus(status)
    if (id) {
      dispatch(actionsOrder.setPageOrdersCreate(1))
    }
    setIsListStatuses(false)
  }

  const clearSearchName = () => {
    setSearch(value => ({...value, name: ''}))
    setSearchName('')
    dispatch(actionsOrder.setPageOrdersCreate(1))
  }
  const clearStatus = () => {
    setSearch(value => ({...value, status: null}))
    setSearchStatus('')
    setIsListStatuses(false)
    dispatch(actionsOrder.setPageOrdersCreate(1))
  }

  useOnClickOutside(nameRef, startSearch)
  useOnClickOutside(refStatuses, () => setIsListStatuses(false))

  return (
    <>
      <CustomPrompt/>

      <div className={s.headerList}>
        <div>
          <h3 className={s.title}>Созданные заказы</h3>
        </div>

        <div className={s.search}>
          <div className={s.filterStatus}>
            <input type="text"
                   name="name"
                   className={s.searchInput}
                   onChange={(e) => onChange(e)}
                   value={searchName}
                   placeholder={'Поиск'}
                   ref={nameRef}
            />

            {
              searchName &&
              <span
                onClick={clearSearchName}
                className={s.close}
              >
              X
            </span>
            }
          </div>

          <div className={s.filterStatus}>
            <InputWithCalendar setSearch={setSearch} search={search}/>
          </div>

          <div className={s.filterStatus} ref={refStatuses}>
            <button className={s.filterStatusButton}
                    onClick={setListStatuses}
            >
              {SvgImages({type: 'status'})}
              <span>
                {
                  searchStatus ? searchStatus : 'Статус'
                }
              </span>
            </button>
            {
              search.status &&
              <span
                onClick={clearStatus}
                className={s.close}
              >X</span>
            }
            {
              isListStatues &&
              <ul className={s.filterListStatuses}>
                {
                  statuses.map(status => (
                    <li key={status.id}
                        onClick={() => setStatus(status.id)}
                    >
                      {status.value}
                    </li>
                  ))
                }
              </ul>
            }
          </div>
        </div>
      </div>

      <div>
        <div className={s.table}>

          <div className={s.tableHeader}>
            {
              headlines.map((item, i) => (
                <div key={i} className={classNames(item.className)}>
                  <span>{item.name}</span>
                </div>
              ))
            }
          </div>

          <RenderOrdersMemo filterOrders={filterOrders}/>
        </div>
      </div>
    </>
  )
}