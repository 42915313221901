export const SHOW_LOADER = 'SHOW_LOADER';

export const SHOW_MODAL = 'SHOW_MODAL';

export const SHOW_PROMPT = 'SHOW_PROMPT';

export const GET_ORDER = 'GET_ORDER';

export const REMOVE_ORDER = 'REMOVE_ORDER';

export const SET_ORDERS_CREATE = 'orders/SET_ORDERS_CREATE';

export const SET_UPDATE_ORDERS_CREATE = 'orders/SET_UPDATE_ORDERS_CREATE';

export const SET_ORDERS = 'orders/SET_ORDERS';

export const SET_UPDATE_ORDER = 'orders/SET_UPDATE_ORDER';

export const SET_ORDER_DATES = 'orders/SET_ORDER_DATES';

export const SET_UPDATE_ORDER_DATES = 'orders/SET_UPDATE_ORDER_DATES';

export const SET_ORDERS_DASHBOARD = 'orders/SET_ORDERS_DASHBOARD';

export const SET_UPDATE_ORDERS_DASHBOARD = 'orders/SET_UPDATE_ORDERS_DASHBOARD';

export const SET_CHANGED_ORDERS = 'orders/SET_CHANGED_ORDERS';

export const SET_ORDER_TO_CHANGE = 'SET_ORDER_TO_CHANGE';

export const SET_PAGE_DASHBOARD = 'orders/SET_PAGE_DASHBOARD';

export const SET_PAGE_ORDERS = 'orders/SET_PAGE_ORDERS';

export const SET_PAGE_ORDERS_CREATE = 'orders/SET_PAGE_ORDERS_CREATE';

export const GET_NOTIES = 'GET_NOTIES';

export const GET_MATERIALS_TO_ORDER = 'GET_MATERIALS_TO_ORDER';

export const GET_MATERIALS_AWAITING = 'GET_MATERIALS_AWAITING';

export const GET_MATERIALS_ARCHIVE = 'GET_MATERIALS_ARCHIVE';

export const SET_MATERIALS_TYPE = 'SET_MATERIALS_TYPE';

export const GET_NEWS = 'GET_NEWS';

export const GET_MENU = 'GET_MENU';

export const SET_TASKS = 'SET_TASKS';

export const SET_TASKS_USER = 'SET_TASKS_USER';

export const SET_USER_REPORT = 'SET_USER_REPORT';

export const SET_USER_REPORT_LINK = 'SET_USER_REPORT_LINK';

export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';

export const SET_TOKEN = 'SET_TOKEN';

export const AUTH_PROFILE = 'AUTH_PROFILE';

export const SET_RECAPTCHA = 'SET_RECAPTCHA';

export const SET_RESTORE_PASSWORD = 'SET_RESTORE_PASSWORD';

export const SET_USERS = 'SET_USERS';

export const SET_USERS_SELLERS = 'SET_USERS_SELLERS';

export const SET_USERS_CHANGED = 'SET_USERS_CHANGED';

export const SET_ROLES = 'SET_ROLES';

export const SET_USERS_UPHOLSTERERS = 'SET_USERS_UPHOLSTERERS';

export const DELETE_GOOD = 'DELETE_GOOD';

export const SET_ORDER_CODE = 'SET_ORDER_CODE';

export const SET_PRODUCT_FROM_SERVER = 'SET_PRODUCT_FROM_SERVER';

export const SET_CONSUMABLE_FROM_SERVER = 'SET_CONSUMABLE_FROM_SERVER';

export const SET_LEGS_FROM_SERVER = 'SET_LEGS_FROM_SERVER';

export const SET_MOLDING_FROM_SERVER = 'SET_MOLDING_FROM_SERVER';

export const SET_DOCUMENT_LINK = 'SET_DOCUMENT_LINK';

export const SET_POINT = 'SET_POINT';

export const SET_POINTS = 'SET_POINTS';

export const SET_PRICES = 'system/SET_PRICES';

export const SET_TEXT_MESSAGE = 'system/SET_TEXT_MESSAGE';

export const SET_REMOVE_UPLOAD_ERROR = 'system/SET_REMOVE_UPLOAD_ERROR';

export const SET_PAYMENTS = 'payments/SET_PAYMENTS';

export const SET_RESET_STATE = 'SET_RESET_STATE';