import React from 'react'

import s from './orderingMaterials.module.scss'
import {isChangeName, isOptionsToChange, namesProducts, optionsToChange} from '../../function/changeNameProduct'

export const OrderCard = ({children, content, showInfo}) => {
  return (
    <div className={s.ordersItem_card}>
      <div className={s.orderName}>
        <p className={s.card_title}>Договор {content?.materials[0]?.order_item?.order?.code}</p>
        {
          showInfo &&
          <button onClick={() => showInfo(content?.materials[0]?.order_item?.order?.id)} className={s.card_info}>Полная
            информация
          </button>
        }
      </div>

      <div className={s.card_item}>
        <p className={s.card_item_title}>Товар</p>
        <div className={s.card_item_value}>
          {/*{content?.materials[0]?.order_item?.product}*/}
          {
            !isChangeName(namesProducts, content?.materials[0]?.order_item?.product)
              ? content?.materials[0]?.order_item?.product
              : isOptionsToChange(optionsToChange, content?.materials[0]?.order_item?.options)
              ? content?.materials[0]?.order_item?.product.replace('Д/О', 'Д.к. ') : content?.materials[0]?.order_item?.product
          }
        </div>
      </div>

      <div className={s.card_item}>
        <p className={s.card_item_title}>Дата изготовления</p>
        <div className={s.card_item_value}>
          {`${content?.materials[0]?.order_item?.order?.production_date_from}-${content?.materials[0]?.order_item?.order?.production_date_to}`}
        </div>
      </div>

      <div className={s.card_item}>
        <p className={s.card_item_title}>Материал</p>
        <div className={s.card_item_value}>{content?.materials.map(item => item.material).join(' + ')}</div>
      </div>

      <div className={s.card_item}>
        <p className={s.card_item_title}>Наименование</p>
        <div className={s.card_item_value}>{content?.materials[0]?.name}</div>
      </div>

      <div className={s.card_item}>
        <p className={s.card_item_title}>Поставщик</p>
        <div className={s.card_item_value}>{content?.materials[0]?.provider}</div>
      </div>

      {children}

    </div>
  )
}