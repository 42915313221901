import React from 'react'

import { toggleClass } from '../function/toggleClass'

import s from './header.module.scss'

export const Logo = props => {
  const { collapsed } = props

  const logoClass = toggleClass(s.header__logo, s.headerRollUp, !collapsed)

  return (
      <div className={logoClass.join(' ')}>
        <div className={s.logo__img_adaptive}>
          <img style={{height: '110px'}} src="../../image/header/Agenda.svg" alt="logo"/>
        </div>

        <div className={s.logo__img}>
          {!collapsed
            ? <img style={{height: '110px'}} src="../../image/header/logo.svg" alt="logo"/>
            : <img style={{height: '110px'}} src="../../image/header/Agenda.svg" alt="logo"/>
          }
        </div>

        <p className={s.logo__title}>AGENDA</p>
      </div>
  )
}