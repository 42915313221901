export const getInitials = (firstName: string, middleName: string, lastName: string) => {
    if (!firstName && !middleName && !lastName) {
        return ''
    }

    return `
        ${lastName ? lastName : ''} 
        ${firstName && lastName ? firstName[0] + '.' : firstName} 
        ${(middleName && (lastName || firstName)) ? middleName[0] + '.' : middleName}
    `
}