import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReportFromServer, removeUserData } from '../../../../../../redux/tasksReducer'

import { InfoUser } from '../Statistics'
import { AppStateType } from '../../../../../../redux/rootReducer'
import { IUserReportData } from '../../../../../../types/types'

import s from './modalUserStatistic.module.scss'

interface IModalUserStatistic {
  setModalClass: (flag: boolean) => void
}

export const monthsNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

const ModalUserStatistic: React.FC<IModalUserStatistic & InfoUser> = ( props ) => {
  const { codeId, idUser, month, year, setModalClass } = props
  const userReportData: Array<IUserReportData> = useSelector((state: AppStateType) => state.tasks.userReport)
  const userReportDataLink: string = useSelector((state: AppStateType) => state.tasks.userReportLink)
  // const date = new Date()
  // date.setMonth(month)
  // const monthName = date.toLocaleString('ru', { month: 'long' })
  const dispatch = useDispatch()

  const total = userReportData.reduce((sum, item) => sum += item.summ, 0)

  useEffect(() => {
    if (idUser && year) {
      dispatch(getReportFromServer({
        user: idUser,
        month: month >= 0 ? month : month + 12,
        year: year
      }))
    }
  }, [dispatch, idUser, month, year])

    const closeModal = () => {
        setModalClass(false)
        dispatch(removeUserData())
    }

  return (
    <div className={s.modalContent}>
      <div className={s.close} onClick={closeModal}>x</div>

      <div className={s.titleWrap}>
        <div className={s.title}>Работы id {codeId}</div>
        <div className={s.subtitle}>За { monthsNames[month >= 0 ? month : month + 12] }</div>
      </div>

      <div className={s.buttons}>
          {
              userReportDataLink &&
              <a href={userReportDataLink}
                 className={s.print}
                 target={'_blank'}
                 rel="noreferrer">Печать</a>
          }
      </div>

      <div className={s.table}>
        <div className={s.tableHeader}>
          <div className={s.tableHeaderItem}>Договор</div>
          <div className={s.tableHeaderItem}>Товар</div>
          <div className={s.tableHeaderItem}>Сумма</div>
        </div>

        <div className={s.tableContent}>
          {
            userReportData.length
              ? userReportData.map((item, index) => (
                <div key={index} className={s.tableContentRow}>
                  <div className={s.tableContentItem}>{ item.order_name }</div>
                  <div className={s.tableContentItem}>
                    {
                      item.order_items.map((elem, idx) => (
                        <div key={idx} className={s.tableContentGood}>{ elem }</div>
                      ))
                    }
                  </div>
                  <div className={s.tableContentItem}>{ item.summ }</div>
                </div>
              ))
              : <div className={s.reportEmpty}>Выполненных работ нет!</div>
          }
        </div>
        
        <div className={s.tableFooter}>
          <div className={s.tableFooterItem}>Итого</div>
          <div className={s.tableFooterItem}>
            <div className={s.footerTotal}> </div>
            <div className={s.footerTotal}>{ total ? `${total} руб` : '' }</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalUserStatistic