// функция добавления/удаления класса по условию
export const toggleClass = (classElem, newClassElem, temp) => {

	const elementClass = [classElem];

	if (temp) {
		elementClass.push(newClassElem);
	}

	return elementClass;
};