import {usersAPI} from '../api/api'

import {SET_ROLES, SET_USERS, SET_USERS_CHANGED, SET_USERS_SELLERS, SET_USERS_UPHOLSTERERS} from './types'

import {appActions} from './appReducer'

const initialState = {
  users: [] as Array<IUser>,
  usersUpholsterers: [],
  usersSellers: [] as Array<IUser>,
  roles: []
}

interface IUser {
  avatar: null
  code_id: string
  email: string
  first_name: string
  id: number
  last_name: string
  middle_name: string
  phone_number: string
  position: string
  role: string
  status: string
  task_status: null | string
  user_access: string
  username: string
  workpoint: null | string
}

export const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.users
      }

    case SET_USERS_SELLERS:
      return {
        ...state,
        usersSellers: action.users
      }

    case SET_USERS_CHANGED:
      return {
        ...state,
        users: state.users.map((item) => item?.id === action.user?.id ? action.user : item)
      }

    case SET_ROLES:
      return {
        ...state,
        roles: action.roles
      }

    case SET_USERS_UPHOLSTERERS:
      return {
        ...state,
        usersUpholsterers: action.users,
      }

    default:
      return state
  }
}

type SetUsersActionType = {
  type: typeof SET_USERS
  users: any
}
type SetUserChangedActionType = {
  type: typeof SET_USERS_CHANGED
  user: any
}
type SetRolesActionType = {
  type: typeof SET_ROLES
  roles: any
}
type SetUsersSellersActionType = {
  type: typeof SET_USERS_SELLERS
  users: any
}

const setUsers = (users: any): SetUsersActionType => ({type: SET_USERS, users: users})
const setUsersChanged = (user: any): SetUserChangedActionType => ({type: SET_USERS_CHANGED, user: user})
const setUsersSellers = (users: any): SetUsersSellersActionType => ({type: SET_USERS_SELLERS, users: users})
const setRoles = (roles: any): SetRolesActionType => ({type: SET_ROLES, roles: roles})

export const createUserToServer = (data: any, document: any, cb: Function) => (dispatch: Function) => {
  dispatch(appActions.showLoader(false))

  return usersAPI.createUser(data, document)
    .then((response: any) => {
      dispatch(appActions.showPrompt('SUCCESS', 'Пользователь успешно создан!'))
      cb()
      // dispatch(setUsers(response.results))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}

export const getUsersWithFilter = (filter: string) => (dispatch: Function) => {
  return usersAPI.getUsersWithRole(filter)
    .then((response: any) => {
      dispatch(setUsers(response.results))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}

export const getUsersRoleName = (users: string) => (dispatch: Function) => {
  return usersAPI.getUsersRoleName(users)
    .then((response: any) => {
      dispatch(setUsersSellers(response.results))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}

export const getRoles = (type: string) => (dispatch: Function) => {
  return usersAPI.getRoles(type)
    .then((response: any) => {
      dispatch(setRoles(response))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}

export const setUserDataOnServer = (id: number, data: any, document: any = null, cb: Function) => (dispatch: Function) => {
  if (document?.file?.size > 2048000) {
    dispatch(appActions.showPrompt('ALARM', `Файл не должен превышать 2МБ`))
    return
  }
  dispatch(appActions.showLoader(true))

  return usersAPI.setUserData(id, data, document)
    .then((response: any) => {
      cb()
      dispatch(setUsersChanged(response.data))
    })
    .catch((error: any) => {
      console.log(error)
      debugger
      if (error.response?.data?.detail === "Страница не найдена!") {
        dispatch(appActions.showPrompt('ALARM', `Такого пользователя не существует!`))
        return
      }
      if (error.response) {
        dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      } else {
        dispatch(appActions.showPrompt('ALARM', `Ошибка сервера`))
      }
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}