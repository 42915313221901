import { SET_PRICES, SET_REMOVE_UPLOAD_ERROR, SET_TEXT_MESSAGE } from './types'
import { systemAPI } from '../api/api'
import { appActions } from './appReducer'
import { downloader } from '../utils/downloader/downloader'

const initialState = {
  tableData: [] as Array<any>,
  fails: [] as Array<string>
}

export type InitialStateType = typeof initialState

export const systemReducer = (state = initialState, action: ActionsTypes): InitialStateType => {
  switch (action.type) {
    case SET_PRICES:
      return {
        ...state,
        tableData: action.tableData
      }

    case SET_TEXT_MESSAGE:
      return {
        ...state,
        fails: action.fails
      }

    case SET_REMOVE_UPLOAD_ERROR:
      return {
        ...state,
        fails: []
      }

    default:
      return state
  }
}

type SetPrices = {
  type: typeof SET_PRICES
  tableData: Array<any>
}
type SetTextMessage = {
  type: typeof SET_TEXT_MESSAGE
  fails: Array<string>
}
type SetRemoveUploadError = {
  type: typeof SET_REMOVE_UPLOAD_ERROR
}
type ActionsTypes = SetPrices | SetTextMessage | SetRemoveUploadError

export const setPrices = (tableData: Array<any>) => ({type: SET_PRICES, tableData})
export const setFailsMessage = (fails: Array<string>) => ({type: SET_TEXT_MESSAGE, fails})
export const setRemoveUploadError = () => ({type: SET_REMOVE_UPLOAD_ERROR})

interface ITask {
  summ: number,
  is_dk: boolean,
  role: string,
  product: string,

  [key: string]: any
}

export const getFabricDocument = (type: string | null) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return systemAPI.getFabricDocument(type)
    .then((response: any) => {
      dispatch(appActions.showPrompt('SUCCESS', 'Подготовка документа к скачиванию...'))

      if (response.status === 200) {
        setTimeout(() => downloader(response.data, '_self'), 3000)
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const sendFabricDocument = (file: any) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return systemAPI.sendFabricDocument(file)
    .then((response: any) => {
      dispatch(appActions.showPrompt('SUCCESS', 'Загрузка документа завершена!'))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getConsumablesDocument = (type: string | null) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return systemAPI.getConsumablesDocument(type)
    .then((response: any) => {
      dispatch(appActions.showPrompt('SUCCESS', 'Подготовка документа к скачиванию...'))

      if (response.status === 200) {
        setTimeout(() => downloader(response.data, '_self'), 3000)
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const sendConsumablesDocument = (file: any) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return systemAPI.sendConsumablesDocument(file)
    .then((response: any) => {
      if (!response.data.success) {
        dispatch(appActions.showModal(true))
        dispatch(setFailsMessage(response.data.fails))
      } else {
        dispatch(appActions.showPrompt('SUCCESS', 'Загрузка документа завершена!'))
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getProductsDocument = (type: string | null) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return systemAPI.getProductsDocument(type)
    .then((response: any) => {
      dispatch(appActions.showPrompt('SUCCESS', 'Подготовка документа к скачиванию...'))

      if (response.status === 200) {
        setTimeout(() => downloader(response.data, '_self'), 3000)
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getClientsBase = () => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return systemAPI.getClientsBase()
    .then((response: any) => {
      dispatch(appActions.showPrompt('SUCCESS', 'Подготовка документа к скачиванию...'))

      if (response.status === 200) {
        setTimeout(() => downloader(response.data, '_self'), 3000)
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const sendProductsDocument = (file: any) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return systemAPI.sendProductsDocument(file)
    .then((response: any) => {
      dispatch(appActions.showPrompt('SUCCESS', 'Загрузка документа завершена!'))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getPricesForTasks = () => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return systemAPI.getPricesForTasks()
    .then((response: any) => {
      dispatch(setPrices(response))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const setNewPriceToServer = (data: ITask, cbError: Function) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return systemAPI.setNewPriceForTask(data)
    .then((response: any) => {
      console.log(response)
    })
    .catch((error: any) => {
      if (error.response) {
        cbError()
        // dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      } else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}