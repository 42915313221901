import React, { FC, useEffect, useState } from 'react'
import { normalizePrice, removeSpace } from '../../../../../../utils/validators/validators'
import { paymentType } from '../../../../../../configs/enums'

import s from './prepayment.module.scss'
import { changeOrder } from '../../../../../../redux/ordersReducer'
import { useDispatch } from 'react-redux'
import { appActions } from '../../../../../../redux/appReducer'

interface IProps {
  idOrder: number
  status: number
  prepayment_fact: string
  prepayment: number
  payment_type: paymentType
}

const Prepayment: FC<IProps> = ({idOrder, status, prepayment_fact, prepayment, payment_type}) => {
  const [isFactPrepayment, setIsFactPrepayment] = useState(false)
  const [valueFact, setValueFact] = useState(`${prepayment_fact}`)
  const [isValueInvalid, setIsValueInvalid] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setValueFact(prepayment_fact)
  }, [prepayment_fact])

  const checkValue = (): boolean => {
    const numValue = +(removeSpace(valueFact))

    if (numValue > prepayment) {
      setIsValueInvalid(true)
      dispatch(appActions.showPrompt('ALARM', `Число не может быть больше ${prepayment}`))
      return false
    }
    return true
  }
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement> & React.FocusEvent<HTMLInputElement>) => {
    if (event?.key?.toLowerCase() === 'enter' && checkValue()) {
      event.target.blur()
      changeOrderPrepayment()
      setIsValueInvalid(false)
    }
  }
  const onBlurField = () => {
    if (checkValue()) {
      setIsFactPrepayment(false)
      setIsValueInvalid(false)
      changeOrderPrepayment()
    }
  }
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeSpace(e.target.value.replace(/[^0-9]/gi, ''))

    setValueFact(+value[0] !== 0 ? normalizePrice(value) : normalizePrice(value.slice(1)))

    if (+value > 0 && +value <= prepayment) {
      setIsValueInvalid(false)
    } else if (+value > prepayment) {
      setIsValueInvalid(true)
    } else {
      setValueFact('0')
    }
  }
  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = removeSpace(e.target?.value)

    if (+value === 0) {
      setValueFact('')
    } else {
      setValueFact(normalizePrice(e.target.value))
    }
  }
  const changeOrderPrepayment = () => {
    const removedSpace = +(removeSpace(valueFact))
    console.log(removedSpace)
    if (removedSpace !== +prepayment_fact) {
      dispatch(changeOrder(idOrder, {
        prepayment_fact: removedSpace,
        prepayment_date: removedSpace ? new Date().toLocaleDateString() : null
      }))
    }
  }

  return (
    <div className={s.prepaymentCell}>
      {
        status === 1 &&
        <span className={s.factPrepayment}>
          {
            !isFactPrepayment
              ? <span className={s.factSpan}
                      onClick={() => setIsFactPrepayment(true)}>
                  {normalizePrice(`${valueFact}`)}
                </span>
              : <input className={`${s.prepaymentInput} ${isValueInvalid ? s.invalidValue : ''}`}
                       onChange={onChange}
                       onBlur={onBlurField}
                       onFocus={onFocus}
                       value={valueFact}
                       autoFocus={true}
                       onKeyDown={onKeyDown}/>
          }
        </span>
      }
      {
        status === 1 && ` / `
      }
      <span className={status !== 1 ? s.fullWidthPrepayment : s.prepayment}>
        {normalizePrice(`${prepayment}`)}
        {status !== 1 && payment_type && ' - ' + paymentType[payment_type]}
      </span>
    </div>
  )
}

export default Prepayment