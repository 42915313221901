import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'

import {
  changeQuantityConsumable,
  getConsumableFromServer,
} from '../../../../redux/productsReducer'

import s from './StorageMaterials.module.scss'

const TableContent = (props) => {
  const {id, name, quantity, unit, in_work} = props
  const dispatch = useDispatch()
  const [currentQuantity, setCurrentQuantity] = useState(quantity)

  const onChange = (e) => {
    const target = +e.target.value

    if (target !== Math.round(quantity)) {
      setCurrentQuantity(target)
    }
  }

  const onSubmit = (e) => {
    const target = +e.target.value

    if (target === Math.round(quantity)) {
      return null
    }

    if (target === '') {
      setCurrentQuantity(Math.round(quantity))
      return null
    }

    dispatch(changeQuantityConsumable(id, {quantity: target}))
  }

  return (
    <>
      <div className={s.data}>
        <div className={`${s.dataItem} ${s.dataItemName}`}>{name}</div>
        <div className={`${s.dataItem} ${s.dataItemUnit}`}>{unit}</div>
        <input type="number"
               onChange={onChange}
               onBlur={onSubmit}
               className={`${s.dataItem} ${s.dataItemQuantity}`}
               value={Math.round(currentQuantity | 0)}
        />
        <div className={`${s.dataItem} ${s.dataItemOrder}`}>{ Math.round(in_work | 0)}</div>
      </div>
    </>
  )
}

const StorageMaterials = () => {
  const consumable = useSelector((state) => state?.products?.consumable)
  const userId = useSelector(state => state?.auth?.id)
  const dispatch = useDispatch()
  const sortConsumable = consumable.sort((a, b) => +a.quantity - +b.quantity)

  useEffect(() => {
    if (userId) {
      dispatch(getConsumableFromServer())
    }
  }, [dispatch, userId])

  return (
    <div>

      <div className={s.content}>
        <div className={s.header}>
          <div className={s.headerTitleName}>Наименование</div>
          <div className={s.headerTitleUnit}>Ед.изм.</div>
          <div className={s.headerTitleQuantity}>Текущий остаток</div>
          <div className={s.headerTitleOrder}>Текущие расходы</div>
        </div>

        {
          sortConsumable.map(item => <TableContent key={item.id}
                                                   {...item}/>)
        }
      </div>
    </div>
  )
}

export default reduxForm({form: 'storage-materials'})(StorageMaterials)