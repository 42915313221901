import React, { FC, useEffect, useRef, useState } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'

import s from '../InputWithCalendar/inputWithCalendar.module.scss'
import 'react-day-picker/lib/style.css'
import { useDispatch } from 'react-redux'
import actions from '../../../../redux/actions'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

const WEEKDAYS_SHORT = {ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']}
const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ]
}

interface IValue {
  name: string
  date: {
    to: Date | null | undefined
    from: Date | null | undefined
  }
  status: number | null
}

interface IProps {
  search: any
  setSearch: (value: (value: IValue) => any) => void
}

const InputWithCalendar: FC<IProps> = ({search, setSearch}) => {
  const [date, setDate] = useState({
    from: undefined as Date | undefined | null,
    to: undefined as Date | undefined | null
  })
  const [datesToString, setDatesToString] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const {from, to} = date
  const dispatch = useDispatch()
  const refCalendar = useRef(null)

  useOnClickOutside(ref, () => {
    setIsOpen(false)
    setSearch(value => ({...value, date}))
  })

  useEffect(() => {
    if (date.from) {
      const toLocaleFrom = from?.toLocaleDateString()
      const toLocaleTo = to?.toLocaleDateString()
      const joinDates = `${toLocaleFrom}${toLocaleTo ? ' - ' + toLocaleTo : ''}`

      setDatesToString(joinDates)
    } else {
      setDatesToString('')
    }

    dispatch(actions.setPageOrdersCreate(1))
    if (date.to) {
      setSearch({
        ...search,
        date: {
          from: date.from,
          to: date.to
        }
      })
      setIsOpen(false)
    }
    // eslint-disable-next-line
  }, [date])

  const handleDayClick = (day: Date) => {
    const range = DateUtils.addDayToRange(day, date)
    setDate(range)
  }

  const clearCalendar = () => {
    setDate({from: undefined, to: undefined})
    setSearch(value => ({
      ...value,
      date: {
        from: undefined,
        to: undefined
      }
    }))
    dispatch(actions.setPageOrdersCreate(1))
  }

  return (
    <div
      className={s.searchDate}
      ref={ref}
    >
      <div>
        <input
          className={s.searchInput}
          defaultValue={datesToString}
          onFocus={() => setIsOpen(true)}
          placeholder={'Выберите дату'}
        />
      </div>

      {
        isOpen &&
        <DayPicker
          ref={refCalendar}
          className={s.selectable + ' ' + s.dayPicker}
          selectedDays={{from, to}}
          onDayClick={handleDayClick}
          months={MONTHS['ru']}
          weekdaysShort={WEEKDAYS_SHORT['ru']}
        />
      }

      {
        date.from &&
        <span
          onClick={clearCalendar}
          className={s.close}
        >X</span>
      }
    </div>
  )
}

export default InputWithCalendar
