export enum orderStatusesForServer {
  new = 1,
  preWork = 2,
  working = 3,
  preOtk = 4,
  otk = 5,
  finish = 6,
}

export enum orderStatusesForClient {
  'Без предоплаты' = 1,
  'Ожидает' = 2,
  'В работе' = 3, // 3,4,5 = договор "В работе"
  'Готово' = 6
}

export enum orderSubStatusesForClient {
  'SHIPPED' = 'Отгружено'
}

export enum paymentType {
  'Перевод по реквизитам' = 1,
  'Перевод по счету' = 2,
  'Терминал' = 3,
  'Наличный расчет' = 4
}