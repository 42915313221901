import React from 'react'
import { change, formValueSelector } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

import {createField, LabelInput} from '../../../common/FormsControl'

import s from './Scheme.module.scss'

const Scheme = ({ item }) => {
  const selector = formValueSelector('fieldArrays')
  const state = useSelector(state => state)
  const scheme = selector(state, `${item}.scheme`)
  const dispatch = useDispatch()

  const onClick = (e) => {
    dispatch(change('fieldArrays', `${item}.scheme`, e.target.value))
  }
  return (
    <div className={s.scheme}>
      <div className={s.fieldWrap}>
        {createField('Г', `${item}.scheme`, [],
          LabelInput, {
            value: 'г',
            id: `${item}.scheme-g`,
            type: 'radio',
            className: `${s.checkboxInput}`,
            classLabel: `${s.schemeLabel}`,
            classWrap: `${s.inputWrap}`,
            content: 'Г',
            onClick: (e) => onClick(e),
            checked: scheme === 'г'
          },'')}
      </div>
      <div className={s.fieldWrap}>
        {createField('7', `${item}.scheme`, [],
          LabelInput, {
            value: '7',
            id: `${item}.scheme-7`,
            type: 'radio',
            className: `${s.checkboxInput}`,
            classLabel: `${s.schemeLabel}`,
            classWrap: `${s.inputWrap}`,
            content: '7',
            onClick: (e) => onClick(e),
            checked: scheme === '7'
          }, '')}
      </div>
      <div className={s.fieldWrap}>
        {createField('', `${item}.scheme`, [],
          LabelInput, {
            id: `${item}.empty`,
            type: 'radio',
            className: `${s.checkboxInput}`,
            classLabel: `${s.schemeLabel}`,
            classWrap: `${s.inputWrap}`,
            content: '',
          }, '')}
      </div>
    </div>
  )
}

export default Scheme