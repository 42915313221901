import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { appActions } from '../../../../redux/appReducer'

import s from './customPrompt.module.scss'

export const CustomPrompt = ({time = 3000}) => {
  const prompt = useSelector(state => state.app.prompt)

  const [style, setStyle] = useState('')
  const dispatch = useDispatch()

  const closePrompt = () => {
    dispatch(appActions.showPrompt(null))
    setStyle('')
  }

  useEffect(() => {
    if (!prompt.style) return
    if (prompt.style === 'SUCCESS') setStyle(s.prompt_success)
    if (prompt.style === 'ALARM') setStyle(s.prompt_alarm)
    if (prompt.style === 'WARN') setStyle(s.prompt_warning)

    let timerId = setTimeout(closePrompt, time)

    return () => {
      clearTimeout(timerId)
    }
    // eslint-disable-next-line
  }, [prompt])

  if (!prompt.style) return null

  return (
    <div className={s.container}>
      <div className={`${s.prompt} ${style}`}>
        {prompt.text}
        <p onClick={closePrompt}>x</p>
      </div>
    </div>
  )
}