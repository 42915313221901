import React, { useEffect, useState } from 'react'

import ModalFormUserInfo from '../ModalFormUserInfo/ModalFormUserInfo'

import {IUser} from '../../../../../types/types'

import s from './performersInfo.module.scss'

interface IContent {
  item: IUser
}

const Content: React.FC<IContent> = ({ item }) => {
  const [status, setStatus] = useState('')
  const [isModal, setIsModal] = useState(false)

  // устанавливаем стиль статуса в таблице(зелёный/желтый круг или красный крест)
  useEffect(() => {
    if (item?.status === 'active') setStatus(s.active)
    if (item?.status === 'inactive') setStatus(s.blocked)
    if (item?.status === 'deleted') setStatus(s.delete)
  }, [item])

  return (
    <div className={s.content}>
      <span className={s.id}>
        <span className={status}> </span>
        {item?.code_id}
      </span>
      <span>{`${item?.first_name} ${item?.last_name} ${item?.middle_name}`}</span>
      <span>{item?.role}</span>
      <span>{item?.phone_number}</span>
      <span className={s.info} onClick={() => setIsModal(true)}>Подробнее</span>

      {
        isModal &&
        <div className={s.modalMat}>
          <div className={s.modal}>
            <button className={s.btnClose} onClick={() => setIsModal(false)}>x</button>

            <h2 className={s.modalTitle}>ID-{item.code_id}</h2>

            <ModalFormUserInfo userData={item}/>
          </div>
        </div>
      }
    </div>
  )
}

interface IPerformersInfo {
  users: Array<IUser>
}

const PerformersInfo: React.FC<IPerformersInfo> = ({ users }) => {
  return (
    <div className={s.performersInfo}>

      <div className={s.header}>
        <span>ID</span>
        <span>ФИО</span>
        <span>Должность</span>
        <span>Телефон</span>
        <span>Полная информация</span>
      </div>

      {
        users?.length > 0 && users.map((item) => <Content key={item.id} item={item}/>)
      }
    </div>
  )
}

export default PerformersInfo