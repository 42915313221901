import { newsAPI } from '../api/api'

import { GET_NEWS } from './types'

import { appActions } from './appReducer'

const initialState = {
  news: [],
}

export const newsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_NEWS:
      return {...state, news: action.payload}

    default:
      return state
  }
}

type GetNewsActionType = {
  type: typeof GET_NEWS
  payload: any
}
export const getNews = (news: any): GetNewsActionType => {
  return {
    type: GET_NEWS,
    payload: news
  }
}

export const getNewsFromServer = () => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))
  return newsAPI.getNews()
    .then((response: any) => dispatch(getNews(response.data)))
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
