import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { toggleClass } from '../function/toggleClass'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { useReverseArray } from '../hooks/useReverseArray'

import s from './sider.module.scss'

export const SiderComponent = props => {
	const { collapsed, setCollapsed } = props
	const menu = useSelector(state => state.app.menu)
	const ref = useRef(null)

	const siderMenu = useReverseArray(menu)

	const handleClickOutside = () => setCollapsed(false);
	useOnClickOutside(ref, handleClickOutside);

	const siderClass = toggleClass(s.sider, s.siderRollUp, !collapsed);

	return (
		<section className={siderClass.join(' ')} ref={ref}>
			<ul className={s.list}>
				{siderMenu?.map(item => (
					<NavLink key={item.id} to={item.to} activeClassName={s.sider_active}>
						<li>
							<div className={s.sider__img}>
								<img src={item.icon} alt={item.icon_alt}/>
							</div>
							<p className={s.sider__title}>{item.title}</p>
							<div className={s.sider__tooltip}>
								<p>{item.title}</p>
							</div>
						</li>
					</NavLink>
				))}

				<li className={s.help}>
					<div className={s.sider__img}>
						<img src="../../image/sider/help.png" alt="help"/>
					</div>
					<p className={s.sider__title}>Помощь</p>
				</li>		
			</ul>
		</section>
	);
};