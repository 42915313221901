import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '../../../common/Buttons/button/Button'
import { OrderCard } from '../../OrderCard'
import AwaitingReceiptForm from './AwaitingReceiptForm'
import { Confirmation } from '../../../common/confirmation/Confirmation'

import { orderMaterials } from '../../../../../redux/materialsReducer'
import { appActions } from '../../../../../redux/appReducer'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'

import s from '../../orderingMaterials.module.scss'

export const AwaitingReceiptCard = ({ item, showInfo }) => {
  const form = useSelector(state => state.form.FormChangeOrder)
  const [access, setAccess] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [count, setCount] = useState(0)
  const [err, setErr] = useState(false)
  const ref = useRef(null)
  const dispatch = useDispatch()

  // закрытие доступа изменения заказа
  const sendData = () => setAccess(false)

  // ----------------------закрытие доступа изменения заказа при клике вне области--------------------------
  const handleClickOutside = () => sendData()
  useOnClickOutside(ref, handleClickOutside)
  // -------------------------------------------------------------------------------------------------------

  // формирование ошибки для пользователя при ошибке в валидации
  useEffect(() => {
    if (form && form.syncErrors) setErr(true)
    else setErr(false)
  }, [form])

  // запрос на изменение данных материала
  useEffect(() => {
    if (count > 1) {
      if (err) {
        dispatch(appActions.showPrompt('ALARM', `Данные не сохранены. Введите корректные данные`))
        return
      }
      dispatch(orderMaterials(item.id, form.values))
    }
    // eslint-disable-next-line
  }, [count])

  useEffect(() => {
    if (!access) {
      setCount(count => count + 1)
    }
  }, [access])

  // открытие окна подтверждения
  const onClick = () => setOpenConfirm(true)

  // закрытие окна подтверждения
  // eslint-disable-next-line
  const handleCancel = useCallback(() => setOpenConfirm(false), [])

  // запрос на изменение статуса материала (материал поступил)
  const submitData = () => {
    let data = {
      status: 3,
      end_datetime: (new Date()).toLocaleDateString()
    }
    dispatch(orderMaterials(item.id, data))
    handleCancel()
  }


  return (
    <div ref={ref}>
      <OrderCard content={item} showInfo={showInfo}>
        {openConfirm &&
        <Confirmation
          text="Подтвердите получение заказа"
          submitData={submitData}
          subTitle="Отменить действие будет невозможно"
          handleCancel={handleCancel}
        />
        }
        <AwaitingReceiptForm access={access} value={item}/>
        <p className={s.card_item_date}>Дата заказа <span>{item.modified_datetime}</span></p>
        <div className={`${s.card_item}  ${s.item_control}`}>
          <span>Дата создания:<span>{item.created_datetime}</span></span>
          <Button
            title="Заказ поступил"
            className={`${s.item_control_button} ${s.awaitingReceipt_button}`}
            onClick={onClick}
          />
        </div>
        <span className={s.card_changeItem} onClick={() => setAccess(true)}>Изменить</span>
      </OrderCard>
    </div>
  )
}