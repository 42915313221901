import { FC } from 'react'
import s from '../GoodsInStock/goodsInStock.module.scss'
import { isChangeName, isOptionsToChange, namesProducts, optionsToChange } from '../../../function/changeNameProduct'
import Select from '../GoodsInStock/SelectPoint/Select'
import { LegMolding, OptionGoods, WorkpointsType } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../../../redux/rootReducer'
import { changeGoodToServer } from '../../../../redux/goodsReducer'

interface IRow {
  id: number
  product: string
  production_date: string
  workpoint: any
  options: OptionGoods[]
  setIsListPoints: (flag: boolean) => void
  leg: LegMolding | undefined
  molding: LegMolding | undefined
  materials: any[]
  note_tk1: string
  note_tk2: string
  schema: string
}
const Row: FC<IRow> = (props) => {
  const {
    setIsListPoints,
    id, product, production_date, workpoint, options,
    leg, molding, materials, note_tk1, note_tk2, schema
  } = props
  const workpoints = useSelector((state: AppStateType) => state.points.points)
  const dispatch = useDispatch()

  const optionsForTitle = options.reduce((acc = '', option, index): string => {
    return acc + `${option.name}\n`
  }, '')
  const materialsForTitle = materials.reduce((acc = '', material, index): string => {
    return acc + `${material.name ? `${material.material} ${material.name} ${material.provider}\n` : ''}`
  }, '')
  const tk1 = note_tk1 ? `ТК1: ${note_tk1}\n` : ''
  const tk2 = note_tk2 ? `ТК2: ${note_tk2}\n` : ''
  const legName = `${leg?.name ? `Ножки: ${leg.name}\n` : ''}`
  const moldingName = `${molding?.name ? `Молдинг: ${molding.name}\n` : ''}`
  const schemaName = `${schema ? `Cхема: ${schema}\n` : ''}`

  const accTitle = optionsForTitle + materialsForTitle + tk1 + tk2 + legName + moldingName + schemaName

  const setPointToServer = (id: number, choice: WorkpointsType) => {
    dispatch(changeGoodToServer(
      id,
      {workpoint: choice.id}, `order__isnull=true&limit=10000`)
    )
    setIsListPoints(false)
  }
  const getWorkpointName = (id: number, workpoints: WorkpointsType[]) => {
    return workpoints.find(workpoint => workpoint.id === id)
  }

  return (
    <div className={s.good}>
      <div className={s.goodField} title={accTitle || 'Без комплектации'} onClick={() => console.log(accTitle)}>
        {

          (isChangeName(namesProducts, product) && !isOptionsToChange(optionsToChange, options))
            ? product
            : product.replace('Д/О', 'Д.к. ')
        }
      </div>
      <div className={s.goodField}>{production_date}</div>
      <Select
        activeChoice={getWorkpointName(workpoint?.id, workpoints)?.prefix || ''}
        choices={workpoints}
        setNewChoice={(choice) => setPointToServer(id, choice)}
      />
    </div>
  )
}

export default Row