import { goodsAPI } from '../api/api'
import { appActions } from './appReducer'
import { InferActionsTypes } from './rootReducer'
import { AxiosResponse } from 'axios'
import { GoodsType } from '../types/types'

const initialState = {
  goodsInStock: [] as GoodsType[]
}

export type InitialStateType = typeof initialState

export const goodsReducer = (state = initialState, action: InferActionsTypes<typeof actionsGoods>): InitialStateType => {
  switch (action.type) {
    case 'SET_GOODS':
      return {
        ...state,
        goodsInStock: action.payload
      }

    default:
      return state
  }
}

export const actionsGoods = {
  setGoods: (payload: any) => ({type: 'SET_GOODS', payload} as const)
}

export const getGoodFromServer = (query?: string) => (dispatch: any) => {
  dispatch(appActions.showLoader(true))

  return goodsAPI.getGood(query)
    .then((response: any) => {
      if (response.status === 200) {
        dispatch(actionsGoods.setGoods(response.data.results))
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getGoodsFromServer = (query?: string) => (dispatch: any) => {
  return goodsAPI.getGoods(query)
    .then((response: any) => {
      if (response.status === 200) {
        dispatch(actionsGoods.setGoods(response.data.results))
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const changeGoodToServer = (id: number, data: any, query: string) => (dispatch: Function) => {
  return goodsAPI.changeGood(id, data)
    .then((response: AxiosResponse) => {
      dispatch(getGoodsFromServer(query))
      dispatch(appActions.showPrompt('SUCCESS', 'Данные успешно изменены'))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
