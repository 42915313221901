import React from 'react';
import s from './PersonalData.module.scss'

export const PersonalData = ({phone, firstName, lastName, avatar}) => {
	return (
		<div className={s.personalData}>
			<div className={s.personalContent}>
				<div className={s.person}>
					<span className={s.personalImg}>
						<img src={avatar} alt=""/>
					</span>
					<span className={s.personalName}>{firstName + ' ' + lastName}</span>
				</div>

				<div className={s.data}>
					<div className={s.dataItem}>
						<span className={s.phoneTitle}>Телефон</span>
						<span className={s.phone}>{phone}</span>
					</div>
				</div>
			</div>

		</div>
	);
};