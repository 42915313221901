import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, Field } from 'redux-form'
import { FormInput } from '../../FormOrderBlock/FormOrderControl'
import { isNumber, maxLength, minLength, required } from '../../../../../utils/validators/validators'

import s from './productMaterialInfo.module.scss'

const minLength3 = minLength(3)
const maxLength10 = maxLength(10)
const maxLength50 = maxLength(50)

export const ProductMaterialInfo = ({item}) => {
  const {isFormFromHall} = useSelector(state => state.orders)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(change('fieldArrays', `${item}.material1`, 'Ткань 1'))
    dispatch(change('fieldArrays', `${item}.material2`, 'Ткань 2'))
    dispatch(change('fieldArrays', `${item}.material3`, 'Кант'))
    // eslint-disable-next-line
  }, [])

  return (
    <div className={s.formItemBlock}>
      <div className={s.headlines}>
        <div className={s.headMaterial}>Материал</div>
        <div className={s.headName}>Наименование</div>
        <div className={s.headProvider}>Поставщик</div>
        <div className={s.headPrice}>Цена</div>
      </div>

      <div className={s.inputsBlock}>
        <Field name={`${item}.material1`} component={FormInput}
               disabled={true} className={s.inputMaterial}/>
        <Field name={`${item}.name1`}
               component={FormInput}
               validate={[minLength3, maxLength50, required]}
               className={s.inputName} autoComplete={'off'}
               disabled={isFormFromHall}
        />
        <Field name={`${item}.provider1`} component={FormInput}
               validate={[minLength3, maxLength50, required]}
               className={s.inputProvider} autoComplete={'off'}
               disabled={isFormFromHall}
        />
        <Field name={`${item}.materialPrice1`} component={FormInput}
               validate={[maxLength10, isNumber, required]}
               className={s.inputPrice} autoComplete={'off'}/>
      </div>
      <div className={s.inputsBlock}>
        <Field name={`${item}.material2`} component={FormInput}
               disabled={true} className={s.inputMaterial}/>
        <Field name={`${item}.name2`} component={FormInput}
               validate={[minLength3, maxLength50]}
               className={s.inputName}
               disabled={isFormFromHall}
        />
        <Field name={`${item}.provider2`} component={FormInput}
               validate={[minLength3, maxLength50]}
               className={s.inputProvider}
               disabled={isFormFromHall}
        />
        <Field name={`${item}.materialPrice2`} component={FormInput}
               validate={[maxLength10, isNumber]}
               className={s.inputPrice}/>
      </div>
      <div className={s.inputsBlock}>
        <Field name={`${item}.material3`} component={FormInput}
               disabled={true} className={s.inputMaterial}/>
        <Field name={`${item}.name3`} component={FormInput}
               validate={[minLength3, maxLength50, required]}
               className={s.inputName}
               disabled={isFormFromHall}
        />
        <Field name={`${item}.provider3`} component={FormInput}
               validate={[minLength3, maxLength50, required]}
               className={s.inputProvider}
               disabled={isFormFromHall}
        />
        <Field name={`${item}.materialPrice3`} component={FormInput}
               validate={[maxLength10, isNumber, required]}
               className={s.inputPrice}/>
      </div>
    </div>
  )
}