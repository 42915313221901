export const required = (value: string) => {
  if (value) return undefined

  return "Обязательное поле"
}
export const requiredBorder = (value: string) => {
  if (value) return undefined

  return " "
}

type IsSelected = {
  fullName: string
}
type IsSelectedLegs = {
  name: string
}
type IsSelectedOrder = {
  code: string
}

export const isSelected = (arr: Array<IsSelected>) => (value: string) => {
  const isFromList = arr.some(item => item.fullName === value)
  if (isFromList) return undefined

  return "Выберите пользователя"
}

export const isSelectedLegsMoldings = (arr: Array<IsSelectedLegs>) => (value: string) => {
  const isFromList = arr.some(item => item.name === value)
  if (!value || isFromList) return undefined

  return "Выберите из списка"
}
export const isSelectedFromList = (arr: Array<IsSelectedOrder>) => (value: string) => {
  const isFromList = arr.some(item => item.code === value)
  if ((!value && arr) || isFromList) return undefined

  return "Выберите из списка"
}
export const isSelectedFromListProduct = (value: string) => {
  const products: Array<string> = sessionStorage.getItem('products')?.split(',') || []

  if (!value && !products) return ''

  const isFromList = products?.some(item => item === value)
  if ((!value && products) || isFromList) return undefined

  return "Выберите из списка"
}

export const minLength = (length: number) => (value: string) => {
  if (value && value.length < length) return `Минимальная длина ${length} символов`

  return ''
}

export const maxLength = (length: number) => (value: string) => {
  if (value && value.length > length) return `Макс. длина ${length} символов`

  return ''
}

export const loginSymbols = (value: string) => {
  if (value && /[^a-zA-Z0-9@.\-_]/i.test(value)) return 'Недопустимые символы'

  return ''
}
export const emailSymbols = (value: string) => {
  if (value && !/[\w+]+@[\w+]+\.+[\w+]+/i.test(value)) return 'Формат email - email@domain.com'

  return ''
}

export const passSymbols = (value: string) => {
  if (value && /[^a-zA-Z0-9]/i.test(value)) return 'Недопустимые символы'

  return ''
}

export const normalizePhone = (value: string) => {
  if (!value) return value

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 1 && (onlyNums === '8' || onlyNums === '7')) return `+7`
  if (onlyNums.length <= 1 && (onlyNums === '9')) return `+7-${onlyNums}`
  
  if (onlyNums.length <= 1 ) return `${onlyNums}`

  if (onlyNums.length <= 4) return `+${onlyNums.slice(0, 1)}-(${onlyNums.slice(1)}`
  if (onlyNums.length <= 7) return `+${onlyNums.slice(0, 1)}-(${onlyNums.slice(1, 4)})-${onlyNums.slice(4)}`

  return `+${onlyNums.slice(0, 1)}-(${onlyNums.slice(1, 4)})-${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 11)}`
}

export const normalizeDiscont = (value: number) => {
  if (value && value > 100) return 100;
  if (value && value < 0) return Math.abs(value)
  return value
}

export const normalizeQuantity = (value: number) => {
  if (value && value > 99) return 99
  if (value && value < 1) return Math.abs(value)
  return value
};

export const normalizePrice = (value: string) => {

  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 3) return `${onlyNums}`
  if (onlyNums.length <= 4) return `${onlyNums.slice(0, 1)} ${onlyNums.slice(1)}`
  if (onlyNums.length <= 5) return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2)}`
  if (onlyNums.length <= 6) return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3)}`

  if (onlyNums.length <= 7) return `${onlyNums.slice(0, 1)} ${onlyNums.slice(1, 4)} ${onlyNums.slice(4)}`

  return `${onlyNums.slice(0, 1)} ${onlyNums.slice(1, 4)} ${onlyNums.slice(4, 7)} ${onlyNums.slice(7, 10)}`
}

export const normalizeCost = (value: string) => {
  if (isNaN(Number(value))) return
  if (value.length <= 3) return `${value}`
  if (value.length <= 4) return `${value.slice(0, 1)} ${value.slice(1)}`
  if (value.length <= 5) return `${value.slice(0, 2)} ${value.slice(2)}`
  if (value.length <= 6) return `${value.slice(0, 3)} ${value.slice(3)}`
  if (value.length <= 7) return `${value.slice(0, 1)} ${value.slice(1, 4)} ${value.slice(4)}`
  if (value.length <= 8) return `${value.slice(0, 1)} ${value.slice(1, 4)} ${value.slice(4, 7)} ${value.slice(7)}`
  if (value.length <= 9) return `${value.slice(0, 1)} ${value.slice(1, 4)} ${value.slice(4, 7)} ${value.slice(7, 10)} ${value.slice(10)}`
}

export const removeSpace = (value: string) => value ? value.replaceAll(' ', '') : value

export const isNumber = (value: any): any => {
  if (value && isNaN(value)) return 'Введите число'

  return ''
}
export const isPositive = (value: any): any => {
  if (value && (+value > 0)) return undefined

  return ' '
}