import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {appActions} from '../../../../redux/appReducer'

import { RequiresToOrder } from './requiresToOrder/RequiresToOrder'
import { AwaitingReceipt } from './awaitingReceipt/AwaitingReceipt'
import { CustomPrompt } from '../../common/customPrompt/CustomPrompt'
import ModalInfo from '../../ProductionOrders/ModalInfo/ModalInfo'
import {Modal} from '../../common/modal/Modal'

import {getOrderFromServer, actionsOrder} from '../../../../redux/ordersReducer'

import s from '../orderingMaterials.module.scss'

export const Orders = () => {
	const orderInfo = useSelector(state => state?.orders?.orderInfo)
	const [content, setContent] = useState(null)
	const [modalClass, setModalClass] = useState(false)
	const dispatch = useDispatch();

	useEffect(() => dispatch(appActions.showPrompt(null)), [dispatch])
	useEffect(() => {
		if (orderInfo.length > 0) {
			setContent(orderInfo[0])
			dispatch(appActions.showModal(true))
			setModalClass(true)
		}
	}, [dispatch, orderInfo])

	const showInfo = (id) => dispatch(getOrderFromServer(id))
	const removeDataOrder = () => dispatch(actionsOrder.removeDataOrderFromStore())

	return (
		<>
			<CustomPrompt/>
			<div className={s.orders}>
				<RequiresToOrder showInfo={showInfo}/>
				<AwaitingReceipt showInfo={showInfo}/>

				<Modal modalClass={modalClass} setModalClass={setModalClass}
							 className={s.orderModal} callFn={[removeDataOrder]}>
					<ModalInfo content={content} changeName={true} closeModal={setModalClass}/>
				</Modal>
			</div>
		</>
	)
}