import * as axios from 'axios'
import { addressAPI } from './APISettings'

let instance = {}

if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
  instance = axios.create({
    baseURL: `https://${addressAPI}/api/v1/`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`
    }
  })
}

export const createInstance = (token) => {
  instance = axios.create({
    baseURL: `https://${addressAPI}/api/v1/`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}
export const getAuth = {
  login(username, password) {
    return axios.post(`https://${addressAPI}/api/v1/auth/token/`, {username, password})
  },
  refresh(refresh) {
    return axios.post(`https://${addressAPI}/api/v1/auth/token/refresh/`, {refresh})
  },
  me() {
    return instance.get('users/me/')
  },
  changeData(content) {
    return instance.post(`system/user-queries/`, content)
  },
  changePoint(userId, data) {
    return instance.patch(`users/${userId}/`, data)
  },
  passwordReset(data) {
    return axios.post(`https://${addressAPI}/api/v1/system/password-reset/`, data)
  },
  changePassword(data) {
    return axios.post(`https://${addressAPI}/api/v1/system/password-confirm/`, data)
  }
}

export const usersAPI = {
  getUsers() {
    return instance.get('users/')
      .then(response => {
        return response.data
      })
  },
  setUserData(id, formData, document) {
    const data = new FormData()
    if (document?.file) {
      data.append('passport', document?.file)
    }

    for (let key in formData) {
      if (key !== 'passport') {
        data.append(key, formData[key] ? `${formData[key]}` : ' ')
      }
    }

    return instance.patch(`users/${id}/`, data)
      .then(response => {
        return response.data
      })
  },
  createUser(formData, document) {
    const data = new FormData()
    if (document.file) {
      data.append('passport', document.file)
    }

    for (let key in formData) {
      if (key !== 'passport') {
        data.append(key, `${formData[key]}`)
      }
    }

    return instance.post('users/create/', data)
      .then(response => {
        return response.data
      })
  },
  getUsersRoleName(role) {
    return instance.get(`users/?${role}`)
      .then(response => {
        return response.data
      })
  },
  getUsersWithRole(filter) {
    return instance.get(`users/?${filter}`)
      .then(response => {
        return response.data
      })
  },
  getRoles(type) {
    return instance.get(`system/roles/?${type}`).then(response => response.data)
  }
}

export const ordersAPI = {
  getOrderCode() {
    return instance.get('orders/next-order-code/')
  },
  createOrder(values) {
    return instance.post('orders/', values)
  },
  getOrder(id) {
    return instance.get(`orders/${id}/`)
  },
  getOrders(query) {
    return instance.get(`orders/?${query ?? `?${query}`}`)
  },
  getOrdersWithFilter(filter) {
    return instance.get(`orders/?${filter}`)
  },
  getOrdersCreate(status, query) {
    let querySearch = ''

    if (status instanceof Object && !Array.isArray(status)) {
      querySearch = `&status__gt=${status.from}&status__lt=${status.to}`
    } else if (status) {
      querySearch = `&status__gt=${status}`
    }

    return instance.get(`orders/?${query ? query : ''}${querySearch}`)
  },
  getOrdersWithStatus(status) {
    return instance.get(`orders/?status__gt=${status}&limit=1000`)
  },
  changeOrder(id, data) {
    return instance.patch(`orders/${id}/`, data)
  },
  deleteOrder(id) {
    return instance.delete(`orders/${id}/`)
  },
  getDocument(id) {
    return instance.get(`orders/get-document/?order_id=${id}`)
  }
}

export const notiesAPI = {
  readNoties(ids) {
    return instance.post('system/notifications/', ids)
  },
  getNoties() {
    return instance.get('system/notifications/')
  },
  deleteNonice(id) {
    return instance.delete(`system/notifications/${id}/`)
  }
}

export const materialsAPI = {
  getMaterials(status) {
    return instance.get(`materials/?status=${status}`)
  },
  orderMaterialCreate(data) {
    return instance.post(`materials/`, data)
  },
  orderMaterials(id, data) {
    return instance.patch(`materials/${id}/`, data)
  },
  orderMaterialsType(type) {
    return instance.get(`materials/type/?type=${type}`)
  }
}

export const newsAPI = {
  getNews() {
    return instance.get('system/news/')
  }
}

export const menuAPI = {
  getMenu() {
    return instance.get('system/menu/')
  }
}

export const systemAPI = {
  getTime() {
    return instance.get('time/')
  },
  getFabricDocument(type) {
    return instance.get(`system/fabric-document/?${type}`)
  },
  sendFabricDocument(file) {
    const formData = new FormData()
    formData.append('fabric', file)
    return instance.put('system/fabric-parse/', formData)
  },
  getConsumablesDocument(type) {
    return instance.get(`system/specifications-document/?${type}`)
  },
  sendConsumablesDocument(file) {
    const formData = new FormData()
    formData.append('specifications', file)
    return instance.put('system/specifications-parse/', formData)
  },
  getProductsDocument(type) {
    return instance.get(`system/products-document/?${type}`)
  },
  getClientsBase() {
    return instance.get(`system/clients-document/`)
  },
  sendProductsDocument(file) {
    const formData = new FormData()
    formData.append('products', file)
    return instance.put('system/products-parse/', formData)
  },
  getPricesForTasks() {
    return instance.get('system/prices/table/')
      .then((response) => response.data)
  },
  setNewPriceForTask(data) {
    return instance.post('system/prices/new/', data)
  }
}

export const tasksAPI = {
  getAllTasks() {
    return instance.get(`tasks/`)
  },
  getTasks(userId) {
    return instance.get(`tasks/?user=${userId}`)
  },
  getMyTasks(my) {
    return instance.get(`tasks/?my=${my}`)
  },
  createTasks(data) {
    return instance.post('tasks/mass/', data)
  },
  getTask(id, data) {
    return instance.get(`tasks/${id}/`, data)
  },
  startTask(id, data) {
    return instance.patch(`tasks/${id}/`, data)
  },
  getReport(data) {
    return instance.get(`tasks/report/?user=${data.user}&month=${data.month}&year=${data.year}`)
      .then(response => response.data)
  },
  getReportLink(data) {
    return instance.get(`tasks/report/pdf/?user=${data.user}&month=${data.month}&year=${data.year}`)
      .then(response => response.data)
  }
}

export const goodsAPI = {
  getGood(id) {
    return instance.get(`orders/items/${id}/}`)
  },
  getGoods(query) {
    //with_materials=${withMaterials}
    const queryParam = query ? `?${query}` : ''
    return instance.get(`orders/items/${queryParam}`)
  },
  changeGood(id, data) {
    return instance.patch(`orders/items/${id}/`, data)
  }
}

export const productAPI = {
  getProducts(type) {
    return instance.get(`products/${type && `?type=${type}`}`)
  },
  getProductPrice(data) {
    return instance.get(`products/get-price/?product_id=${data.productId}&material_price=${data.materialPrice}`)
  },
  getConsumable() {
    return instance.get('products/consumable/')
  },
  setConsumable(id, data) {
    return instance.patch(`products/consumable/${id}/`, data)
  }
}

export const materialAPI = {
  getLegs() {
    return instance.get('products/legs/')
  },
  getMolding() {
    return instance.get('products/molding/')
  }
}

export const pointsAPI = {
  getWorkpoints() {
    return instance.get('workpoints/')
  }
}

export const paymentsAPI = {
  getPayments(filter) {
    return instance.get(`payments/full/?${filter}`)
      .then((response) => response.data)
  }
}
