import React from 'react';
import {Route, Switch, useRouteMatch, NavLink, Link} from 'react-router-dom';
import {PersonalData} from './personalData/PersonalData';
import {CorporateData} from './corporateData/CorporateData';
import {Account} from './account/Account';
import {DataChangeRequest} from './dataChangeRequest/DataChangeRequest';
import {tabsMenu} from '../../../configs/menuСonfiguration';
import s from './PrivateOffice.module.scss';
import {useSelector} from 'react-redux';

const PrivateOffice = () => {
  const {url} = useRouteMatch()
  const personalData = useSelector(state => state?.auth)
  const {
    phone_number, first_name, last_name, avatar, email,
    position, username, user_access, company: {name: companyName = ''} = {}
  } = personalData

  return (
    <section className="private-office">
      <div className="container">
        <h1 className={s.title}>Личный кабинет</h1>

        <div className={s.privateOffice}>
          <div className={s.privateOfficeTabs}>
            <div className={s.tabs}>
              {tabsMenu.map(tab => (
                <NavLink
                  key={tab.id}
                  to={`${url}${tab.to}`}
                  className={s.tabsItem}
                  activeClassName={s.tabsItemActive}
                >
									<span className={s.privateOfficeIcon}>
										<img src={tab.icon} alt=''/>
									</span>

                  <span className={s.privateOfficeTitle}>
                    {tab.title}
                  </span>
                </NavLink>
              ))}
            </div>
          </div>

          <div className={s.officeItem}>
            <Switch>
              <Route path={`${url}/personal-data`}>
                <PersonalData phone={phone_number}
                              firstName={first_name}
                              lastName={last_name}
                              avatar={avatar}/>
              </Route>
              <Route path={`${url}/corporate-data`}>
                <CorporateData companyName={companyName} position={position} userAccess={user_access}/>
              </Route>
              <Route path={`${url}/account`}>
                <Account email={email} username={username}/>
              </Route>
              <Route path={`${url}/data-change-request`}>
                <DataChangeRequest/>
              </Route>
            </Switch>
          </div>
        </div>

        <div className={s.otherLinks}>
          <Link to='/confidentiality'>
            <span>Конфиденциальность</span>
          </Link>
          <Link to='/terms'>
            <span>Условия</span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default PrivateOffice