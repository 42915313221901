import {tasksAPI} from "../api/api"

import {REMOVE_USER_DATA, SET_TASKS, SET_TASKS_USER, SET_USER_REPORT, SET_USER_REPORT_LINK} from "./types"

import {appActions} from "./appReducer"
import { IDocumentReport } from "../types/types"

const initialState = {
  tasks: [],
  tasksUser: [],
  userReport: [],
  userReportLink: ''
}

export const tasksReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_TASKS:
      return {...state, tasks: action.payload}

    case SET_TASKS_USER:
      return {...state, tasksUser: action.payload}

    case SET_USER_REPORT:
      return {...state, userReport: action.payload}

    case SET_USER_REPORT_LINK:
      return {...state, userReportLink: action.payload}

    case REMOVE_USER_DATA:
      return {
        ...state,
        userReport: [],
        userReportLink: ''
      }

    default:
      return state
  }
}

type SetTasksActionType = {
  type: typeof SET_TASKS
  payload: any
}

export const setTasks = (tasks: any): SetTasksActionType => ({ type: SET_TASKS, payload: tasks})
export const setTasksUser = (tasks: any) => ({ type: SET_TASKS_USER, payload: tasks})
export const setUserReport = (report: any) => ({ type: SET_USER_REPORT, payload: report})
export const setUserReportLink = (link: any) => ({ type: SET_USER_REPORT_LINK, payload: link})
export const removeUserData = () => ({ type: REMOVE_USER_DATA})

export const getTasksFromServer = () => (dispatch: any) => {
  dispatch(appActions.showLoader(true))

  return tasksAPI.getAllTasks()
    .then((response: any) => {
      dispatch(setTasksUser(response.data))
      console.log(response.data)
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getMyTasksFromServer = (my: any) => (dispatch: any) => {
  dispatch(appActions.showLoader(true))

  return tasksAPI.getMyTasks(my)
    .then((response: any) => {
      dispatch(setTasks(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const createTasks = (data: any) => (dispatch: any) => {
  dispatch(appActions.showLoader(true))

  return tasksAPI.createTasks(data)
    .then((response: any) => {
      dispatch(getMyTasksFromServer(true))
      dispatch(appActions.showModal(false))
      dispatch(appActions.showPrompt('SUCCESS', 'Данные успешно сохранены'))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const changeTask = (id: number, data: any) => (dispatch: any) => {
  dispatch(appActions.showLoader(true))

  return tasksAPI.startTask(id, data)
    .then((response: any) => {
      dispatch(getMyTasksFromServer(true))
      dispatch(appActions.showPrompt('SUCCESS', 'Данные успешно сохранены'))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getReportFromServer = (data: IDocumentReport) => (dispatch: any) => {
  dispatch(appActions.showLoader(true))

  return tasksAPI.getReport(data)
    .then((response: any) => {
      dispatch(setUserReport(response))

      if (response.length) {
        dispatch(getReportLinkFromServer({
          user: data.user,
          month: data.month,
          year: data.year
        }))
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getReportLinkFromServer = (data: IDocumentReport) => (dispatch: any) => {
  dispatch(appActions.showLoader(true))

  return tasksAPI.getReportLink(data)
    .then((response: { link: string }) => {
      dispatch(setUserReportLink(response.link))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
