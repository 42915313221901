import React, { useState } from 'react';
import { toggleClass } from '../../function/toggleClass';
import '../../../style/_drop-down-list.scss';

export const DropDownList = props => {
	const [openBody, setOpenBody] = useState(false);

	const onClick = () => setOpenBody(!openBody);

	// ---------добавление/удаление классов по условию -----------------------------------------------------
	const boddyClass = toggleClass('drop-down-list__body', 'drop-down-list__body--active', openBody);
	const arrowClass = toggleClass('drop-down-list__header--arrow', 'arrow-open', openBody);
	const HeaderClass = toggleClass('drop-down-list__header', 'drop-down-list__header-open', openBody);
	// ------------------------------------------------------------------------------------------------------

	return (
		<div className={`drop-down-list ${props.className ?? ''}`}>
			<div
				className={HeaderClass.join(' ')}
				onClick={onClick}
			>
				<div className={arrowClass.join(' ')}></div>
				<p className="drop-down-list__header--title">{props.title}</p>
			</div>
			<div className={boddyClass.join(' ')}>
				{props.children}
			</div>
		</div>
	);
};