import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'

import { classNames } from '../../../function/toggleClasses'
import { SvgImages } from '../../../generic'
import {CustomPrompt} from '../../common/customPrompt/CustomPrompt'

import {getFabricDocument, sendFabricDocument} from '../../../../redux/systemReducer'
import s from './editData.module.scss'

const EditData = () => {
  const dispatch = useDispatch()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      dispatch(sendFabricDocument(e.target.files[0]))
    }
  }
  const onClickFabricLocked = () => dispatch(getFabricDocument('locked'))
  const onClickFabric = () => dispatch(getFabricDocument(null))

  return (
    <div>
      <CustomPrompt/>

      <div>
        <div className={s.buttons}>
          <div className={classNames([s.buttonsDownload])}>

            <button
               className={classNames([s.buttonDownload, s.buttonDownloadOutlay])}
               onClick={onClickFabricLocked}>
                <span className={s.imageWrap}>
                  <SvgImages type={'download'}/>
                </span>
                Выгрузить данные для изменения расхода ткани
            </button>

            <button
              className={classNames([s.buttonDownload, s.buttonDownloadMaterial])}
              onClick={onClickFabric}>
              <span className={s.imageWrap}>
                <SvgImages type={'download'}/>
              </span>
              Выгрузить данные для операций по изменению ткани
              <span className={classNames([s.imageWrap, s.imageWrapWarning])}>
                <SvgImages type={'warning'}/>
              </span>
            </button>
          </div>


          <label className={classNames([s.buttonUpload, s.buttonUploadEdited])}>
            <input type='file'
                   accept=".xls"
                   className={s.input}
                   onChange={onChange}/>
            <span className={s.imageWrap}>
              <SvgImages type={'upload'}/>
            </span>
            Загрузить отредактированный документ
          </label>
        </div>
      </div>
    </div>
  )
}

export default EditData