import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { arraySplice, change, Field, FieldArray, formValueSelector, untouch } from 'redux-form'

import { Button } from '../../../common/Buttons/button/Button'
import { FormTable } from '../../FormTable'
import { FormInput, FormInputQuantity } from '../../FormOrderBlock/FormOrderControl'
import {
  isSelectedFromListProduct,
  maxLength,
  minLength,
  normalizeCost,
  normalizeDiscont,
  normalizePrice,
  normalizeQuantity,
  required
} from '../../../../../utils/validators/validators'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import {
  getPriceProduct,
  getProductsFromServer,
  getLegsFromServer,
  getMoldingFromServer
} from '../../../../../redux/productsReducer'
import { coincidences } from '../../../../function/searchCoincidences'
import { getGoodsFromServer } from '../../../../../redux/goodsReducer'
import { isChangeName, namesProducts } from '../../../../function/changeNameProduct'

import s from './productInformation.module.scss'

const headlines = [
  {
    name: '№',
    className: ''
  },
  {
    name: 'Наименование',
    className: ''
  },
  {
    name: 'Цена',
    className: ''
  },
  {
    name: 'Кол-во',
    className: ''
  },
  {
    name: 'Стоимость',
    className: ''
  },
  {
    name: 'Cкидка',
    className: ''
  },
  {
    name: 'Сумма со скидкой',
    className: ''
  }
]

const maxStrings = 5
const minLength3 = minLength(3)
const maxLength50 = maxLength(50)
const minLength1 = minLength(1)
const maxLength9 = maxLength(9)

const RenderInputs = ({
                        index,
                        item,
                        indexGood,
                        fieldsLength,
                        removeField,
                        onEnterGood,
                        hasOption,
                        setHasOption
                      }) => {
  const selector = formValueSelector('fieldArrays')
  const state = useSelector(state => state)
  const products = useSelector((state) => state.products?.products)
  const materialPrice1 = selector(state, `goods[${indexGood}].orderInfo[${index}].materialPrice1`) || 0
  const materialPrice2 = selector(state, `goods[${indexGood}].orderInfo[${index}].materialPrice2`) || 0
  const goodsInStock = useSelector(state => state.goods.goodsInStock)
  const isFromHall = useSelector(state => state.orders.isFormFromHall)
  const workpointId = useSelector((state) => state.auth.workpoint)
  const {legs, molding} = useSelector((state) => state.products)

  const [productsCoincidences, setProductsCoincidences] = useState([])
  const [targetValue, setTargetValue] = useState(null)
  const [search, setSearch] = useState(false)
  const [productPath, setProductPath] = useState(null)
  const [idProduct, setIdProduct] = useState(null)
  const product = selector(state, `${item}.product`)
  const price = selector(state, `${item}.price`)
  const quantity = selector(state, `${item}.quantity`)
  const costGood = selector(state, `${item}.cost`)
  const discount = selector(state, `${item}.discount`) || 0
  const sum = selector(state, `${item}.sum`)

  const productsForm = selector(state, `goods`)
  const dispatch = useDispatch()
  const ref = useRef(null)
  const productsABC = products.sort((a, b) => {
    const nameA = a.name[0].toLowerCase(),
      nameB = b.name[0].toLowerCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })

  const goodsABC = goodsInStock.sort((a, b) => {
    const nameA = a.product[0].toLowerCase(),
      nameB = b.product[0].toLowerCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
  useEffect(() => {
    if (isFromHall) {
      dispatch(getLegsFromServer())
      dispatch(getMoldingFromServer())
    }
  }, [dispatch, isFromHall])
  // расчет итого
  useEffect(() => {
    let sum = 0

    if (productsForm) {
      for (let i = 0; i < productsForm.length; i++) {
        if (productsForm[i].orderInfo) {
          for (let k = 0; k < productsForm[i].orderInfo.length; k++) {
            if (productsForm[i]?.orderInfo[k]?.items) {
              for (let j = 0; j < productsForm[i].orderInfo[0].items.length; j++) {
                if (productsForm[i].orderInfo[0].items[j].good && productsForm[i].orderInfo[0].items[j].good[0]?.sum) {
                  sum += +(productsForm[i].orderInfo[0].items[j].good[0].sum.toString().split(' ').join(''))
                }
              }
            }
          }
        }
      }
    }

    changeStore('total', normalizeCost(sum + ''))
    // eslint-disable-next-line
  }, [item, sum])
  // установить стоимость и сумму
  useEffect(() => {
    if (price) {
      changeStore(`${item}.cost`,
        normalizeCost(+(price.toString().split(' ').join('')) * +quantity + ''))
    } else {
      changeStore(`${item}.cost`, '')
    }

    if (costGood) {
      const costGoodNormalize = +(selector(state, `${item}.cost`).toString().split(' ').join(''))

      dispatch(change('fieldArrays', `${item}.sum`,
        normalizeCost(
          Math.floor(
            costGoodNormalize - ((costGoodNormalize * +discount / 100) * 100) / 100) + '')))
    } else {
      dispatch(change('fieldArrays', `${item}.sum`, normalizeCost(selector(state, `${item}.cost`) + '')))
    }
    // eslint-disable-next-line
  }, [quantity, price, discount, costGood, dispatch, item])
  useEffect(() => {
    if (index < 1) {
      changeInfoProduct()
    }
    // eslint-disable-next-line
  }, [quantity, discount])
  // устанавливаем цену для товара, когда есть его id и цена хот бы одного материала
  useEffect(() => {
    let biggestMaterialPrice

    if (index < 1) {
      if (product && idProduct !== null && (+materialPrice1 > 0 || +materialPrice2 > 0)) {
        biggestMaterialPrice = (+materialPrice1 >= +materialPrice2) ? materialPrice1 : materialPrice2

        if (+biggestMaterialPrice > 0) {
          dispatch(getPriceProduct({
            productId: idProduct,
            materialPrice: biggestMaterialPrice || 0
          }, (price) => {

            changeStore(`${productPath}.price`, normalizeCost(price + ''))
          }))
        }

        setTargetValue('')
      } else {
        changeStore(`${productPath}.price`, '')
      }
    }
    // eslint-disable-next-line
  }, [product, idProduct, materialPrice1, materialPrice2])
  useEffect(() => {
    if (isFromHall && index < 1) {
      setProductsCoincidences(coincidences(goodsABC, 'product', targetValue, goodsABC.length))
    } else {
      setProductsCoincidences(coincidences(productsABC, 'name', targetValue, productsABC.length))
    }
    // eslint-disable-next-line
  }, [targetValue, productsABC, goodsABC, isFromHall])
  useEffect(() => {
    if (product && index === 0) {
      onEnterGood(product)
    }
    if (isFromHall && !product && index === 0) {
      setHasOption(false)
      if (fieldsLength > 1) {
        dispatch(arraySplice('fieldArrays', `goods[${indexGood}].orderInfo[0].items`, 1, fieldsLength - 1), null)
      }
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].name1`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].name2`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].name3`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].provider1`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].provider2`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].provider3`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].materialPrice1`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].materialPrice2`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].materialPrice3`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].legsId`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].legs`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].moldingId`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].molding`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].tk1`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].tk2`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].commentary`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].scheme`, ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].materials_type`, 0))
      dispatch(untouch('fieldArrays'))
      dispatch(untouch('fieldArrays', `goods[${indexGood}].orderInfo[${index}].materialPrice1`))

    }
    // eslint-disable-next-line
  }, [product])

  // изменение store
  const changeStore = (to, value) => dispatch(change('fieldArrays', to, value))

  useOnClickOutside(ref, () => setSearch(false))

  const changeInfoProduct = () => {
    let cost
    let normalizeValue
    if (price) {
      cost = price * quantity
      normalizeValue = normalizeCost(cost + '')

      changeStore(`${productPath}.cost`, normalizeValue)
    }

    if (discount && cost && index < 1) {
      changeStore(`${productPath}.sum`, normalizeCost((Math.floor((cost - (cost * discount / 100)) * 100) / 100) + ''))
    } else {
      changeStore(`${productPath}.sum`, normalizeValue)
    }
  }
  const onChangeProduct = (e) => {
    if (index < 1) {
      if (isFromHall) {
        dispatch(getGoodsFromServer(`workpoint=${workpointId}&order__isnull=true`))
      } else {
        dispatch(getProductsFromServer('main'))
      }
    } else {
      if (!e.target.value) {
        dispatch(changeStore(`${item}.price`, ''))
      }
      dispatch(getProductsFromServer('optional'))
    }

    if (e.target.value !== '') {
      const target = e.target.value
      setSearch(true)
      setTargetValue(target)
      if (index === 0) {
        onEnterGood(target)
      }
      return
    }
    setSearch(false)
    if (index === 0) onEnterGood('')

  }
  const onChange = (e, field) => {
    if (e.target.value) {
      dispatch(changeStore(`${item}.${field}`, e.target.value))
    }
  }
  const onBlurGood = (e) => {
    if (isFromHall && index < 1) {
      dispatch(getGoodsFromServer(`workpoint=${workpointId}&order__isnull=true`))
      return
    }
    if (index < 1) {
      dispatch(getProductsFromServer('main'))
    } else {
      dispatch(getProductsFromServer('optional'))
    }
  }
  const onClickProduct = (to, product) => {
    const {product_entity = '', id, price, options, product: productGood, name} = product
    const value = (isFromHall && index === 0) ? productGood : name
    setProductPath(to)
    setIdProduct((isFromHall && index === 0) ? product_entity : id)
    dispatch(changeStore(`${to}.product`, value))
    dispatch(changeStore(`${to}.id`, id))
    setSearch(false)

    if (index >= 1) {
      dispatch(changeStore(`${to}.price`, product['price_before_1700'] || price))
    }
    if (index === 0) {
      onEnterGood(value)
    }

    if (isFromHall && options?.length && index === 0) {
      const isAddOptionFromProduct = isChangeName(namesProducts, productGood)
      const optionsToAdd = options.filter(option => {
        const toLower = option.name.toLowerCase()
        return toLower === 'спальное место' || toLower === 'опт спальное место'
      })

      if (isAddOptionFromProduct && optionsToAdd.length) {
        setHasOption(true)

        dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].items[1].good[0].id`, optionsToAdd[0].id))
        dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].items[1].good[0].product`, optionsToAdd[0].name))
        dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].items[1].good[0].price`, optionsToAdd[0].price))
        dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].items[1].good[0].quantity`, (+optionsToAdd[0].quantity).toFixed(0)))
        dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].items[1].good[0].cost`, optionsToAdd[0].cost))
        dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].items[1].good[0].sum`, optionsToAdd[0].sum))
      } else {
        setHasOption(false)
        dispatch(arraySplice('fieldArrays', `goods[${indexGood}].orderInfo[0].items`, 1, fieldsLength - 1), null)
      }
    }
    if (isFromHall && index === 0) {
      const legsName = legs.find(({id}) => id === product.leg)
      const moldingName = molding.find(({id}) => id === product.molding)

      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].name1`, product?.materials[0]?.name))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].name2`, product?.materials[1]?.name))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].name3`, product?.materials[2]?.name))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].provider1`, product?.materials[0]?.provider))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].provider2`, product?.materials[1]?.provider))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].provider3`, product?.materials[2]?.provider))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].legsId`, product?.leg))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].legs`, legsName?.name))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].moldingId`, product?.molding))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].molding`, moldingName?.name))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].tk1`, product?.note_tk1))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].tk2`, product?.note_tk2))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].scheme`, product?.schema))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].materials_type`, product?.materials_type))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].materialPrice1`, product?.materials[0]?.price || ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].materialPrice2`, product?.materials[1]?.price || ''))
      dispatch(changeStore(`goods[${indexGood}].orderInfo[${index}].materialPrice3`, product?.materials[2]?.price || ''))
    }
  }

  const cancelClick = (e) => e.preventDefault()

  return (
    <tr key={index} ref={ref}>
      <td>
        <p className="pNumber">{index + 1}</p>
      </td>

      <td className={s.productSearch} ref={ref}>
        <Field
          name={`${item}.product`}
          component={FormInput}
          onChange={(e) => onChangeProduct(e)}
          onBlur={(e) => onBlurGood(e)}
          validate={(index === 0)
            ? [minLength3, maxLength50, isSelectedFromListProduct, required]
            : [minLength3, maxLength50, required]
          }
          onClick={(e) => cancelClick(e)}
          disabled={(isFromHall && index === 1 && hasOption)}
        />
        {
          <div className={`${s.productSearchBar} ${search ? s.productSearchBarActive : ''}`}>
            {
              (productsCoincidences.length > 0)
                ? productsCoincidences.map((el) => {
                  return <span
                    key={el.id}
                    className={s.productName}
                    onClick={() => onClickProduct(
                      `${item}`, el
                    )}
                  >
                    {
                      (isFromHall && index === 0) ? el.product : el.name
                    }
                  </span>
                })
                : 'Совпадения не найдены'
            }
          </div>
        }
      </td>
      <td>
        <Field
          name={`${item}.price`}
          component={FormInput}
          validate={[minLength1, maxLength9, required]}
          normalize={normalizePrice}
          type="text"
          onChange={(e) => onChange(e, 'price')}
          disabled={index === 0}
        />
      </td>
      <td>
        <Field
          name={`${item}.quantity`}
          component={FormInputQuantity}
          quantity={quantity}
          className={'form-input-quantity'}
          normalize={normalizeQuantity}
          disabled={isFromHall && hasOption && index === 1}
        />
      </td>
      <td>
        <Field
          name={`${item}.cost`}
          component={FormInput}
          disabled={true}
        />
      </td>
      <td>
        <Field
          name={`${item}.discount`}
          component={FormInput}
          type="number"
          onChange={(e) => onChange(e, 'discount')}
          normalize={normalizeDiscont}
        />
      </td>
      <td>
        <Field
          name={`${item}.sum`}
          component={FormInput}
          disabled={true}
        />
      </td>

      {
        (fieldsLength > 1 && ((!hasOption && index >= 1) || (hasOption && index >= 2))) &&
        <td className="remove-order-item-string">
          <button type="button" onClick={() => removeField(index)}>x</button>
        </td>
      }
    </tr>
  )
}

const FormInputs = ({
                      fields,
                      index,
                      indexGood,
                      fieldsLength = 0,
                      removeField,
                      onEnterGood,
                      hasOption,
                      setHasOption
                    }) => {
  const product = useSelector(state => state.orders?.orderChange?.items) || []

  useEffect(() => {
    if (fields.length <= 0 && product.length <= 0) {
      fields.push({})
    }
    // eslint-disable-next-line
  }, [])


  return (
    <>
      {
        fields.map((item, idx) => (
          <RenderInputs key={idx} item={item} index={index}
                        indexGood={indexGood} fields={fields} fieldsLength={fieldsLength}
                        removeField={removeField} onEnterGood={onEnterGood}
                        hasOption={hasOption} setHasOption={setHasOption}/>
        ))
      }
    </>
  )
}

export const ProductInformation = ({fields, indexGood, onEnterGood}) => {
  // При наличии опции со спальным местом добавить его, задисейблить поле с названием и убрать удаление
  const [hasOption, setHasOption] = useState(false)

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (fields.length <= 0) fields.push({})
    // eslint-disable-next-line
  }, [fields])

  const setOption = useCallback((value) => {
    setHasOption(value)
  }, [])

  const onClick = e => {
    e.preventDefault()
    if (fields.length >= maxStrings) {
      setDisabled(false)
      return
    }
    fields.push({})
  }

  return (
    <div className={s.formItemBlock}>
      <p className={s.formItemTitle}>Информация о товаре</p>

      <FormTable headlines={headlines}>
        {
          fields.map((item, index) => (
            <FieldArray
              key={index}
              name={`${item}.good`}
              component={FormInputs}
              index={index}
              indexGood={indexGood}
              fieldsLength={fields.length}
              removeField={(id) => fields.remove(id)}
              onEnterGood={onEnterGood}
              hasOption={hasOption}
              setHasOption={setOption}
            />
          ))
        }
      </FormTable>
      <Button className="add-string__btn"
              title="Добавить строку"
              icon={true}
              onClick={onClick}
              disabled={disabled}
      />
    </div>
  )
}