import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../../../redux/rootReducer'
import { normalizePrice } from '../../../../utils/validators/validators'
import { getInitials } from '../../../function/getInitials'

import { CustomPrompt } from '../../common/customPrompt/CustomPrompt'

import { useSortDate } from '../../../hooks/useSortDate'
import { paymentType } from '../../../../configs/enums'
import { actionsOrder, getOrdersPaymentsHistory, startOrdersHistoryListening } from '../../../../redux/ordersReducer'
import { appActions } from '../../../../redux/appReducer'

import s from './history.module.scss'

const History = () => {
  const orders = useSelector((state: AppStateType) => state.orders.ordersPaymentHistory.results)
  const ordersSort = useSortDate(orders, 'finalpayment_date', 'DESC')
  const {role, workpoint} = useSelector((state: AppStateType) => state.auth)

  const [fetching, setFetching] = useState(true)
  const pageStore = useSelector((state: AppStateType) => state?.orders.pageOrdersPaymentHistory)
  const nextPage = useSelector((state: AppStateType) => state?.orders.ordersPaymentHistory.next)
  const dispatch = useDispatch()

  useEffect(() => {
    if (orders.length < 20) {
      setFetching(true)
    }
  }, [orders])

  useEffect(() => {
    dispatch(startOrdersHistoryListening())
  }, [dispatch])

  useEffect(() => {
    if (!fetching) return
    let addWorkpoint = ''
    let isAdmin = ''
    if (role !== 'Admin_prod' && role !== 'Admin' && role !== 'SalesRop') {
      addWorkpoint = `&workpoint_id=${workpoint}`
    }
    if (role === 'Admin_prod' || role === 'Admin' || role === 'SalesRop') {
      isAdmin = `&workpoint__not=6`
    }

    if (fetching && nextPage !== null && nextPage.indexOf(`&page=${pageStore + 1}`)) {
      dispatch(getOrdersPaymentsHistory(
        `status__gte=3${addWorkpoint}${isAdmin}&payment__gte=0&page=${pageStore}&finalpayment_type__isnull=false&order=-finalpayment_date,id`,
        () => {
          setFetching(false)
          dispatch(actionsOrder.setPageOrdersPaymentHistory(pageStore + 1))
          dispatch(appActions.showLoader(false))
        }
      ))
    }

    // eslint-disable-next-line
  }, [fetching])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }

  return (
    <div className={s.history}>
      <CustomPrompt/>

      <div className={s.table}>
        <div className={s.header}>
          <div>№</div>
          <div>Дата получения</div>
          <div>Окончательный платеж</div>
          <div>Форма оплаты</div>
          <div>Ответственный</div>
        </div>

        <div className={s.content}>
          {
            ordersSort.map(({
                              id,
                              code,
                              status,
                              payment,
                              finalpayment_type,
                              finalpayment_date,
                              document_link,
                              responsible_for_payment_user
                            }) => (
              <div className={s.contentRow} key={id}>
                <div className={s.contentCell}>
                  <a href={document_link ? document_link : undefined} target={'_blank'} rel="noreferrer">
                    {code}
                  </a>
                </div>
                <div className={s.contentCell}>{finalpayment_date}</div>
                <div className={s.contentCell}
                     onClick={() => console.log(code, ', id заказа: ' + id, ', status: ' + status)}
                >
                  {normalizePrice(payment.toString())} руб
                </div>
                <div className={s.contentCell}>{paymentType[finalpayment_type]}</div>
                <div className={s.contentCell}>{
                  getInitials(
                    responsible_for_payment_user?.first_name || '',
                    responsible_for_payment_user?.middle_name || '',
                    responsible_for_payment_user?.last_name || ''
                  )
                }</div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default History
