import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { getNotiesFromServer, startNotiesListening } from '../../redux/notiesReducer'
import { logout } from '../../redux/authReducer'

import { Burger } from './Burger'
import { HeaderProfile } from './HeaderProfile'
import { HeaderNotice } from './HeaderNotice'
import { Logo } from "./Logo"

import s from './header.module.scss'

const HeaderComponent = props => {
	const { toggleCollapsed, collapsed, shortName, logout } = props
	const userId = useSelector(state => state.auth.id)
	const dispatch = useDispatch()

	// получение уведомлений и открытие сокетов, если получен id юзера
	useEffect(() => {
		if (userId) {
			dispatch(getNotiesFromServer())
			dispatch(startNotiesListening())
		}
		// eslint-disable-next-line
	}, [userId])

	return (
		<header className={s.header}>
			<div className={s.header__left}>
				<Logo collapsed={collapsed}/>
				<Burger toggleCollapsed={toggleCollapsed} collapsed={collapsed}/>
			</div>

			<div className={s.header__right}>
				<HeaderNotice/>
				<HeaderProfile shortName={shortName} logout={logout}/>
			</div>
		</header>
	)
}

const mapStateToProps = (state) => ({
	shortName: `${state.auth.last_name} ${state.auth.first_name.slice(0, 1)}. ${state.auth.middle_name.slice(0, 1)}.`
})

export default React.memo(connect(mapStateToProps, {logout})(HeaderComponent))