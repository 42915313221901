import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

import s from './tabs.module.scss'

type typeTab = 'tabs' | 'subTab'

interface ITabsSublining {
  type?: typeTab
  tabs: any
}

const Tabs: React.FC<ITabsSublining> = ({type = 'subTab', tabs, children}) => {
  const {url} = useRouteMatch()

  return (
    <div className={type === 'tabs' ? s.menu : ''}>
      <div className={type === 'tabs' ? s.tabs : s.subTabs}>
        {tabs.map((tab: any) => (
          <NavLink
            key={tab.id}
            to={`${url}${tab.to}`}
            className={type === 'tabs' ? s.tabsItem : s.subTabsItem}
            activeClassName={type === 'tabs' ? s.tabsItemActive : s.subTabsItemActive}
          >
            {tab.title}
          </NavLink>
        ))}

        {children}
      </div>
    </div>
  )
}

export default Tabs