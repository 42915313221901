import React, { useEffect, useState } from 'react'

import s from '../productionOrders.module.scss'
import { appActions } from '../../../../redux/appReducer'
import {
  actionsOrder,
  changeOrder,
  getOrdersFromServer,
  startOrdersListeningArchive
} from '../../../../redux/ordersReducer'
import { useDispatch, useSelector } from 'react-redux'
import { getStatusName } from '../../../function/getStatusName'

const CurrentOrders = ({showOrder}) => {
  const orders = useSelector(state => state?.orders?.orders?.results)
  const [orderSortDate, setOrderSortDate] = useState([])
  const dispatch = useDispatch()
  // подгрузка заказов
  const [fetching, setFetching] = useState(true)
  const pageStore = useSelector((state) => state?.orders.pageOrders)
  const nextPage = useSelector((state) => state?.orders.orders.next)

  useEffect(() => {
    if (orders?.length) {
      setOrderSortDate(orders?.sort((a, b) => {
          const dateFirst = a?.created_datetime?.split('.').reverse().join(', ')
          const dateSecond = b?.created_datetime?.split('.').reverse().join(', ')

          a = new Date(dateFirst)
          b = new Date(dateSecond)
          return a > b ? -1 : a < b ? 1 : 0
        })
          .filter(item => item.status > 1)
          .sort((a, b) => a.status - b.status)
      )
    }
  }, [orders])
  useEffect(() => {
    if (orderSortDate.length < 50) {
      setFetching(true)
    }
  }, [orderSortDate])
  useEffect(() => {
    dispatch(startOrdersListeningArchive())
  }, [dispatch])
  useEffect(() => {
    if (!fetching) return

    if (fetching && nextPage !== null && nextPage.indexOf(`&page=${pageStore + 1}`)) {
      dispatch(getOrdersFromServer({from: '1', to: '7'},
        `&page=${pageStore}&substatus=null&order=status,-created_datetime`, () => {
          setFetching(false)
          dispatch(actionsOrder.setPageOrders(pageStore + 1))
          dispatch(appActions.showLoader(false))
        }))
    }

    // eslint-disable-next-line
  }, [dispatch, fetching])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
      dispatch(actionsOrder.resetStateOrders())
      setOrderSortDate([])
    }
    // eslint-disable-next-line
  }, [])

  const scrollHandler = (e) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }

  const changeStatus = (id) => {
    dispatch(changeOrder(id, {
        status: 3,
        have_tasks: true,
      }
    ))
  }

  const onLogOrder = (order) => console.log(order)

  return (
    <div className={s.tableOrder}>
      <div className={s.header}>
        <div className={s.numbers}>№</div>
        <div className={s.compiler}>Составитель</div>
        <div className={s.info}>Информация</div>
        <div className={s.status}>Статус</div>
        <div className={s.dateCreate}>Дата создания</div>
      </div>
      <div className={s.content}>
        {
          orderSortDate.length
            ? orderSortDate?.map(item => (
              <div key={item.code + item.id} className={s.contentRow}>
                <div className={s.numbers}>{item.code}</div>
                <div className={s.compiler}>
                  {
                    item?.responsible_user
                      ? `${item?.responsible_user?.last_name} ${item?.responsible_user?.first_name} ${item?.responsible_user?.middle_name}`
                      : 'Пользователь удалён'
                  }
                </div>
                <div className={s.info}>
                  <button className={s.orderView} onClick={() => showOrder(item)}>
                    Смотреть
                  </button>
                </div>
                <div className={s.status}>{getStatusName(item.status)}</div>
                <div className={s.dateCreate}
                     onClick={() => onLogOrder(item)}
                >{item.created_datetime}</div>
                <div className={s.sendToWork}>
                  {
                    (item.status === 2) &&
                    <button className={s.orderView}
                            onClick={() => changeStatus(item.id)}>
                      В работу
                    </button>
                  }
                </div>
              </div>
            ))
            : <div>Данные не загружены</div>
        }
      </div>
    </div>
  )
}

export default CurrentOrders