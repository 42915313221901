export const tabsMenu = [
	{
		to: `/personal-data`,
		icon: '../../image/profile-page/user.png',
		title: 'Личные данные',
		id: 1
	},
	{
		to: `/corporate-data`,
		icon: '../../image/profile-page/data.png',
		title: 'Корпоративные данные',
		id: 2
	},
	{
		to: `/account`,
		icon: '../../image/profile-page/account.png',
		title: 'Аккаунт',
		id: 3
	},
	{
		to: `/data-change-request`,
		icon: '../../image/profile-page/data-change.png',
		title: 'Запрос на изменение данных',
		id: 4
	},
]

export const tabsMenuProduction = [
	{
		to: `/distribution`,
		title: 'Распределение',
		id: 1
	},
	{
		to: `/control`,
		title: 'Контроль исполнения',
		id: 2
	},
	{
		to: `/employee-tasks`,
		title: 'Задачи сотрудников',
		id: 3
	},
	{
		to: `/archive-of-tasks`,
		title: 'Архив задач',
		id: 4
	},
	{
		to: `/complaints`,
		title: 'Рекламация',
		id: 5
	},
]

export const tabsMenuOrderingMaterials = [
	{
		to: `/orders`,
		title: 'Заказы',
		id: 1
	},
	{
		to: `/order-material`,
		title: 'Заказать ткань',
		id: 2
	},
	{
		to: `/production-orders`,
		title: 'Заказы производство',
		id: 3
	},
	{
		to: `/data-edit`,
		title: 'Редактирование данных',
		id: 4
	},
	{
		to: `/order-archive`,
		title: 'Архив',
		id: 5
	},
]

export const tabsMenuTasks = [
	{
		to: `/executable`,
		title: 'Выполняемые',
		active: true,
		id: 1
	},
	{
		to: `/current`,
		title: 'Текущие',
		active: true,
		id: 2
	},
	{
		to: `/completed`,
		title: 'Выполненные',
		active: true,
		id: 3
	},
]

export const tabsStorage = [
	{
		to: `/materials`,
		title: 'Материалы',
		id: 1
	},
	{
		to: `/editing-data`,
		title: 'Редактирование данных',
		id: 2
	},
]

export const tabsAdministration = [
	{
		to: `/goods-in-stock`,
		title: 'Товары в наличии',
		id: 1
	},
	{
		to: `/sellers`,
		title: 'Продавцы',
		id: 2
	},
	{
		to: `/changeAssortment`,
		title: 'Изменение ассортимента',
		id: 3
	},
]

export const tabsAdminPanel = [
	{
		to: `/production`,
		title: 'Производство',
		id: 1
	},
	{
		to: `/performers`,
		title: 'Исполнители',
		id: 2
	},
	{
		to: `/tariffs`,
		title: 'Расценки',
		id: 3
	},
	{
		to: `/archive`,
		title: 'Архив',
		id: 4
	},
]

export const tabsAdminPanelProduction = [
	{
		to: `/performers`,
		title: 'Исполнители',
		id: 1
	},
	{
		to: `/upholsterers`,
		title: 'Обивщики',
		id: 2
	},
]

export const tabsFinalPayment = [
	{
		to: `/final-payment`,
		title: 'Окончательный платёж',
		id: 1
	},
	{
		to: `/history`,
		title: 'История',
		id: 2
	},
]

export const tabsAdminPanelPerformers = [
	{
		to: `/performers`,
		title: 'Исполнители',
		id: 1
	},
	{
		to: `/statistic`,
		title: 'Статистика',
		id: 2
	},
]

export const tabsOrderProductionPanel = [
	{
		to: `/orders`,
		title: 'Текущие заказы',
		id: 1
	},
	{
		to: `/archive`,
		title: 'Архив',
		id: 2
	},
]