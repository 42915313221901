import React from 'react';

import { toggleClass } from '../function/toggleClass';

import s from './header.module.scss';

export const Burger = props => {
	const { toggleCollapsed, collapsed } = props;

	const burgerClass = toggleClass(s.header__burger, s.header__burger_rotate, collapsed);
	
	return (
		<div className={burgerClass.join(' ')}>
			<img src="../../image/header/Бургер.svg" alt="burger" onClick={toggleCollapsed}/>
		</div>
	);
};