import React, { useEffect } from 'react'
import { NavLink, Redirect, Route, useRouteMatch } from 'react-router-dom'

import Production from './Production/Production'
import Performers from './Performers/Performers'
import Tariffs from './Tariffs/Tariffs'

import { tabsAdminPanel } from '../../../configs/menuСonfiguration'

import s from './adminPanel.module.scss'
import Archive from './Archive/Archive'
import { getUsersWithFilter } from '../../../redux/usersReducer'
import { startOrdersListeningForDashboard } from '../../../redux/ordersReducer'
import { useDispatch } from 'react-redux'

const AdminPanel = () => {
  const { url } = useRouteMatch()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUsersWithFilter('role__type=worker&status=active'))
    dispatch(startOrdersListeningForDashboard())
  }, [dispatch])

  return (
    <div>
      <div className="container">
        <h1 className={ s.adminPanelTitle }>Управление производством</h1>

        <div className={ s.adminPanelContent }>
          <div className={ s.menu }>
            <div className={ s.tabs }>
              { tabsAdminPanel.map(tab => (
                <NavLink
                  key={ tab.id }
                  to={ `${ url }${ tab.to }` }
                  className={ s.tabsItem }
                  activeClassName={ s.tabsItemActive }
                >
                  { tab.title }
                </NavLink>
              )) }
            </div>
          </div>

          <div>
            <Redirect to={ `${ url }/production` }/>

            <Route path={ `${ url }/production` }>
              <Production/>
            </Route>
            <Route path={ `${ url }/performers` }>
              <Performers/>
            </Route>
            <Route path={ `${ url }/tariffs` }>
              <Tariffs/>
            </Route>
            <Route path={ `${ url }/archive` }>
              <Archive/>
            </Route>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminPanel