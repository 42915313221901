import React, { useState } from 'react'
import { stopSubmit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'

import { CustomPrompt } from '../content/common/customPrompt/CustomPrompt'
import LoginReduxForm from './loginForm/LoginForm'
import Restore from './restoreDataForm/RestoreDataForm'

import { authActions, login, passwordChange, restorePassword } from '../../redux/authReducer'

import s from './Login.module.scss'

/* 1. если нужно убирать капчу после проверки расскоментировать код. Удалить строку 'dispatch(setRecaptcha(false))'
в редьюсере 'authReducer', внутри функции logout
*/

const SignIn = ({setRestorePass}) => {
  const recaptcha = useSelector(state => state.auth.recaptcha)
  const [isVerified, setIsVerified] = useState(false)
  const dispatch = useDispatch()

  const verifyCallback = (response) => {
    if (response) {
      localStorage.removeItem('count')
      setIsVerified(false)
      // 1
      // dispatch(setRecaptcha(false))
    }
  }

  const onSubmit = (formData) => {
    if (+localStorage.getItem('count') >= 4) {
      dispatch(authActions.setRecaptcha(true))
      if (recaptcha) setIsVerified(true)
      return
    }

    setIsVerified(false)
    dispatch(login(formData.login, formData.password, formData.rememberMe))
  }

  return (
    <div>
      <h2 className={s.modalTitle}>Авторизация</h2>

      <LoginReduxForm onSubmit={onSubmit}>
        {recaptcha && <div className={s.recaptcha}>
          <Recaptcha
            sitekey="6LdwhRAbAAAAAOI9d4GA8zgTtP-e6d0eAgxSgjL2"
            render="explicit"
            verifyCallback={verifyCallback}
          />
        </div>
        }

        {isVerified && <div className={s.recaptchaError}>Подтвердите что вы не робот</div>}

      </LoginReduxForm>

      <button className={s.restoreBtn} onClick={setRestorePass}>Забыли пароль?</button>
    </div>
  )
}

const Login = ({location}) => {
  const isAuth = useSelector(state => state.auth.isAuth)
  const menu = useSelector(state => state.app?.menu)
  const [restorePass, setRestorePass] = useState(false)
  const [secretKey, setSecretKey] = useState('')
  const dispatch = useDispatch()
  const search = location.search
  const menuLink = menu[menu.length - 1]?.to

  if (!secretKey && search.slice(1, 11) === 'secret_key' && search.slice(12)) {
    setSecretKey(location.search.slice(12))
  }

  if (isAuth && menuLink) {
    return <Redirect to={menuLink}/>
  }

  const onRestorePass = (formdata) => {
    if (!secretKey) {
      dispatch(restorePassword({username: formdata.email}))
    } else {
      if (formdata['password_1'] !== formdata['password_2']) {
        dispatch(stopSubmit('restore', {_error: 'Пароли должны совпадать'}))
      } else {
        dispatch(passwordChange({
          password1: formdata['password_1'],
          password2: formdata['password_2'],
          secret_key: formdata['secret_key'],
        }))
      }
    }
  }

  const onClickAuthorize = () => {
    setSecretKey('')
    setRestorePass(false)
    dispatch(authActions.setRestorePassword(false, ''))
  }

  return (
    <>
      <CustomPrompt/>

      <div className={s.authorize}>
        <div className={s.modal}>
          <div className={s.logo}>
            <img src="../../image/login/logo.svg" alt="Agenda"/>
          </div>
          {
            !restorePass && !secretKey
              ? <SignIn className={s.authorizeForm} setRestorePass={() => setRestorePass(true)}/>
              : <Restore onSubmit={onRestorePass}
                         onClickAuthorize={onClickAuthorize}
                         secretKey={secretKey}/>
          }
        </div>
      </div>
    </>
  )
}

export default Login