import React, {useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { deleteNotice, readNoties } from '../../redux/notiesReducer'

import { toggleClass } from '../function/toggleClass'
import useOnClickOutside from '../hooks/useOnClickOutside'

import s from './header.module.scss'

export const HeaderNotice = () => {
	const noties = useSelector(state => state.noties.noties)
	const [openDrop, setOpenDrop] = useState(false)
	const [warn, setWarn] = useState(false)
	const ref = useRef(null)
	const dispatch = useDispatch()
	let dropDownClass = toggleClass(s.noticeDropdown, s.noticeDropdownOpen, openDrop)

	useEffect(() => {
		if (Boolean(noties.find(item => !item.is_read))) {
			setWarn(true)
		}
	},[noties])

	const openDropDown = () => {
		let ids = noties.reduce((acc, item) => {
			acc.push(item.id)
			return acc
		}, [])

		dispatch(readNoties({ids}))
		setOpenDrop(true)
		setWarn(false)
	}

	const deleteOneNotice = id => dispatch(deleteNotice(id))

	const handleClickOutside = () => setOpenDrop(false)
	useOnClickOutside(ref, handleClickOutside)

	return (
		<div className={s.header__notice} >
			<div className={s.header__menuImg}>
				<img src="../../image/header/bell.svg" alt="bell"
					onClick={openDropDown}
				/>
			</div>

			{
				warn &&	<div className={s.header__notice_is_read}></div>
			}

			<div className={dropDownClass.join(' ')} ref={ref}>
				<p className={s.noticeDropdown_title}>Уведомления</p>

				<ul >
					{
						!noties.length &&
							<li><p>У Вас нет уведомлений...</p></li>
					}
					
					{noties.map(item => (
						<li key={item.id}>
							{
								item.type === 1 
									? <img src="../../image/header/check.png" alt="check"/> :
								item.type === 3
									? <img src="../../image/header/screamer.png" alt="screamer"/>
									: <img src="../../image/header/screamer.png" alt="screamer"/>
							}
								{item.text}
							<img
								className={s.cross}
								src="../../image/header/cross.png"
								alt="cross"
								onClick={() => deleteOneNotice(item.id)}
							/>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}