import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, formValueSelector } from 'redux-form'

import {createField, Input, SelectInput, Textarea} from '../../../common/FormsControl'

import {maxLength, required} from '../../../../../utils/validators/validators'
import {getMaterialsTypeFromServer} from '../../../../../redux/materialsReducer'

import s from './commentary.module.scss'

const maxLength300 = maxLength(300)

const Commentary = ({ item }) => {
  const selector = formValueSelector('fieldArrays')
  const state = useSelector(state => state)
  const {materials_type = 0} = selector(state, item)
  const isFromHall = useSelector(state => state.orders.isFormFromHall)
  const dispatch = useDispatch()
  const ref = useRef()

  const setMaterialsType = (types, val) => {
    dispatch(change('fieldArrays', `${item}.tk1`, types[0]))
    dispatch(change('fieldArrays', `${item}.tk2`, types[1]))
    dispatch(change('fieldArrays', `${item}.materials_type`, +val))
  }

  const onChange = (e) => {
    if (e.target.value) {
      dispatch(getMaterialsTypeFromServer(e.target.value, setMaterialsType))
      return
    }
    dispatch(change('fieldArrays', `${item}.tk1`, ''))
    dispatch(change('fieldArrays', `${item}.tk2`, ''))
    dispatch(change('fieldArrays', `${item}.materials_type`, null))
  }

  return (
    <div className={s.commentary}>

      <div className={s.commentaryWrap}>
        <h2 className={s.commentaryTitle}>Примечание</h2>

        <div className={s.varinate}>
          <div className={s.varinateTitle}>Выберите вариант исполнения</div>

          {createField('', `${item}.materials_type`, [required], SelectInput, {
            className: s.select,
            classWrap: s.selectWrap,
            onChange: (e) => onChange(e),
            ref: ref,
            selectedNum: materials_type,
            disabled: isFromHall
          })}
        </div>
      </div>

      <div>
        <div className={s.commentaryInput}>
          <div className={s.commentaryName}>ТК1</div>
          {createField('', `${item}.tk1`, [required], Input,
            {
              classWrap: `${s.commentaryInputField}`,
              disabled: true
            },'')}
        </div>
        <div className={s.commentaryInput}>
          <div className={s.commentaryName}>ТК2</div>
          {createField('', `${item}.tk2`, [], Input,
            {
              classWrap: `${s.commentaryInputField}`,
              disabled: true
            },'')}
        </div>
        <div className={s.commentaryInput}>
          {createField('', `${item}.commentary`, [maxLength300], Textarea,
            {
              classWrap: s.commentaryTextareaFieldWrap,
              className: s.commentaryTextareaField,
            },
            '')}
        </div>
      </div>
    </div>
  )
}

export default Commentary