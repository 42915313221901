import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Orders } from './orders/Orders'
import { ProductionOrders } from './ProductionOrders/ProductionOrders'
import PrivateOffice from './privateOffice/PrivateOffice'
import { OrderingMaterials } from './orderingMaterials/OrderingMaterials'
import Confidentiality from '../content/confidentiality/Confidentiality'
import Terms from './terms/Terms'
import { ErrorPage } from './ErrorPage/ErrorPage'
import Storage from './Storage/Storage'
import Administration from './Administration/Administration'
import AdminPanel from './AdminPanel/AdminPanel'
import OrderDates from './orderDates/OrderDates'
import FinalPayment from './FinalPayment/FinalPayment'

// сюда класть страницы исключения (без "/")
const exclusionPages = [
  'private-office',
  'confidentiality',
  'terms',
  'storage',
  'administration',
  'order-dates'
]

export const ContentComponent = () => {
  const menu = useSelector(state => state.app.menu)
  const location = useLocation()
  const [showErrorPage, setShowErrorPage] = useState(false)

  useEffect(() => {
    if (!menu.length) return

    const pathname = location.pathname.split('/')[1]

    const temp = menu?.reduce((acc, item) => {
      if (item.to.split('/')[1] === pathname || exclusionPages.includes(pathname)) {
        return acc = 1
      }
      return acc
    }, 0)

    if (!temp) setShowErrorPage(true)
    else setShowErrorPage(false)
  }, [location, menu])

  return (
    <div className="content">
      {showErrorPage
        ?
        <ErrorPage/>
        :
        <Switch>
          <Route path="/order-creation">
            <Orders/>
          </Route>

          <Route path="/ordering-materials">
            <OrderingMaterials/>
          </Route>

          <Route path="/production-orders">
            <ProductionOrders/>
          </Route>

          <Route path="/private-office">
            <PrivateOffice/>
          </Route>

          <Route path="/confidentiality">
            <Confidentiality/>
          </Route>

          <Route path="/terms">
            <Terms/>
          </Route>

          <Route path="/storage">
            <Storage/>
          </Route>

          <Route path="/administration">
            <Administration/>
          </Route>

          <Route path="/admin-panel">
            <AdminPanel/>
          </Route>

          <Route path="/order-dates">
            <OrderDates/>
          </Route>

          <Route path="/final-payment">
            <FinalPayment/>
          </Route>
        </Switch>
      }
    </div>
  )
}