import React, {useState} from 'react'

import {createField, Input} from '../../content/common/FormsControl'

import {maxLength, minLength, passSymbols, required} from '../../../utils/validators/validators'

import s from '../Login.module.scss'

const minLength6 = minLength(6)
const maxLength32 = maxLength(32)

const ChangePassword = () => {
  const [pass1, setPass1] = useState(false)
  const [pass2, setPass2] = useState(false)

  const onShowPass = (field) => {
    if (field === 'pass1') setPass1(() => !pass1)
    if (field === 'pass2') setPass2(() => !pass2)
  }

  return (
    <>
      <div className={s.emailTitle}>Введите новый пароль:</div>
      <div className={s.inputWrap}>
        {createField('Введите новый пароль', 'password_1',
          [required, passSymbols, minLength6, maxLength32], Input,
          {
            type: !pass1 ? 'password' : 'text',
            className: s.fieldPass
          })}
        <span onClick={(span) => onShowPass('pass1')} className={`${s.passwordControl} ${!pass1 ? s.view : ''}`}></span>
      </div>

      <div className={s.emailTitle}>Повторите пароль:</div>
      <div className={s.inputWrap}>
        {createField('Введите новый пароль', 'password_2',
          [required, passSymbols, minLength6, maxLength32], Input,
          {
            type: !pass2 ? 'password' : 'text',
            className: s.fieldPass
          })}
        <span onClick={() => onShowPass('pass2')} className={`${s.passwordControl} ${!pass2 ? s.view : ''}`}></span>
      </div>

      {createField('', 'secret_key', [], Input, {type: 'hidden'})}
    </>
  )
}

export default ChangePassword