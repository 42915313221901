import React, { useEffect, useState } from 'react'
import { Modal } from '../../../common/modal/Modal'
import ModalInfo, { IContent } from '../../../ProductionOrders/ModalInfo/ModalInfo'
import { actionsOrder } from '../../../../../redux/ordersReducer'
import { useDispatch, useSelector } from 'react-redux'
import { appActions } from '../../../../../redux/appReducer'
import { AppStateType } from '../../../../../redux/rootReducer'

const OrderView = () => {
  const [content, setContent] = useState<IContent>({
    id: null,
    production_date_from: null,
    production_date_to: null,
    delivery_date_from: null,
    delivery_date_to: null,
    document_link: undefined,
    code: '',
    items: [],
  })
  const [modalClass, setModalClass] = useState(false)
  const orderInfo = useSelector((state: AppStateType) => state?.orders?.orderInfo)
  const dispatch = useDispatch()

  useEffect(() => {
    if (orderInfo.length > 0) {
      setContent(orderInfo[0])
      dispatch(appActions.showModal(true))
      setModalClass(true)
    }
  }, [dispatch, orderInfo])

  const removeDataOrder = () => dispatch(actionsOrder.removeDataOrderFromStore())

  return (
    <Modal modalClass={modalClass}
           setModalClass={setModalClass}
      // className={s.orderModal}
           styleProps={{width: '648px'}}
           callFn={[removeDataOrder]}
           isEdit={false}>
      <ModalInfo content={content}
                 changeName={true}
                 closeModal={setModalClass}
                 isLink={false}/>
    </Modal>
  )
}

export default OrderView