import React, {useEffect, useState} from "react";
import s from "./good.module.scss";
import {SvgImages} from "../../../generic";
import {FieldArray} from "redux-form";
import RenderOrder from "./RenderOrder";

const RenderGoodInfo = ({indexGood, item, fieldsLength, onDeleteGood, toggleActiveGood, activeGood}) => {
    const [goodName, setGoodName] = useState('')

    useEffect(() => (fieldsLength === 1) && toggleActiveGood(0))

    const onEnterGood = (name) => {
        setGoodName(name)
    }
    const unfurlGood = () => {
        if (activeGood === null || activeGood !== indexGood) {
            toggleActiveGood(indexGood)
            return
        }

        if (activeGood === indexGood) {
            toggleActiveGood(null)
        }
    }

    return (
        <div className={`${s.good}`}>
            <div className={s.goodHeader}>
            <span className={`${s.goodName} ${(activeGood === indexGood) ? s.goodNameActive : ''}`}
                  onClick={unfurlGood}>
              {goodName}
            </span>
                {
                    fieldsLength > 1 &&
                    <span className={s.deleteGood}
                          onClick={() => onDeleteGood(indexGood)}>
            <SvgImages type={'basket'}/>
          </span>
                }
            </div>

            <div className={`${s.goods} ${(activeGood !== indexGood) ? s.goodHide : ''}`}>
                <FieldArray
                    name={`${item}.orderInfo`}
                    onEnterGood={onEnterGood}
                    component={RenderOrder}
                    indexGood={indexGood}
                />
            </div>
        </div>
    )
}

export default RenderGoodInfo