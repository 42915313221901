import React, {useEffect, useRef, useState} from 'react'

import s from './legsMolding.module.scss'
import {getLegsFromServer, getMoldingFromServer} from '../../../../../redux/productsReducer'
import {useDispatch, useSelector} from 'react-redux'
import {change, Field} from 'redux-form'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import {FormInput} from '../../FormOrderBlock/FormOrderControl'
import {createField, Input} from '../../../common/FormsControl'
import {isSelectedLegsMoldings} from '../../../../../utils/validators/validators'

const LegsMolding = ({ item }) => {
  const [searchLegs, setSearchLegs] = useState(false)
  const [searchMolding, setSearchMolding] = useState(false)
  const isFromHall = useSelector(state => state.orders.isFormFromHall)
  const {legs, molding} = useSelector((state) => state.products)
  const dispatch = useDispatch()
  const refLegs = useRef(null)
  const refMolding = useRef(null)

  useEffect(() => {
    dispatch(change('fieldArrays', `${item}.legs-material`, "Ножки"))
    dispatch(change('fieldArrays', `${item}.molding-material`, "Молдинг"))
    // eslint-disable-next-line
  }, [])

  const changeStore = (to, value) => dispatch(change('fieldArrays', to, value))
  const handleClickOutside = (val) => {
    if (val === 'legs') setSearchLegs(false)
    if (val === 'molding') setSearchMolding(false)
  }
  useOnClickOutside(refLegs, () => handleClickOutside('legs'))
  useOnClickOutside(refMolding, () => handleClickOutside('molding'))

  const onFocusLegs = () => {
    setSearchLegs(true)
    dispatch(getLegsFromServer())
  }
  const onClickLegs = (to, value, id) => {
    setSearchLegs(false)
    dispatch(changeStore(`${to}.legs`, value))
    dispatch(changeStore(`${to}.legsId`, id))
  }

  const onFocusMolding = () => {
    setSearchMolding(true)
    dispatch(getMoldingFromServer())
  }
  const onClickMolding = (to, value, id) => {
    setSearchMolding(false)
    dispatch(changeStore(`${to}.molding`, value))
    dispatch(changeStore(`${to}.moldingId`, id))
  }

  const isLegsSelected = isSelectedLegsMoldings(legs)
  const isMoldingSelected = isSelectedLegsMoldings(molding)

  return (
    <div className={s.blockLegsMoldings}>
      <h2 className={s.title}>Ножки и молдинг</h2>

      <div className={s.blockInputs}>
        <div className={s.blockForm}>
          <span className={`${s.formHeader} ${s.formHeaderMaterial}`}>Материал</span>
          <span className={`${s.formHeader} ${s.formHeaderName}`}>Наименование</span>
        </div>

        <div className={s.blockForm}>
          <Field name={`${item}.legs-material`}
                 component={FormInput}
                 disabled={true}
                 className={s.inputFormName}
          />
          <div className={s.wrapInput} ref={refLegs}>
            <Field name={`${item}.legs`}
                   component={FormInput}
                   className={s.inputFormName}
                   onFocus={(e) => onFocusLegs(e)}
                   validate={[isLegsSelected]}
                   disabled={isFromHall}
            />
            {createField('', `${item}.legsId`, [],
              Input, {type: 'hidden'})}
            <div className={`${s.productSearchBar} ${searchLegs ? s.productSearchBarActive : ''}`}>
              {
                (legs.length > 0)
                  ? legs.map((el) => {
                    return <span key={el.id}
                     className={s.materialName}
                     onClick={() => onClickLegs(`${item}`, el.name, el.id)}>{el.name}</span>
                    })
                  : 'Ничего не найдено'
              }
            </div>
          </div>
        </div>

        <div className={s.blockForm}>
          {/*<span className={s.inputFormName}>Молдинг</span>*/}
          <Field name={`${item}.molding-material`} component={FormInput}
                 disabled={true} className={s.inputFormName}/>
          <div className={s.wrapInput} ref={refMolding}>
            <Field name={`${item}.molding`}
                   component={FormInput}
                   className={s.inputFormName}
                   onFocus={(e) => onFocusMolding(e)}
                   validate={[isMoldingSelected]}
                   disabled={isFromHall}
            />
            {createField('', `${item}.moldingId`, [],
              Input, {type: 'hidden'})}
            <div className={`${s.productSearchBar} ${searchMolding ? s.productSearchBarActive : ''}`}>
              {
                (molding.length > 0)
                  ? molding.map((el) => {
                    return <span key={el.id}
                                 className={s.materialName}
                                 onClick={() => onClickMolding(`${item}`, el.name, el.id)}>{el.name}</span>
                  })
                  : 'Ничего не найдено'
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegsMolding