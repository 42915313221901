import React from 'react'

const SvgImages = (props: React.SVGProps<SVGSVGElement>) => {

  switch (props.type) {
    case 'basket':
      return (
        <svg width="14" height="18" viewBox="0 0 14 18" fill="red" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
                fill="black"/>
          <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
                fill="white"/>
        </svg>
      )
    case 'download':
      return (
        <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 9.17647H15V0H6V9.17647H0L10.5 19.8824L21 9.17647ZM0 22.9412V26H21V22.9412H0Z" fill="white"/>
        </svg>
      )
    case 'upload':
      return (
        <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.625 22H16.375V12.25H22.875L11.5 0.875L0.125 12.25H6.625V22ZM0.125 25.25H22.875V28.5H0.125V25.25Z"
                fill="white"/>
        </svg>
      )
    case 'warning':
      return (
        <svg width="42" height="37" viewBox="0 0 42 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 7.61727L35.3755 32.4545H6.62455L21 7.61727ZM21 0L0 36.2727H42L21 0Z" fill="#FF0000"/>
          <path d="M22.909 26.7271H19.0908V30.5452H22.909V26.7271Z" fill="#FF0000"/>
          <path d="M22.909 15.2729H19.0908V24.8184H22.909V15.2729Z" fill="#FF0000"/>
        </svg>
      )
    case 'save':
      return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.8333 0.25H2.83333C1.26083 0.25 0 1.525 0 3.08333V22.9167C0 24.475 1.26083 25.75 2.83333 25.75H22.6667C24.225 25.75 25.5 24.475 25.5 22.9167V5.91667L19.8333 0.25ZM12.75 22.9167C10.3983 22.9167 8.5 21.0183 8.5 18.6667C8.5 16.315 10.3983 14.4167 12.75 14.4167C15.1017 14.4167 17 16.315 17 18.6667C17 21.0183 15.1017 22.9167 12.75 22.9167ZM17 8.75H2.83333V3.08333H17V8.75Z"
            fill="white"/>
        </svg>
      )
    case 'status':
      return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.8722 0H0.533658C0.123042 0 -0.133383 0.447487 0.0727626 0.804472L3.9711 7.59891V11.4637C3.9711 11.7604 4.20909 12 4.50406 12H8.90184C9.19681 12 9.4348 11.7604 9.4348 11.4637V7.59891L13.3348 0.804472C13.5393 0.447487 13.2829 0 12.8722 0ZM8.2348 10.7933H5.1711V8.31287H8.23647V10.7933H8.2348ZM8.39569 6.99555L8.25491 7.24025H5.14931L5.00853 6.99555L3.7264 4.75979H9.67949L8.39569 6.99555ZM10.2946 3.68716H3.11132L1.68673 1.20671H11.7192L10.2946 3.68716Z"
            fill="#8A94A2"/>
        </svg>
      )
    default:
      return (
        <span>Image</span>
      )
  }
}

export default SvgImages