import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'

import { getUsersRoleName, setUserDataOnServer } from '../../../../redux/usersReducer'
import { getWorkpointsFromServer } from '../../../../redux/workpointsReducer'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { appActions } from '../../../../redux/appReducer'

import { CustomPrompt } from '../../common/customPrompt/CustomPrompt'

import { AppStateType } from '../../../../redux/rootReducer'

import { IUser } from '../../../../types/types'

import s from './sellers.module.scss'

interface IWorkpointDropDown {
  user: IUser;
  index: number;
}

const WorkpointDropDown: React.FC<IWorkpointDropDown> = ({user, index}) => {
  const workpoints = useSelector((state: AppStateType) => state?.points?.points)
  const [isShowPoints, setIsShowPoints] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  useOnClickOutside(ref, () => setIsShowPoints(false))

  const showPoints = () => {
    setIsShowPoints(true)
  }
  const setPoint = (idPoint: number) => {
    dispatch(setUserDataOnServer(user.id, {workpoint: idPoint}, null, () => {
        setIsShowPoints(false)
        dispatch(getUsersRoleName('role__name__or=Sales,SalesRop'))
        dispatch(appActions.showPrompt('SUCCESS', 'Торговая точка успешно изменена'))
      })
    )
  }

  if (!workpoints) return null

  return (
    <div className={s.row}>
      <div className={s.contentCell}>{index + 1}</div>
      <div className={s.contentCell}>{`${user?.first_name} ${user?.last_name} ${user?.middle_name}`}</div>
      <div className={`${s.contentCell} ${s.dropdownCell}`} onClick={showPoints} ref={ref}>
        {workpoints.find(item => item.id === user.workpoint)?.address}

        {
          isShowPoints &&
          <div className={s.points}>
            {
              workpoints.map((item) => (
                <span key={item.id} className={s.pointItem} onClick={() => setPoint(item.id)}>
                  {item.address}
                </span>
              ))
            }
          </div>
        }
      </div>
    </div>
  )
}

const Sellers = () => {
  const users: Array<IUser> = useSelector((state: AppStateType) => state.users?.usersSellers)
  const dispatch = useDispatch()

  // Получение точек с сервера
  useEffect(() => {
    dispatch(getWorkpointsFromServer())
  }, [dispatch])

  useEffect(() => {
    dispatch(getUsersRoleName('role__name__or=Sales,SalesRop'))
  }, [dispatch])

  return (
    <div>
      <CustomPrompt/>
      <div className={s.table}>
        <div className={s.header}>
          <div className={s.headerCell}>№</div>
          <div className={s.headerCell}>ФИО</div>
          <div className={s.headerCell}>Торговая точка</div>
        </div>

        <div className={s.content}>
          {
            users.map((user, index) => (
              <WorkpointDropDown key={index} user={user} index={index}/>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Sellers