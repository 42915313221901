import React, { useState } from 'react'
import { reduxForm } from 'redux-form'

import { createField, Input } from '../../content/common/FormsControl'

import { loginSymbols, maxLength, minLength, passSymbols, required } from '../../../utils/validators/validators'

import s from '../Login.module.scss'

const minLength4 = minLength(4)
const minLength6 = minLength(6)
const maxLength32 = maxLength(32)

const LoginForm = ({handleSubmit, error, children}) => {
  const [isShowPassword, setIsShowPassword] = useState(false)
  const classNameEye = `${s.passwordControl} ${isShowPassword ? s.view : ''}`

  const setViewPassword = (e) => {
    e.preventDefault()
    setIsShowPassword(value => !value)
  }

  return (
    <form onSubmit={handleSubmit} className={s.form}>

      <div className={s.input}>
        {createField('Логин', 'login',
          [required, minLength4, maxLength32, loginSymbols], Input,
          {})}
      </div>

      <div className={s.input}>
        {createField('Пароль', 'password',
          [required, minLength6, maxLength32, passSymbols], Input,
          {type: !isShowPassword ? 'password' : 'text'})}
        <button onClick={setViewPassword} className={classNameEye}></button>
      </div>

      {error && <div className={s.wrongData}>{error}</div>}

      <label className={s.modalCheckbox}>
        {createField('', 'rememberMe', [], Input, {type: 'checkbox'}, 'Запомнить пароль')}
      </label>

      {children}

      <button type='submit' className='btn btn--gradient'>Войти</button>
    </form>
  )
}

const LoginReduxForm = reduxForm({form: 'login'})(LoginForm)

export default LoginReduxForm