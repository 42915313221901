// проверка договора на наличие опций и смена имени
export const namesProducts = ['Д/О', 'ОПТ Д/О']
export const optionsToChange = ['Спальное место', 'ОПТ Спальное место']

// проверяем есть ли в массиве имен подстрока - true/false
export const isChangeName = (namesArr, name) => {
  return namesArr.some(item => name?.toLowerCase().search(item?.toLowerCase()) >= 0)
}

// проверяем есть ли у массива опций опции, из-за которых нужно изменить название товара
export const isOptionsToChange = (namesOptions, options) => {
  return options.some(el => {
    return namesOptions.some(item => {
      const pos = el?.name?.toLowerCase().search(item?.toLowerCase())
      return pos >= 0
    })
  })
}