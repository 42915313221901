import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getConsumablesDocument, sendConsumablesDocument, setRemoveUploadError } from '../../../../redux/systemReducer'
import { CustomPrompt } from '../../common/customPrompt/CustomPrompt'
import { SvgImages } from '../../../generic'
import { classNames } from '../../../function/toggleClasses'

import s from './editData.module.scss'
import { Modal } from '../../common/modal/Modal'
import { AppStateType } from '../../../../redux/rootReducer'
import { appActions } from '../../../../redux/appReducer'

const EditData = () => {
  const fails = useSelector((state: AppStateType) => state.system.fails)
  const [modalClass, setModalClass] = useState(false)
  const ref = useRef<any>()
  const dispatch = useDispatch()

  useEffect(() => {
    if (fails.length) {
      setModalClass(true)
    }
  }, [fails])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      dispatch(sendConsumablesDocument(e.target.files[0]))

      ref.current.value = ''
    }
  }
  const onClickConsumablesLocked = () => dispatch(getConsumablesDocument('locked'))
  const onClickConsumables = () => dispatch(getConsumablesDocument(''))

  return (
    <div>
      <CustomPrompt/>

      <div>
        <div className={s.buttons}>
          <div className={classNames([s.buttonsDownload])}>

            <button
              className={classNames([s.buttonDownload, s.buttonDownloadOutlay])}
              onClick={onClickConsumablesLocked}>
            <span className={s.imageWrap}>
            <SvgImages type={'download'}/>
            </span>
              Выгрузить данные для изменения расхода материала
            </button>

            <button
              className={classNames([s.buttonDownload, s.buttonDownloadMaterial])}
              onClick={onClickConsumables}>
              <span className={s.imageWrap}>
              <SvgImages type={'download'}/>
              </span>
              Выгрузить данные для операций по изменению спецификаций
              <span className={classNames([s.imageWrap, s.imageWrapWarning])}>
               <SvgImages type={'warning'}/>
              </span>
            </button>
          </div>


          <label className={classNames([s.buttonUpload, s.buttonUploadEdited])}>
            <input type="file"
                   accept=".xls"
                   className={s.input}
                   onChange={onChange}
                   ref={ref}/>
            <span className={s.imageWrap}>
              <SvgImages type={'upload'}/>
            </span>
            Загрузить отредактированный документ
          </label>
        </div>
      </div>

      <Modal callFn={[() => dispatch(setRemoveUploadError())]}
             isEdit={false}
             modalClass={modalClass}
             setModalClass={setModalClass}
      >
        <div className={s.modal}>
          <div className={s.closeModal} onClick={() => {
            dispatch(appActions.showModal(false))
            dispatch(setRemoveUploadError())
          }}
          >X
          </div>

          <div className={s.modalTitle}>Ошибка загрузки документа!</div>

          <div>
            {
              fails.map((item, index) => <div key={index}>{item}</div>)
            }
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default EditData