import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {getPricesForTasks} from '../../../../redux/systemReducer'

import {CustomPrompt} from '../../common/customPrompt/CustomPrompt'
import CellWithPrice from './CellWithPrice/CellWithPrice'

import s from './tariffs.module.scss'

const Tariffs = () => {
  const tableData = useSelector((state) => state.system.tableData)
  const [isChanging, setIsChanging] = useState(false)
  const [roles, setRoles] = useState([])
  const dispatch = useDispatch()

  // убираем ОТК из списка и делаем массив с обивщиком Д.о и Д.к для таблицы
  useEffect(() => {
    if (tableData?.roles?.length) {

      const rolesFilter = tableData.roles.reduce((acc, item) => {
        if (item.name === 'Обивщик') {
          acc.push(item, {...item, is_dk: true})
          return acc
        }
        acc.push(item)

        return acc
      }, [])

      setRoles([...rolesFilter])
    }
  }, [tableData])

  useEffect(() => {
    dispatch(getPricesForTasks())
  }, [dispatch])

  const setChangingTariffs = () => {
    setIsChanging((state) => !state)
    dispatch(getPricesForTasks())
  }

  return (
    <div>
      <CustomPrompt/>

      <div className={s.btns}>
        <button className={s.btnChange} onClick={setChangingTariffs}>
          {
            !isChanging ? 'Изменить' : 'Сохранить'
          }
        </button>
      </div>

      <div className={s.table}>
        <div className={s.header}>
          <span className={`${s.headerItem} ${s.headerName}`}>Наименование</span>
          {
            roles.map((item, index) => (
              <span key={index} className={s.headerItem}>
                {
                  item.name === 'Обивщик' && item.is_dk
                    ? `${item.name} Д.к.`
                    : item.name === 'Обивщик' && !item.is_dk
                    ? `${item.name} Д.о.`
                    : item.name
                }
              </span>
            ))
          }
        </div>

        <div>
          {
            tableData?.products?.map((item) => (
              <div key={item.id} className={s.contentRow}>
                <span className={s.contentItem}>{item?.name}</span>
                {
                  roles.map((itemRole, index) => (
                    <CellWithPrice key={index}
                                   isChanging={isChanging}
                                   idProduct={item.id}
                                   productName={item?.name}
                                   isDk={itemRole?.is_dk}
                                   prices={item.prices}
                                   itemRole={itemRole}
                    />
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Tariffs