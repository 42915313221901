import React, { useEffect } from 'react'
import {Field} from 'redux-form'

import s from './FormControl.module.scss'

const FormControl = ({meta: {touched, error}, children, classWrap}) => {
  const hasError = touched && error

  return (
    <div className={`${s.field} ${classWrap ? classWrap : ''} ${hasError ? `${s.fieldError}` : ''}`}>
      {children}
      {hasError && <span className={s.error}>{error}</span>}
    </div>
  )
}

export const Textarea = (props) => {
  const {input, meta, child, classWrap, ...restProps} = props

  return <FormControl classWrap={classWrap} {...props}><textarea {...input} {...restProps} /></FormControl>
}

export const Input = (props) => {
  const {input, meta, child, classWrap, ...restProps} = props
  return <FormControl classWrap={classWrap} {...props}><input {...input} {...restProps} /></FormControl>
}

export const LabelInput = (props) => {
  const {id, input, meta, child, classLabel, content, input: {name, value, onChange}, classWrap, ...restProps} = props

  return (
    <FormControl {...props} {...restProps}>
      <input id={id} {...restProps} value={value} name={name} onChange={onChange}/>
      <label className={classLabel} htmlFor={id}>{content}</label>
    </FormControl>
  )
}
export const SelectInput = (props) => {
  const {input: {onChange}, classWrap, selectedNum, ...restProps} = props

  return (
    <FormControl {...props} className={classWrap} >
      <select onChange={(e) => onChange(e)} value={selectedNum} {...restProps}>
        <option value=""></option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
    </FormControl>
  )
}

export const FormDayPickerInput = props => {
	const { input, disabled=false, className='', setHasError, meta: { touched, error } } = props
	
	const hasError = touched && error

	useEffect(() => {
		setHasError({show: hasError, error: error})
		// eslint-disable-next-line
	}, [hasError])

	return (
		<div className={`${className}`}>
			<input  {...input} disabled={disabled}/>
		</div>
	)
}

export const createField = (placeholder, name, validators, component, props = {}, text = '') => {
  return (
    <>
      <Field name={name}
             component={component}
             validate={validators}
             placeholder={placeholder}
             {...props}/> {text}
    </>
  )
}