import { useCallback, useEffect, useRef, useState } from 'react'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../../../redux/rootReducer'
import { getGoodsFromServer } from '../../../../redux/goodsReducer'
import { getWorkpointsFromServer } from '../../../../redux/workpointsReducer'
import filterIcon from '../../../../assets/icons/filter.svg'

import { GoodsType } from '../../../../types/types'
import { startOrdersListening } from '../../../../redux/ordersReducer'
import { appActions } from '../../../../redux/appReducer'

import s from './goodsInStock.module.scss'
import Row from '../Row/Row'
import { useSortDate } from '../../../hooks/useSortDate'
import { getLegsFromServer, getMoldingFromServer } from '../../../../redux/productsReducer'
import { getLegsMoldings } from '../../../function/getLegsMoldings'

const GoodsInStock = () => {
  const {goodsInStock} = useSelector((state: AppStateType) => state.goods)
  const {points} = useSelector((state: AppStateType) => state.points)
  const {legs: legsStore, molding: moldingStore} = useSelector((state: AppStateType) => state.products)
  const [isListPoints, setIsListPoints] = useState(false)
  const [filterPoint, setFilterPoint] = useState('Фильтр по торговой точке')
  const [filterGoods, setFilterGoods] = useState<GoodsType[]>([])
  const dispatch = useDispatch()

  const [filters, setFilters] = useState([
    {
      id: 1000,
      prefix: 'Все торговые точки'
    },
    {
      id: 1001,
      prefix: 'Без торговой точки'
    }
  ])
  useEffect(() => {
    dispatch(startOrdersListening('6'))
    dispatch(getLegsFromServer())
    dispatch(getMoldingFromServer())
  }, [dispatch])
  useEffect(() => {
    dispatch(getWorkpointsFromServer())
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    // order__workpoint - id торговой точки "В зал", 6
    // order__status - статус, когда товары уже все готовы
    // reusefull - чтобы отделить товары, которые могут быть повторно отправлены в заказ, true
    dispatch(getGoodsFromServer(`order__isnull=true&limit=10000`))
    dispatch(appActions.showLoader(true))
    // eslint-disable-next-line
  }, [dispatch])
  useEffect(() => {
    setFilters(state => [...state, ...points])
    // eslint-disable-next-line
  }, [dispatch])
  useEffect(() => {
    const filterPointLowerCase = filterPoint.toLowerCase()
    if (
      filterPointLowerCase === 'фильтр по торговой точке'
      || filterPointLowerCase === 'все торговые точки'
      || filterPointLowerCase === ''
    ) {
      setFilterGoods(goodsInStock)
    } else if (filterPointLowerCase === 'без торговой точки') {
      setFilterGoods(goodsInStock
        .filter(good => (!good.workpoint) ? good : false))
    } else {
      setFilterGoods(goodsInStock
        .filter(good => (good.workpoint?.prefix.toLowerCase() === filterPointLowerCase) ? good : false))
    }
  }, [goodsInStock, filterPoint])

  const sortGoods = useSortDate(filterGoods, `production_date`, 'DESC')

  const showPoint = useCallback(() => {
    setIsListPoints(value => !value)
  }, [])
  const setPoint = (e: any) => {
    setFilterPoint(e.target.textContent)
    setIsListPoints(false)
  }

  const refFilterPoint = useRef(null)
  useOnClickOutside(refFilterPoint, () => setIsListPoints(false))

  return (
    <div>
      <div className={s.buttons}>
        <div className={s.filter} ref={refFilterPoint}>
          <button className={s.buttonFilter}
                  onClick={showPoint}
                  style={{background: isListPoints ? '#DCEDE9' : ''}}
          >
            <img className={s.filterIcon} src={filterIcon} alt=""/>
            <div>{filterPoint}</div>
          </button>

          {
            points && isListPoints &&
            <ul className={s.listPoints}>
              {
                filters.map(({id, prefix}) => <li key={id} onClick={setPoint}>{prefix}</li>)
              }
            </ul>
          }
        </div>
      </div>

      <div className={s.table}>
        <div className={s.headers}>
          <div>Название товара</div>
          <div>Дата изготовления</div>
          <div>Торговая точка</div>
        </div>
        <div className={s.content}>
          {
            sortGoods.length
              ? sortGoods.map(({
                                 id,
                                 product,
                                 production_date,
                                 workpoint,
                                 options,
                                 leg,
                                 molding,
                                 materials,
                                 note_tk1,
                                 note_tk2,
                                 schema
                               }) => (
                <Row
                  key={id}
                  id={id}
                  product={product}
                  production_date={production_date}
                  workpoint={workpoint}
                  options={options}
                  setIsListPoints={setIsListPoints}
                  leg={getLegsMoldings(legsStore, leg)}
                  molding={getLegsMoldings(moldingStore, molding)}
                  materials={materials}
                  note_tk1={note_tk1}
                  note_tk2={note_tk2}
                  schema={schema}
                />
              ))
              : <p className={s.emptyGoods}>По запросу "{filterPoint}" ничего не найдено</p>
          }
        </div>

      </div>
    </div>
  )
}

export default GoodsInStock