import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'

import PerformersInfo from './PerformersInfo/PerformersInfo'
import Tabs from '../../common/Tabs/Tabs'
import { CustomPrompt } from '../../common/customPrompt/CustomPrompt'
import Statistics from './Statistic/Statistics'
import { CreateUserReduxForm } from './CreateUser/CreateUser'

import { tabsAdminPanelPerformers } from '../../../../configs/menuСonfiguration'
import { createUserToServer } from '../../../../redux/usersReducer'
import { getPayments } from '../../../../redux/paymentsReducer'

import { AppStateType } from '../../../../redux/rootReducer'

import s from './performers.module.scss'
import arrowTop from './../../../../assets/icons/arrow-top.svg'
import { Loader } from '../../common/loader/Loader'

const filterItems = [
  {
    id: 1,
    name: 'Все',
    status: '',
    statusClass: s.all
  },
  {
    id: 2,
    name: 'Активные',
    status: 'active',
    statusClass: s.active
  },
  {
    id: 3,
    name: 'Заблокированные',
    status: 'inactive',
    statusClass: s.inactive
  },
  {
    id: 4,
    name: 'Удаленные',
    status: 'deleted',
    statusClass: s.deleted
  }
]

const Performers: React.FC = () => {
  const users = useSelector((state: AppStateType) => state?.payments.payments)
  const [createUserModal, setCreateUserModal] = useState(false)
  const [roleId, setRoleId] = useState(null)
  const [showFilterForUser, setShowFilterForUser] = useState(false)
  const [activeId, setActiveId] = useState(1)
  const [filterUsers, setFilterUsers] = useState([])
  const [document, setDocument] = useState({file: null})
  const {url} = useRouteMatch()
  const dispatch = useDispatch()

  // запрос на пользователей с нужной ролью
  useEffect(() => {
    dispatch(getPayments('role__type=worker'))
  }, [dispatch])

  // сгенерировать массив юзеров
  useEffect(() => {
    setFilterUsers(users)
  }, [users])

  // Закрытие/открытие модалки для создания пользователя
  const closeModal = () => setCreateUserModal(false)
  const openModalCreateUser = () => setCreateUserModal(true)

  // запрос на создание пользователя
  const createUser = (formData: any) => {
    const num = Math.random() * (999999 - 111111) + 111111

    dispatch(createUserToServer({
        ...formData,
        role: roleId,
        username: `${num.toFixed()}`,
        password: `${num.toFixed()}`,
      },
      document,
      () => {
        dispatch(getPayments('role__type=worker'))
        setCreateUserModal(false)
      }))
  }

  // показать фильтр для юзеров
  const setFilter = () => {
    setShowFilterForUser((state) => !state)
  }

  const setActiveItem = (id: number, status: string) => {
    setActiveId(id)
    setShowFilterForUser(false)
    if (status) {
      setFilterUsers(users.filter((item: any) => item.status === status))
    } else {
      setFilterUsers(users)
    }
  }

  return (
    <div className={s.performers}>

      <Loader/>
      <CustomPrompt/>

      <div className={s.nav}>
        <div className={s.tabsWrap}>
          <Tabs type={'subTab'} tabs={tabsAdminPanelPerformers}>
            <div className={s.filterWrap}>
              <div className={s.filter} onClick={setFilter}>
                <img src={arrowTop} className={`${s.arrowTop} ${showFilterForUser ? s.active : ''}`} alt=""/>
                <img src={arrowTop} className={`${s.arrowBottom} ${showFilterForUser ? s.active : ''}`} alt=""/>
              </div>

              {
                showFilterForUser &&
                <div className={s.filterList}>
                  {
                    filterItems.map((item) => (
                      <span key={item.id}
                            className={`${s.filterItem} ${item.id === activeId ? s.activeFilter : ''}`}
                            onClick={() => setActiveItem(item.id, item.status)}>
                        <span className={item.statusClass}> </span>
                        {item.name}
                    </span>
                    ))
                  }
                </div>
              }
            </div>
          </Tabs>


        </div>

        <button className={s.btnAddUser} onClick={openModalCreateUser}>
          Добавить исполнителя
        </button>
      </div>

      <Redirect to={`${url}/performers`}/>

      <Route exact path={`${url}/performers`}>
        <PerformersInfo users={filterUsers}/>
      </Route>
      <Route exact path={`${url}/statistic`}>
        <Statistics users={filterUsers}/>
      </Route>

      {
        createUserModal &&
        <div className={s.modal}>
          <CreateUserReduxForm onSubmit={createUser}
                               closeModal={closeModal}
                               setRoleId={setRoleId}
                               setDocument={setDocument}/>
        </div>
      }
    </div>
  )
}

export default Performers