import React from 'react'
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Payment from './Payment/Payment'
import History from './History/History'

import { tabsFinalPayment } from '../../../configs/menuСonfiguration'

import s from './finalPayment.module.scss'

const FinalPayment = () => {
  const {url} = useRouteMatch()

  return (
    <div className="container">
      <h1 className={s.paymentTitle}>Окончательный платёж</h1>

      <div className={s.paymentContent}>
        <div className={s.menu}>
          <div className={s.tabs}>
            {tabsFinalPayment.map(tab => (
              <NavLink
                key={tab.id}
                to={`${url}${tab.to}`}
                className={s.tabsItem}
                activeClassName={s.tabsItemActive}
              >
                {tab.title}
              </NavLink>
            ))}
          </div>
        </div>

        <div>
          <Redirect to={`${url}/final-payment`}/>

          <Switch>
            <Route path={`${url}/final-payment`}>
              <Payment/>
            </Route>
            <Route path={`${url}/history`}>
              <History/>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default FinalPayment