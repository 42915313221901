import { ordersAPI, tasksAPI } from '../api/api'
import { appActions } from './appReducer'
import { GoodType, OrderType, Upsholster } from '../types/types'
import { downloader } from '../utils/downloader/downloader'
import { wsAPI } from '../api/ws-api'
import { Dispatch } from 'redux'
import { InferActionsTypes } from './rootReducer'

const initialState = {
  ordersCreate: {
    count: 0,
    next: '',
    previous: '',
    results: [] as Array<OrderType>
  },
  orders: {
    count: 0,
    next: '',
    previous: '',
    results: [] as Array<OrderType>
  },
  ordersProductionArchive: {
    count: 0,
    next: '',
    previous: '',
    results: [] as Array<OrderType>
  },
  ordersPayment: {
    count: 0,
    next: '',
    previous: '',
    results: [] as Array<OrderType>
  },
  ordersPaymentHistory: {
    count: 0,
    next: '',
    previous: '',
    results: [] as Array<OrderType>
  },
  ordersDashboard: {
    count: 0,
    next: '',
    previous: '',
    results: [] as Array<OrderType>
  },
  ordersDashboardArchive: {
    count: 0 as number | null,
    next: '' as string | null,
    previous: '' as string | null,
    results: [] as Array<OrderType>
  },
  orderDates: {
    count: 0,
    next: '',
    previous: '',
    results: [] as Array<OrderType>
  },
  orderChange: {},
  orderInfo: [] as any,
  goodsArr: [
    {
      id: 1
    }
  ] as Array<GoodType>,
  orderCode: '',
  link: '',
  isDownload: false,
  pageOrdersCreate: 1,
  pageOrders: 1,
  pageOrdersProductionArchive: 1,
  pageDashboard: 1,
  pageDashboardArchive: 1,
  pageOrdersPaymentHistory: 1,
  isFormFromHall: false
}

export type InitialStateType = typeof initialState

// ищем совпадения договоров и возвращаем массив без повторений
const checkSameItem = (currArr: Array<Upsholster>) => {
  const result = []
  const ordersObj: any = {}

  currArr.forEach((item) => {
    ordersObj[item.id] = item
  })

  for (let key in ordersObj) {
    if (ordersObj.hasOwnProperty(key)) {
      result.push(ordersObj[key])
    }
  }
  return result
}

// обновляем договор, если статус >= 6(завершенный), то вырезаем его иначе обновляем новыми данными
const updateCompleteOrder = (arr: any, order: any) => {
  const index = arr.findIndex((item: any) => item.id === order.id)

  if (order.status >= 6 && index >= 0) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)]
  }

  return (index === -1) ? [...arr, order] : [...arr.map((item: any) => item.id === order.id ? order : item)]
}

export const ordersReducer = (state = initialState, action: InferActionsTypes<typeof actionsOrder>): InitialStateType => {
  switch (action.type) {
    case 'SET_ORDER_CODE':
      return {...state, orderCode: action.orderCode}

    case 'GET_ORDER':
      return {...state, orderInfo: [action.payload]}

    case 'REMOVE_ORDER':
      return {...state, orderInfo: []}

    case 'SET_ORDERS':
      return {
        ...state,
        orders: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...(checkSameItem([...state.orders.results, ...action.payload.results]))]
        }
      }

    case 'SET_ORDERS_FOR_PRODUCTION_ARCHIVE':
      return {
        ...state,
        ordersProductionArchive: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...(checkSameItem([...state.ordersProductionArchive.results, ...action.payload.results]))]
        }
      }

    case 'SET_ORDERS_PAYMENT_HISTORY':
      return {
        ...state,
        ordersPaymentHistory: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...(checkSameItem([...state.ordersPaymentHistory.results, ...action.payload.results]))]
        }
      }

    case 'SET_ORDERS_PAYMENT':
      return {
        ...state,
        ordersPayment: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...(checkSameItem([...state.ordersPayment.results, ...action.payload.results]))]
        }
      }

    case 'SET_UPDATE_ORDER':
      return {
        ...state,
        orders: {
          ...state.orders,
          results: ((state.orders.count === state.orders.results.length) || action.order.status === 2)
            ? [...(checkSameItem([...state.orders.results, action.order]))]
            : [...state.orders.results.map(item => item.id === action.order.id ? {...action.order} : item)]
        }
      }

    case 'SET_UPDATE_ORDER_PAYMENT':
      return {
        ...state,
        ordersPayment: {
          ...state.ordersPayment,
          results: ((state.ordersPayment.count === state.ordersPayment.results.length) || action.order.status === 2)
            ? [...(checkSameItem([...state.ordersPayment.results, action.order]))]
            : [...state.ordersPayment.results.map(item => item.id === action.order.id ? {...action.order} : item)]
        }
      }

    case 'SET_ORDER_DATES':
      return {
        ...state,
        orderDates: {
          ...state.orders,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: action.payload.results
        }
      }

    case 'SET_UPDATE_ORDER_DATES':
      return {
        ...state,
        orderDates: {
          ...state.orderDates,
          results: [...updateCompleteOrder(state.orderDates.results, action.order)]
        }
      }

    case 'SET_ORDERS_CREATE_JOIN':
      return {
        ...state,
        ordersCreate: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: (action.payload.next === state.ordersCreate.next)
            ? [...state.ordersCreate.results]
            : [...(checkSameItem([...state.ordersCreate.results, ...action.payload.results]))]
        }
      }

    case 'SET_ORDERS_CREATE':
      return {
        ...state,
        ordersCreate: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...action.payload.results]
        }
      }

    case 'SET_UPDATE_ORDERS_CREATE':
      return {
        ...state,
        ordersCreate: {
          ...state.ordersCreate,
          results: [...(checkSameItem([...state.ordersCreate.results, action.order]))]
        }
      }

    case 'SET_UPDATE_ORDER_HISTORY':
      return {
        ...state,
        ordersPaymentHistory: {
          ...state.ordersPaymentHistory,
          results: [...(checkSameItem([...state.ordersPaymentHistory.results, action.order]))]
        }
      }

    case 'SET_ORDERS_DASHBOARD':
      return {
        ...state,
        ordersDashboard: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: (action.payload.next === state.ordersDashboard.next)
            ? [...state.ordersDashboard.results]
            : [...(checkSameItem([...state.ordersDashboard.results, ...action.payload.results]))]
        }
      }

    case 'SET_UPDATE_ORDERS_DASHBOARD':
      return {
        ...state,
        ordersDashboard: {
          ...state.ordersDashboard,
          results: (state.ordersDashboard.count > state.ordersDashboard.results.length)
            ? state.ordersDashboard.results.map(item => item.id === action.order.id ? action.order : item)
            : [...(checkSameItem([...state.ordersDashboard.results, action.order]))]
        }
      }

    case 'SET_CHANGED_ORDERS':
      return {
        ...state,
        ordersDashboard: {
          ...state.ordersDashboard,
          results: state.ordersDashboard.results.map((order) => {
            return order.id === action.payload.id ? ({...order, ...action.payload}) : ({...order})
          })
        }
      }

    case 'SET_ORDERS_DASHBOARD_ARCHIVE':
      return {
        ...state,
        ordersDashboardArchive: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: (action.payload.next === state.ordersDashboardArchive.next)
            ? [...state.ordersDashboardArchive.results]
            : [...(checkSameItem([...state.ordersDashboardArchive.results, ...action.payload.results]))]
        }
      }

    case 'SET_UPDATE_ORDERS_DASHBOARD_ARCHIVE':
      return {
        ...state,
        ordersDashboardArchive: {
          ...state.ordersDashboardArchive,
          results: (state.ordersDashboardArchive.count && state.ordersDashboardArchive.count > state.ordersDashboardArchive.results.length)
            ? state.ordersDashboardArchive.results.map(item => item.id === action.order.id ? action.order : item)
            : [...(checkSameItem([...state.ordersDashboardArchive.results, action.order]))]
        }
      }

    case 'SET_RESET_ORDERS_DASHBOARD_ARCHIVE':
      return {
        ...state,
        ordersDashboardArchive: {
          count: null,
          next: '',
          previous: '',
          results: []
        }
      }

    case 'SET_DOCUMENT_LINK':
      return {
        ...state,
        link: action.link,
        isDownload: action.isDownload
      }

    case 'SET_PAGE_DASHBOARD':
      return {
        ...state,
        pageDashboard: action.page
      }

    case 'SET_PAGE_DASHBOARD_ARCHIVE':
      return {
        ...state,
        pageDashboardArchive: action.page
      }

    case 'SET_PAGE_ORDERS':
      return {
        ...state,
        pageOrders: action.page
      }
    case 'SET_PAGE_PRODUCTION_ARCHIVE':
      return {
        ...state,
        pageOrdersProductionArchive: action.page
      }

    case 'SET_PAGE_ORDERS_CREATE':
      return {
        ...state,
        pageOrdersCreate: action.page
      }

    case 'SET_PAGE_ORDERS_PAYMENT_HISTORY':
      return {
        ...state,
        pageOrdersPaymentHistory: action.page
      }

    case 'SET_RESET_STATE':
      return {
        ordersCreate: {
          count: 0,
          next: '',
          previous: '',
          results: [] as Array<OrderType>
        },
        orders: {
          count: 0,
          next: '',
          previous: '',
          results: [] as Array<OrderType>
        },
        ordersProductionArchive: {
          count: 0,
          next: '',
          previous: '',
          results: [] as Array<OrderType>
        },
        ordersPayment: {
          count: 0,
          next: '',
          previous: '',
          results: [] as Array<OrderType>
        },
        ordersPaymentHistory: {
          count: 0,
          next: '',
          previous: '',
          results: [] as Array<OrderType>
        },
        ordersDashboard: {
          count: 0,
          next: '',
          previous: '',
          results: [] as Array<OrderType>
        },
        ordersDashboardArchive: {
          count: 0,
          next: '',
          previous: '',
          results: [] as Array<OrderType>
        },
        orderDates: {
          count: 0,
          next: '',
          previous: '',
          results: [] as Array<OrderType>
        },
        orderChange: {},
        orderInfo: [] as any,
        goodsArr: [
          {
            id: 1
          }
        ] as Array<GoodType>,
        orderCode: '',
        link: '',
        isDownload: false,
        pageOrdersCreate: 1,
        pageOrders: 1,
        pageOrdersProductionArchive: 1,
        pageDashboard: 1,
        pageDashboardArchive: 1,
        pageOrdersPaymentHistory: 1,
        isFormFromHall: false
      }

    case 'SET_FORM_FROM_HALL':
      return {
        ...state,
        isFormFromHall: action.payload
      }

    default:
      return state
  }
}

export const actionsOrder = {
  setPageOrders: (page: number) => ({type: 'SET_PAGE_ORDERS', page} as const),
  setPageOrdersPaymentHistory: (page: number) => ({type: 'SET_PAGE_ORDERS_PAYMENT_HISTORY', page} as const),
  getOrder: (orders: any) => ({type: 'GET_ORDER', payload: orders} as const),
  removeDataOrderFromStore: () => ({type: 'REMOVE_ORDER'} as const),

  setOrdersCreate: (orders: any) => ({type: 'SET_ORDERS_CREATE', payload: orders} as const),
  setOrdersCreateJoin: (orders: any) => ({type: 'SET_ORDERS_CREATE_JOIN', payload: orders} as const),
  setUpdateOrdersCreate: (order: any) => ({type: 'SET_UPDATE_ORDERS_CREATE', order} as const),
  setPageOrdersCreate: (page: number) => ({type: 'SET_PAGE_ORDERS_CREATE', page} as const),

  setUpdateOrderHistory: (order: any) => ({type: 'SET_UPDATE_ORDER_HISTORY', order} as const),
  getOrders: (orders: any) => ({type: 'SET_ORDERS', payload: orders} as const),
  getOrdersForProductionArchive: (orders: any) => ({
    type: 'SET_ORDERS_FOR_PRODUCTION_ARCHIVE',
    payload: orders
  } as const),
  setOrdersPaymentsHistory: (orders: any) => ({type: 'SET_ORDERS_PAYMENT_HISTORY', payload: orders} as const),
  setOrdersPayments: (orders: any) => ({type: 'SET_ORDERS_PAYMENT', payload: orders} as const),
  setUpdateOrder: (order: any) => ({type: 'SET_UPDATE_ORDER', order} as const),
  setUpdateOrderPayment: (order: any) => ({type: 'SET_UPDATE_ORDER_PAYMENT', order} as const),
  setOrderDates: (payload: any) => ({type: 'SET_ORDER_DATES', payload} as const),
  setUpdateOrderDates: (order: any) => ({type: 'SET_UPDATE_ORDER_DATES', order} as const),

  setOrdersDashboard: (orders: any) => ({type: 'SET_ORDERS_DASHBOARD', payload: orders} as const),
  setUpdateOrderDashboard: (order: any) => ({type: 'SET_UPDATE_ORDERS_DASHBOARD', order} as const),
  setChangedOrders: (order: OrderType) => ({type: 'SET_CHANGED_ORDERS', payload: order} as const),
  setPage: (page: number) => ({type: 'SET_PAGE_DASHBOARD', page} as const),
  setPageProductionArchive: (page: number) => ({type: 'SET_PAGE_PRODUCTION_ARCHIVE', page} as const),

  setOrdersDashboardArchive: (orders: any) => ({type: 'SET_ORDERS_DASHBOARD_ARCHIVE', payload: orders} as const),
  setUpdateOrderDashboardArchive: (order: any) => ({type: 'SET_UPDATE_ORDERS_DASHBOARD_ARCHIVE', order} as const),
  setPageDashboardArchive: (page: number) => ({type: 'SET_PAGE_DASHBOARD_ARCHIVE', page} as const),
  setResetOrdersDashboardArchive: () => ({type: 'SET_RESET_ORDERS_DASHBOARD_ARCHIVE'} as const),

  setOrderCode: (orderCode: string) => ({type: 'SET_ORDER_CODE', orderCode: orderCode} as const),
  setDocumentLink: (link: string, isDownload: boolean) => ({
    type: 'SET_DOCUMENT_LINK',
    link: link,
    isDownload: isDownload
  } as const),
  setFormFromHall: (payload: boolean) => ({type: 'SET_FORM_FROM_HALL', payload} as const),
  resetStateOrders: () => ({type: 'SET_RESET_STATE'} as const),
}

export const getOrderCode = () => (dispatch: any) => {
  return ordersAPI.getOrderCode()
    .then((response: any) => {
      dispatch(actionsOrder.setOrderCode(response.data.code))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}
export const createOrderInServer = (values: any) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))
  return ordersAPI.createOrder(values)
    .then((response: any) => {
      dispatch(appActions.showModal(false))
      dispatch(appActions.showPrompt('SUCCESS', 'Договор успешно сохранен'))
      dispatch(getDocument(response.data.id))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const changeOrder = (id: number | null, data: any, cb?: Function | null) => (dispatch: any) => {
  dispatch(appActions.showLoader(true))
  return ordersAPI.changeOrder(id, data)
    .then((response: any) => {
      // dispatch(getOrdersFromServerWithStatus(1))
      dispatch(appActions.showModal(false))
      dispatch(appActions.showPrompt('SUCCESS', 'Данные успешно сохранены'))

      if (cb) {
        cb(response.data.status, response.data.document_link)
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const changeOrderStatus = (id: number, data: any) => (dispatch: any) => {
  dispatch(appActions.showLoader(true))
  return ordersAPI.changeOrder(id, data)
    .then((response: any) => {
      dispatch(actionsOrder.setChangedOrders(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getDocument = (id: number) => (dispatch: Function) => {
  return ordersAPI.getDocument(id)
    .then((response: any) => {
      dispatch(actionsOrder.setDocumentLink(response.data.link, true))
      dispatch(appActions.showPrompt('SUCCESS', 'Подготовка договора к скачиванию...'))
      // return response.data.link
      downloader(response.data.link, '_target')
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getOrderFromServer = (id: number) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))
  return ordersAPI.getOrder(id)
    .then((response: any) => {
      dispatch(actionsOrder.getOrder(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getUpdateOrderFromServer = (id: number) => (dispatch: Function) => {
  return ordersAPI.getOrder(id)
    .then((response: any) => {
      dispatch(actionsOrder.setUpdateOrder(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети 2`))
    })
}
export const getUpdateOrderPymentFromServer = (id: number) => (dispatch: Function) => {
  return ordersAPI.getOrder(id)
    .then((response: any) => {
      dispatch(actionsOrder.setUpdateOrderPayment(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети 2`))
    })
}
export const getOrderDatesFromServer = (filter: string) => (dispatch: Function) => {
  return ordersAPI.getOrdersWithFilter(filter)
    .then((response: any) => {
      dispatch(actionsOrder.setOrderDates(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети 2`))
    })
}
export const getUpdateOrderDatesFromServer = (id: number) => (dispatch: Function) => {
  return ordersAPI.getOrder(id)
    .then((response: any) => {
      dispatch(actionsOrder.setUpdateOrderDates(response.data))
    })
    .catch((error: any) => {
      console.log(error)
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}
export const getOrdersCreateFromServerJoin = (status: string, query: string, cb?: () => void) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrdersCreate(status, query)
    .then((response: any) => {
      dispatch(actionsOrder.setOrdersCreateJoin(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => {
      dispatch(appActions.showLoader(false))
      if (cb) {
        cb()
      } else {
        dispatch(appActions.showLoader(false))
      }
    })
}
export const getOrdersCreateFromServer = (query: string, cb?: () => void) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrdersCreate('', query)
    .then((response: any) => {
      dispatch(actionsOrder.setOrdersCreate(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => {
      dispatch(appActions.showLoader(false))
      if (cb) {
        cb()
      } else {
        dispatch(appActions.showLoader(false))
      }
    })
}
export const getUpdateOrdersCreateFromServer = (id: number) => (dispatch: Function, getState: any) => {
  return ordersAPI.getOrder(id)
    .then((response: any) => {
      const workpoints = getState().points.points
      const workpointId = getState().auth.workpoint
      const userRole = getState().auth.role
      const workpointName = workpoints.filter((item: any) => item.id === workpointId)[0]?.prefix

      if (userRole.toLowerCase() === 'admin' || workpointName === response.data.code.split('-')[0]) {
        dispatch(actionsOrder.setUpdateOrdersCreate(response.data))
      }
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}
export const getUpdateHistoryFromServer = (id: number) => (dispatch: Function) => {
  return ordersAPI.getOrder(id)
    .then((response: any) => {
      dispatch(actionsOrder.setUpdateOrderHistory(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
}
export const getOrdersFromServer = (status: string | { from: string, to: string }, query: string, cb?: () => void) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrdersCreate(status, query)
    .then((response: any) => {
      dispatch(actionsOrder.getOrders(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => {
      if (cb) {
        cb()
      } else {
        dispatch(appActions.showLoader(false))
      }
    })
}
export const getOrdersFromServerForProductionArchive = (status: string, query: string, cb?: () => void) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrdersCreate(status, query)
    .then((response: any) => {
      dispatch(actionsOrder.getOrdersForProductionArchive(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => {
      if (cb) {
        cb()
      } else {
        dispatch(appActions.showLoader(false))
      }
    })
}
export const getOrdersPaymentsFromServer = (status: string, query: string, cb?: () => void) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrdersCreate(status, query)
    .then((response: any) => {
      dispatch(actionsOrder.setOrdersPayments(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => {
      if (cb) {
        cb()
      } else {
        dispatch(appActions.showLoader(false))
      }
    })
}
export const getOrdersPaymentsHistory = (query: string, cb?: () => void) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrders(query)
    .then((response: any) => {
      dispatch(actionsOrder.setOrdersPaymentsHistory(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети 3`))
    })
    .finally(() => {
      if (cb) {
        cb()
      } else {
        dispatch(appActions.showLoader(false))
      }
    })
}
export const getOrdersFromServerDashboard = (status: string | { from: string, to: string }, query: string, cb?: () => void) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrdersCreate(status, query)
    .then((response: any) => {
      dispatch(actionsOrder.setOrdersDashboard(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => {
      if (cb) {
        cb()
      } else {
        dispatch(appActions.showLoader(false))
      }
    })
}
export const getOrdersFromServerDashboardArchive = (status: string, query: string, cb?: () => void) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrdersCreate(status, query)
    .then((response: any) => {

      dispatch(actionsOrder.setOrdersDashboardArchive(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => {
      if (cb) {
        cb()
      } else {
        dispatch(appActions.showLoader(false))
      }
    })
}
export const getUpdateOrderFromServerDashboard = (id: number, cb?: () => void) => (dispatch: Function) => {
  return ordersAPI.getOrder(id)
    .then((response: any) => {
      dispatch(actionsOrder.setUpdateOrderDashboard(response.data))
      dispatch(actionsOrder.setUpdateOrderDashboardArchive(response.data))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети 2`))
    })
    .finally(() => {
      if (cb) {
        cb()
      } else {
        dispatch(appActions.showLoader(false))
      }
    })
}
export const getUpdateTaskFromServer = (id: number) => (dispatch: any) => {
  return tasksAPI.getTask(id)
    .then((response: any) => {
      dispatch(actionsOrder.setUpdateOrderDashboard(response.data.order))
    })
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
  // .finally(() => dispatch(appActions.showLoader(false)))
}
export const getOrdersFromServerWithStatus = (status: number) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrdersWithStatus(status)
    .then((response: any) => dispatch(actionsOrder.getOrders(response.data)))
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}
export const getOrdersFromServerWithFilter = (filter: string) => (dispatch: Function) => {
  dispatch(appActions.showLoader(true))

  return ordersAPI.getOrdersWithFilter(filter)
    .then((response: any) => dispatch(actionsOrder.getOrders(response.data)))
    .catch((error: any) => {
      if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
      else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
    })
    .finally(() => dispatch(appActions.showLoader(false)))
}

const ordersHandler = (dispatch: Function, workpoint: string) => () => {
  return dispatch(getOrdersFromServerWithFilter(workpoint))
}
const ordersCreateUpdate = (dispatch: Function) => (id: number) => {
  return dispatch(getUpdateOrdersCreateFromServer(id))
}
const ordersHistoryUpdate = (dispatch: Function) => (id: number) => {
  return dispatch(getUpdateHistoryFromServer(id))
}
const orderUpdate = (dispatch: Function) => (id: number) => {
  return dispatch(getUpdateOrderFromServer(id))
}
const orderPaymentUpdate = (dispatch: Function) => (id: number) => {
  return dispatch(getUpdateOrderPymentFromServer(id))
}
const orderUpdateHandler = (dispatch: Function) => (id: number) => {
  return dispatch(getUpdateOrderFromServerDashboard(id))
}
const orderDatesUpdateHandler = (dispatch: Function) => (id: number) => {
  return dispatch(getUpdateOrderDatesFromServer(id))
}
const taskHandlerForDashboard = (dispatch: Function) => (id: number) => {
  return dispatch(getUpdateTaskFromServer(id))
}


export const startOrdersListeningArchive = () => (dispatch: Dispatch) => {
  wsAPI.subscribeOrders(orderUpdate(dispatch))
}
export const startOrdersListeningPayment = () => (dispatch: Dispatch) => {
  wsAPI.subscribeOrders(orderPaymentUpdate(dispatch))
}
export const startOrdersCreateListening = () => (dispatch: Dispatch) => {
  wsAPI.subscribeOrders(ordersCreateUpdate(dispatch))
}
export const startOrdersHistoryListening = () => (dispatch: Dispatch) => {
  wsAPI.subscribeOrders(ordersHistoryUpdate(dispatch))
}
export const startOrdersListening = (workpoint: string) => (dispatch: Dispatch) => {
  wsAPI.subscribeOrders(ordersHandler(dispatch, workpoint))
  wsAPI.subscribeOrders(orderUpdate(dispatch))
}
export const startOrdersDatesUpdateListening = () => (dispatch: Dispatch) => {
  wsAPI.subscribeOrders(orderDatesUpdateHandler(dispatch))
}
export const startOrdersListeningForDashboard = () => (dispatch: Dispatch) => {
  wsAPI.subscribeOrdersTasks(taskHandlerForDashboard(dispatch))
  wsAPI.subscribeOrders(orderUpdateHandler(dispatch))
}
export const stopOrdersListening = () => {
  wsAPI.stop()
}