import React from 'react'

import s from './componentLoader.module.scss'

export const ComponentLoader = () => {
	return (
		<div id={s.preloader}>
			<div id={s.loader}></div>
		</div>
	)
}