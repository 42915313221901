import React, { useEffect, useRef, useState } from 'react'
import { change, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { getPayments } from '../../../../../redux/paymentsReducer'

import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import { createField, Input } from '../../../common/FormsControl'
import { setUserDataOnServer } from '../../../../../redux/usersReducer'

import s from './formStatus.module.scss'
import {AppStateType} from "../../../../../redux/rootReducer";

const statuses = ['Активный', 'Заблокирован', 'Удалён']

interface IFormStatusProps {
  id: number
  status: string
}

const FormStatus: React.FC<IFormStatusProps> = ({ id, status = '' }) => {
  const statusForm = useSelector((state: AppStateType) => state.form?.userStatus?.values?.status)
  const [isChangeStatus, setIsChangeStatus] = useState(false)
  const [isStatuses, setIsStatuses] = useState(false)
  const [statusList, setStatusList] = useState<Array<string>>([])
  const refActiveStatus = useRef(null)
  const dispatch = useDispatch()

  // инициализация статуса в форме
  useEffect(() => {
    if (isChangeStatus) {
      dispatch(change('userStatus', 'status', getStatus(status)))
    }
  }, [dispatch, status, isChangeStatus])

  // фильтруем статусы в выпадающем списке, без установленного
  useEffect(() => {
    if (isChangeStatus) {
      setStatusList(statuses.filter((item) => item !== statusForm))
    }
  }, [isChangeStatus, statusForm])

  const closeStatuses = () => {
    setIsStatuses(false)
  }
  useOnClickOutside(refActiveStatus, closeStatuses)

  const getStatus = (status: string) => {
    if (status === 'active') return 'Активный'
    if (status === 'inactive') return 'Заблокирован'
    if (status === 'deleted') return 'Удалён'
    return ''
  }

  const showStatuses = () => {
    if (isChangeStatus) {
      setIsStatuses(true)
    }
  }

  const setStatus = (val: string) => {
    dispatch(change('userStatus', 'status', val))
    closeStatuses()
  }

  const setStatusFromServer = () => {
    const getValue = () => {
      if (statusForm === 'Активный') return 'active'
      if (statusForm === 'Заблокирован') return 'inactive'
      if (statusForm === 'Удалён') return 'deleted'
    }

    dispatch(setUserDataOnServer(id, { status: getValue() }, null, () => {
      dispatch(getPayments('role__type=worker'))
    }))
    setIsChangeStatus(false)
  }

  return (
    <form className={s.statusBlock} autoComplete={'off'}>
      <div className={s.buttons}>
        {
          !isChangeStatus
            ? <div className={s.btnChange} onClick={() => setIsChangeStatus(true)}>Изменить</div>
            : <div className={s.btnChange} onClick={setStatusFromServer}>Сохранить</div>
        }
      </div>

      <div className={s.statusUser}>
        <div className={s.userTitle}>Статус</div>
        <div className={s.userValue} ref={refActiveStatus}>

          {
            isChangeStatus
              ? createField('', 'status',
                [], Input,
                {
                  className: `${s.activeStatus} ${s.userInput}`,
                  onFocus: showStatuses,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()
                })
              : <span className={s.activeStatus} onClick={showStatuses}>
                {getStatus(status)}
                </span>
          }

          {
            isStatuses &&
            <div className={s.status}>
              {
                statusList.map((status: string, index: number) => (
                  <span key={index} className={s.statusItem} onClick={() => setStatus(status)}>
                    {status}
                  </span>
                ))
              }
            </div>
          }
        </div>
      </div>
    </form>
  )
}

interface IFormStatusReduxForm {
  status: string
}

export default reduxForm<IFormStatusReduxForm, IFormStatusProps>({ form: 'userStatus' })(FormStatus)