import { ThunkAction } from 'redux-thunk'

import { menuAPI } from '../api/api'

import { MenuPrompt, MenuType } from '../types/types'
import { AppStateType, InferActionsTypes } from './rootReducer'
import { Dispatch } from 'redux'
import { wsAPI } from '../api/ws-api'

const initialState = {
  isLoading: false,
  openModal: false,
  prompt: {style: null, text: ''} as MenuPrompt | null,
  menu: [] as Array<MenuType>,
  serverTime: ''
}

export type InitialStateType = typeof initialState

export const appReducer = (state = initialState, action: InferActionsTypes<typeof appActions>): InitialStateType => {
  switch (action.type) {
    case 'SHOW_LOADER':
      return {...state, isLoading: action.isLoading}

    case 'SHOW_MODAL':
      return {...state, openModal: action.payload}

    case 'SHOW_PROMPT':
      return {...state, prompt: {style: action.style, text: action.text}}

    case 'GET_MENU':
      return {...state, menu: action.payload} as InitialStateType

    default:
      return state
  }
}

export const appActions = {
  showLoader: (isLoading: boolean) => ({
    type: 'SHOW_LOADER',
    isLoading: isLoading
  } as const),
  showModal: (isShow: boolean) => ({
    type: 'SHOW_MODAL',
    payload: isShow
  } as const),
  showPrompt: (style: string, text: string) => ({
    type: 'SHOW_PROMPT',
    style: style,
    text: text
  } as const),
  getMenu: (menu: Array<object>) => ({
    type: 'GET_MENU',
    payload: menu
  } as const)
}

export const startWSChannel = (userId: number) => (dispatch: Dispatch) => {
  wsAPI.start(userId)
}
export const stopWSChannel = () => (dispatch: Dispatch) => {
  wsAPI.stop()
}

export const getMenuFromServer = (): ThunkAction<Promise<void>, AppStateType, unknown, InferActionsTypes<typeof appActions>> => {
  return (dispatch, state) => {
    return menuAPI.getMenu()
      .then((response: any) => {
        dispatch(appActions.getMenu(response.data))
      })
      .catch((error: any) => {
        if (error.response) dispatch(appActions.showPrompt('ALARM', `Ошибка сервера: ${error.response.status}`))
        else dispatch(appActions.showPrompt('ALARM', `Ошибка сети`))
      })
  }
}
