import React, { useEffect, useState } from 'react'

import {createField, Input} from '../../common/FormsControl'

import RenderGoodInfo from './RenderGoodInfo'

import s from './good.module.scss'

import plus from '../../../../assets/icons/plus.png'

const Goods = ({fields}) => {
  const [activeGood, setActiveGood] = useState(null)

  // eslint-disable-next-line
  useEffect(() => {
    if (fields.length <= 0) fields.push({})
    // eslint-disable-next-line
  }, [])

  const onAddGood = (e) => {
    e.preventDefault()
    setActiveGood(fields.length)
    fields.push({})
  }
  const onDeleteGood = (index) => {
    fields.remove(index)
  }
  const toggleActiveGood = (index) => {
    setActiveGood(index)
  }

  return (
    <div>
      {
        fields.map((item, index) => (
          <RenderGoodInfo key={index}
                          indexGood={index}
                          item={item}
                          fieldsLength={fields.length}
                          toggleActiveGood={(index) => toggleActiveGood(index)}
                          activeGood={activeGood}
                          onDeleteGood={() => onDeleteGood(index)}/>
        ))
      }

      <button className={s.addGood} onClick={onAddGood}>
        <img src={plus} alt=""/>
        <span className={s.btnGoodName}>Добавить товар</span>
      </button>

      <div className={s.blockTotal}>
        <span className={s.total}>Итого</span>
        {createField('', 'total', [],
          Input, {
          className: s.totalSum,
          disabled: true
        })}
        <span className={s.currency}>руб.</span>
      </div>
    </div>
  )
}

export default Goods