import React from 'react';
import s from './CorporateData.module.scss'

export const CorporateData = ({companyName, position, userAccess}) => {

	return (
		<div className={s.personalData}>

			<div className={s.personalContent}>
				<div className={s.corporateData}>
					<div className={s.dataItem}>
						<span className={s.corporateTitle}>Компания</span>
						<span className={s.corporateText}>{companyName}</span>
					</div>

					<div className={s.dataItem}>
						<span className={s.corporateTitle}>Должность</span>
						<span className={s.corporateText}>{position}</span>
					</div>

					<div className={s.dataItem}>
						<span className={s.corporateTitle}>Доступ</span>
						<span className={s.corporateText}>{userAccess}</span>
					</div>
				</div>
			</div>

		</div>
	)
}