import React, {useEffect} from 'react'
import {change, reduxForm} from 'redux-form'
import {NavLink} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import RestoreEmail from '../restoreEmail/RestoreEmail'
import ChangePassword from '../changePassword/ChangePassword'

import s from '../Login.module.scss'

const Restore = ({handleSubmit, error, onClickAuthorize, secretKey}) => {
  const dispatch = useDispatch()
  const isRestore = useSelector(state => state.auth.isRestore)
  const restoreMessage = useSelector(state => state.auth.restoreMessage)

  useEffect(() => {
    dispatch(change('restore', 'secret_key', secretKey))
  })

  return (
    <div className={s.restore}>
      <h2 className={s.modalTitle}>Восстановление пароля</h2>
      {
        !isRestore
          ? <form className={s.formRestore} onSubmit={handleSubmit}>
            {
              !secretKey
                ? <RestoreEmail/>
                : <ChangePassword/>
            }

            {error && <div className={s.wrongData}>{error}</div>}

            <button className="btn btn--gradient">Отправить</button>
          </form>
          : <div className={s.restoreMessage}>{restoreMessage}</div>
      }

      <NavLink to="/sign-in" className={s.restoreBtn} onClick={onClickAuthorize}>Авторизоваться</NavLink>
    </div>
  )
}

export default reduxForm({form: 'restore'})(Restore)