import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'

import {appActions} from '../../../../../redux/appReducer'
import {setNewPriceToServer} from '../../../../../redux/systemReducer'

import s from './cellWithPrice.module.scss'

interface ICellWithPrice {
  isChanging: boolean
  productName: string
  idProduct: string
  prices: any
  itemRole: any
}

const CellWithPrice: React.FC<ICellWithPrice> = (props) => {
  const {
    isChanging, productName,
    idProduct, prices, itemRole
  } = props
  const [price, setPrice] = useState<{summ: number}>({summ: 0})
  const [newPrice, setNewPrice] = useState<{summ: number}>({summ: 0})
  const inputRef = useRef<any>()
  const dispatch = useDispatch()

  useEffect(() => {
    if (prices?.length) {
      const priceData = prices.find((item: any) => {
        const isEqualRole = item.role?.id === itemRole.id
        const isDk = itemRole?.is_dk || false

        return isEqualRole && isDk === item?.is_dk
      })

      if (priceData) {
        setPrice(priceData)
        setNewPrice(priceData)
      }
    }
  }, [prices, itemRole])

  const saveData = () => {
    if (newPrice !== price) {
      dispatch(setNewPriceToServer({
          summ: +newPrice.summ,
          is_dk: itemRole.is_dk || false,
          role: itemRole.id,
          id: itemRole.id,
          product: idProduct,
        },
        () => {
          setNewPrice(price)
          dispatch(appActions.showPrompt('ALARM',
            `Новая цена для "${productName}" для "${itemRole?.name}" не установлена!
          Попробуйте изменить цену еще раз.`))
        }))
    }
  }

  return (
    <span>
      {
        !isChanging
          ? <span className={s.cell}>
              {(+newPrice?.summ).toFixed()}
            </span>
          : <input type="text"
                   className={s.editingField}
                   ref={inputRef}
                   onChange={(e) => {
                     setNewPrice((state: any) => ({...state, summ: e.target.value}))
                   }}
                   onBlur={saveData}
                   value={(+newPrice.summ).toFixed()}/>
      }
    </span>
  )
}

export default CellWithPrice
