import { useEffect, useState } from 'react'

export const useSortDate = <T>(arr: T[], sortFieldDate: keyof T, sortType: string = 'ASC') => {
  const [sortArr, setSortArr] = useState<T[]>([])

  useEffect(() => {
    if (!arr) return

    setSortArr(arr
      .sort((a, b) => {
        // @ts-ignore
        const dateFirst = a[sortFieldDate]?.split('.').reverse().join(', ')
        // @ts-ignore
        const dateSecond = b[sortFieldDate]?.split('.').reverse().join(', ')

        if (sortType === 'DESC') {
          return  +(new Date(dateSecond)) - +(new Date(dateFirst))
        } else {
          return +(new Date(dateFirst)) - +(new Date(dateSecond))
        }
      }))
  }, [arr, sortFieldDate, sortType])

  return sortArr
}