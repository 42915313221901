import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {appActions} from '../../../../../redux/appReducer'

import {Button} from '../../../common/Buttons/button/Button'
import {OrderCard} from '../../OrderCard'
import {Modal} from '../../../common/modal/Modal'
import {ModalContent} from './ModalContent'

import {getMaterialsFromServer} from '../../../../../redux/materialsReducer'

import s from '../../orderingMaterials.module.scss'

export const RequiresToOrder = ({showInfo}) => {
  const materials = useSelector(state => state?.materials?.materialsToOrder)
  const userId = useSelector(state => state.auth.id)
  const [modalClass, setModalClass] = useState(false)
  const [content, setContent] = useState(null)
  const dispatch = useDispatch()

  // Получение материалов с сервера со статусом 1, если id юзера получен
  useEffect(() => {
    if (userId) {
      dispatch(getMaterialsFromServer(1))
    }
  }, [dispatch, userId])

  // открытие модального окна с конкретным материалом
  const onClick = useCallback(item => {
    setContent(item)
    setModalClass(true)
    dispatch(appActions.showModal(true))
    // eslint-disable-next-line
  }, [])

  if (!materials || materials.length <= 0) return null

  return (
    <>
      <Modal
        modalClass={modalClass}
        setModalClass={setModalClass}
        className={s.orderMaterial_modal}
      >
        <ModalContent content={content} showInfo={showInfo}/>
      </Modal>

      <div className={s.orders_requiresToOrder}>
        <p className={s.ordersItem_title}>Требуется заказать</p>

        <div className={s.ordersItem_content}>
          {
            materials.length > 0
              ? materials?.map(item => (
                (item.status === 1) &&
                <OrderCard key={item.id} content={item} showInfo={showInfo}>
                  <div className={`${s.card_item} ${s.item_control}`}>
                    <span>Дата создания:<span>{item.created_datetime}</span></span>
                    <Button
                      title="Заказать"
                      className={s.item_control_button}
                      onClick={() => onClick(item)}
                    />
                  </div>
                </OrderCard>
              ))
              : <p className={s.card_item_title}>Материалов для заказа нет</p>
          }

        </div>
      </div>
    </>
  )
}