import React from 'react'
import {NavLink, Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'

import EditData from './EditData/EditData'
import StorageMaterials from './StorageMaterials/StorageMaterials'

import {tabsStorage} from '../../../configs/menuСonfiguration'

import s from './storage.module.scss'

const Storage = () => {
  const {url} = useRouteMatch()

  return (
    <div>
      <div className='container'>
        <h1 className={s.storageTitle}>Склад</h1>

        <div className={s.storageContent}>
          <div className={s.menu}>
            <div className={s.tabs}>
              {tabsStorage.map(tab => (
                <NavLink
                  key={tab.id}
                  to={`${url}${tab.to}`}
                  className={s.tabsItem}
                  activeClassName={s.tabsItemActive}
                >
                  {tab.title}
                </NavLink>
              ))}
            </div>
          </div>

          <div>
            <Redirect to={`${url}/materials`} />

            <Switch>
              <Route path={`${url}/materials`}>
                <StorageMaterials/>
              </Route>
              <Route path={`${url}/editing-data`}>
                <EditData/>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Storage